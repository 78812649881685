import React from 'react'
import { Images } from '../../../../Assets/Images/Images'
import TopStrip from '../../../../Components/TopStrip'
import { CONSTANTS } from '../../../../Helpers/Constants'
import { removeItemsLocally, setItemsLocally } from '../../../../LocalStorage/LocalStorage'
import DetailCard from './DetailCard'
import Packages from './Packages'
import Loader from '../../../../Components/Loader'

export default function OrderDetailPage({ propsList }) {
    const { data, cards, packages, routeToPackage, setShowOrderDetails, orderCancel, acceptOrder, rejectOrder, userRole, remainingTime, loading, setLoading } = propsList
    const showCancel = data.status === 'Cancelled' || data.status === 'Rejected' ? false : true;
    return (
        <div>
            <TopStrip
                path={[CONSTANTS.STRINGS_LIST.ORDERS]}
                primaryBreadCrumb={() => {
                    setShowOrderDetails(false);
                    removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY);
                    setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.ORDERS);
                }}
                secondPath={[
                    {
                        label: [CONSTANTS.STRINGS_LIST.ORDER_DETAILS],
                        action: () => { }
                    }
                ]}
                image={Images.order_icon_black}
                buttons={[
                    {
                        btnText: [CONSTANTS.STRINGS_LIST.BACK],
                        btnAction: () => {
                            setShowOrderDetails(false);
                            removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY);
                            setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.ORDERS);
                        },
                        showBtn: true,
                    }
                ]}
            />
            <div className="order-details">
                {loading && <Loader />}
                <div className="order_id_timing">
                    <div className="order_id">{CONSTANTS.STRINGS_LIST.ID}: {data?.orderId}</div>
                    {showCancel &&
                        <div className="order_timing">{remainingTime}</div>
                    }
                </div>

                <div className="detail-cards">

                    {cards.length < 1 ? <>
                        <DetailCard
                            heading={CONSTANTS.STRINGS_LIST.CUSTOMER_INFO}
                        />
                        <DetailCard
                            heading={CONSTANTS.STRINGS_LIST.ORDER_INFO}
                        />
                        <DetailCard
                            heading={CONSTANTS.STRINGS_LIST.DELIVERY_INFO}
                        />

                    </> : cards?.map((item, index) => {
                        return (
                            <DetailCard
                                heading={item.heading}
                                key={index}
                                data={item}
                            />
                        )
                    })}
                </div>

                <div className="packageDetails">
                    <div className="packages">
                        <Packages
                            heading={"Packages"}
                            packages={packages}
                            data={data}
                            status={data.status}
                            showCancel={showCancel}
                            routeToPackage={routeToPackage}
                            orderCancel={orderCancel}
                            userRole={userRole}
                            acceptOrder={acceptOrder}
                            rejectOrder={rejectOrder}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
