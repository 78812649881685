import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../Context/UserContext";
import AdminFAQSkeleton from "./Views/AdminFAQSkeleton";
import CreateFAQ from "./CreateFAQ";
import { getFaqData } from "./Views/ComponentHelpers";
import { CONSTANTS } from "../../../Helpers/Constants";
import { setItemsLocally } from "../../../LocalStorage/LocalStorage";


export default function AdminFAQDashboard() {
    const [faqData, setFaqData] = useState([]);
    const [modal, setModal] = useState(false);
    const { loading, setLoading } = useContext(UserContext);
    const [expanded, setExpanded] = useState('panel1');
    const [background, setBackground] = useState('panel1');


    const propList = {
        faqData,
        setFaqData,
        setModal,
        loading,
        setLoading,
        expanded,
        setExpanded,
        background,
        setBackground
    }

    useEffect(() => {
        setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.FAQ);
        getFaqData(setFaqData, setLoading);
    }, [])

    return (
        <div className="main-container">
            {modal && <CreateFAQ propList={propList} />}

            <AdminFAQSkeleton
                propList={propList}
            />
        </div>
    )
}