import { useEffect, useState, useContext } from "react";
import { CONSTANTS } from "../../../Helpers/Constants";
import { removeItemsLocally, setItemsLocally } from "../../../LocalStorage/LocalStorage";
import CreateVendorSkeleton from "./Views/CreateVendorSkeleton";
import { useHistory } from "react-router"
import { askConfimation, capitalizeFirstLetter, onChangeRoute } from "../../../Helpers/Helper";
import { UserContext } from "../../../Context/UserContext";
import { getLocalItems } from "../../../LocalStorage/LocalStorage";
import { doc } from "firebase/firestore";
import { db } from "../../../Firebase/Config";
import { getAreas } from "../../../Helpers/FetchHandlers";

export default function CreateVendorDashboard({ create, setCreate, setShowAddedData, showAddedData }) {
    const history = useHistory()
    const [mounted, setMounted] = useState(true);
    const { changes, setChanges, currentUser, loading, setLoading } = useContext(UserContext);
    const edit = getLocalItems(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY)
    const userRef = doc(db, `admin_users/${currentUser.userId}`);

    const vendorDefault = {
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.CREATED_BY]: userRef,
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.CREATED_ON]: new Date(),
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.EMAIL_ADDRESS]: '',
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.IS_ACTIVE]: true,
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.MODIFIED_BY]: userRef,
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.MODIFIED_ON]: new Date(),
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.ADDRESS]: '',
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.VENDOR_CITY]: "Stockholm",
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.VENDOR_AREA]: '',
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.CONTACT_NUMBER]: '',
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.CONTACT_PERSON_NAME]: '',
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.NAME]: '',
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.ORGANIZATION_NUMBER]: '',
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.POSTAL_CODE]: '',
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.STATUS]: CONSTANTS.STRINGS_LIST.ENABLED.toLocaleLowerCase(),
    }

    const vendorEdit = {
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.NAME]: edit?.name,
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.ORGANIZATION_NUMBER]: edit?.organizationNumber,
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.VENDOR_CITY]: capitalizeFirstLetter(edit?.vendorCity),
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.VENDOR_AREA]: edit?.vendorArea,
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.ADDRESS]: edit?.address,
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.POSTAL_CODE]: edit?.postalCode,
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.CONTACT_PERSON_NAME]: edit?.contactPersonName,
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.EMAIL_ADDRESS]: edit?.contactEmailAddress,
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.STATUS]: edit?.status,
        [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.CONTACT_NUMBER]: edit?.contactNumber,
    }
    const [vendorData, setVendorData] = useState(edit ? vendorEdit : vendorDefault)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [organizationError, setOrganizationError] = useState(false)
    const [stockholmAreas, setStockholmAreas] = useState(false)

    useEffect(() => {
        return () => {
            setMounted(false);
            // alert("unmounting");
            // Anything in here is fired on component unmount.
        }
    }, [mounted])

    useEffect(async () => {
        const areas = []
        const tempAreas = await getAreas()
        tempAreas.map(area => areas.push({ ...area, label: capitalizeFirstLetter(area.label) }))
        setStockholmAreas(areas)

        edit?.vendorArea && setVendorData({ ...vendorData, [CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.VENDOR_AREA]: { value: edit.vendorArea, label: edit.vendorArea } })
    }, [])

    useEffect(() => {
        setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.CREATE_VENDOR)
        let block;
        if (changes) {
            askConfimation(setChanges);
            block = history.block((location, action) => onChangeRoute(setChanges))
        }
        return () => {
            block && block()
        };
    }, [changes, history]);


    const cancelFunc = (event) => {
        removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY)
        setCreate(false)
    }

    const propList = {
        currentUser,
        loading,
        setLoading,
        edit,
        cancelFunc,
        create,
        setCreate,
        changes,
        setChanges,
        setShowAddedData,
        showAddedData,
        vendorData,
        setVendorData,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        error,
        setError,
        phoneError,
        setPhoneError,
        emailError,
        setEmailError,
        organizationError,
        setOrganizationError,
        stockholmAreas
    }
    return (
        <div className="main-container">
            {mounted && <CreateVendorSkeleton
                propList={propList}
            />}
        </div>
    )
}