import { Images } from "../../../../Assets/Images/Images"
import { CONSTANTS } from "../../../../Helpers/Constants"
import Toggle from '../../../../Components/Controls/Toggle'
import { validateURL } from "../../../../Helpers/Helper"

export default function PackageDetailComponent({item, itemList, setToggleActive, toggleActive }) {
    const tags = ["Breakfast", "Tuna", "Food"]
    const allergies = ["Soybeans", "Peanuts", "Milk", "Wheat"]
    const detail = {
        price_per_unit: `80 ${CONSTANTS.STRINGS_LIST.SEK}`,
        time: "1200-1300",
        capacity: "1-100"
    }
    return (
        <div className="main-detail">
            <div className="image_div">
                <img src={(item?.url && validateURL(item?.imageUrl)) ? item.imageUrl : Images.main_detail_img} alt="package_image" />
            </div>

            <div className="package_description">
                <div className="package_title">{item?.title}</div>
                <div className="description_title">{CONSTANTS.STRINGS_LIST.DESCRIPTION}</div>
                <p className="detail_description">{item?.description}</p>

                <div className="tags_allergies">
                    <div className="main">
                        <p className="title">{CONSTANTS.STRINGS_LIST.TAGS}</p>
                        <ul className="list">
                            {tags?.map((tag, index) => {
                                return (
                                    <li key={index}>{tag}</li>
                                )
                            })}
                        </ul>
                    </div>

                    <div className="main">
                        <p className="title">{CONSTANTS.STRINGS_LIST.ALLERGIES}</p>
                        <ul className="list">
                            {allergies?.map((allergy, index) => {
                                return (
                                    <li key={index}>{allergy}</li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="package_detail">
                <div className="detail_title">{CONSTANTS.STRINGS_LIST.DETAILS}</div>
                <ul>
                    {detail && Object.keys(detail).map((key, index) => {
                        return (
                            <li key={index}>{key}: {detail[key]}</li>
                        )
                    })}
                </ul>
                {/* <Toggle
                    title={CONSTANTS.STRINGS_LIST.DIRECT_BOOKING}
                    onChange={(val) => console.log('val', val)} 
                /> */}
            </div>
        </div>
    )
}