import { Images } from "../../../../Assets/Images/Images";
import { CONSTANTS } from "../../../../Helpers/Constants";
import { validateURL } from "../../../../Helpers/Helper";

export default function Packages({ 
    heading, 
    packages, 
    data, 
    showCancel, 
    routeToPackage, 
    orderCancel, 
    userRole,
    rejectOrder,
    acceptOrder,
}) {
    const subTotal = data?.packageTotalAmount;
    const supplementPerc = data?.supplementPercent;
    const supplement = data?.supplementAmount;
    const instaCateFeePerc = data?.instacateFeePercent;
    const instaCateFee = data?.instacateFeeAmount;
    const momsPerc = data?.momsPercent;
    const moms = data?.momsAmount;
    const total = data?.totalPayableAmount;
    const vendorID = data?.vendorId?._key?.path?.segments[6];
    const orderID = data?.dId;
    const status = data?.status;
    return (
        <div className="order-card">
            <div className="package-list">
                <div className="package-heading">{heading}</div>
                <div className="package-table">
                    <table>
                        <thead>
                            <tr>
                                <th>{CONSTANTS.STRINGS_LIST.IMAGE}</th>
                                <th>{CONSTANTS.STRINGS_LIST.TITLE}</th>
                                <th>{CONSTANTS.STRINGS_LIST.ALLERGIES}</th>
                                <th>{CONSTANTS.STRINGS_LIST.PRICE_PER_UNIT}</th>
                                <th>{CONSTANTS.STRINGS_LIST.QUANTITY}</th>
                                <th>{CONSTANTS.STRINGS_LIST.TOTAL_AMOUNT}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {packages ? Object.keys(packages).map((key, index) => {
                                const { imageUrl, title, allergyComments, pricePerUnit, orderedQuantity } = packages[key];
                                return (
                                    <tr onClick={() => { routeToPackage(packages[key]) }} className="dataRow" key={index}>
                                        <td className="packageImage"><img src={validateURL(imageUrl) ? imageUrl : Images.userMask} alt="" /></td>
                                        <td>{title}</td>
                                        <td>{allergyComments}</td>
                                        <td>{pricePerUnit}</td>
                                        <td>{orderedQuantity}</td>
                                        <td>{pricePerUnit * orderedQuantity} {CONSTANTS.STRINGS_LIST.SEK}</td>
                                    </tr>
                                )
                            }) : (
                                <tr>
                                    <td colSpan="6">{CONSTANTS.STRINGS_LIST.NO_PACKAGES}</td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="3" className="spaceCell">&nbsp;</td>
                                <th colSpan="2" className="heading">{CONSTANTS.STRINGS_LIST.SUB_TOTAL}</th>
                                <td colSpan="1" className="data">{subTotal} {CONSTANTS.STRINGS_LIST.SEK}</td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="spaceCell noBorders">&nbsp;</td>
                                <th colSpan="2" className="heading">{CONSTANTS.STRINGS_LIST.SUPLEMENTS} ({supplementPerc}%)</th>
                                <td colSpan="1" className="data">{supplement} {CONSTANTS.STRINGS_LIST.SEK}</td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="spaceCell noBorders">&nbsp;</td>
                                <th colSpan="2" className="heading">{CONSTANTS.STRINGS_LIST.INSTACATE_FEE} ({instaCateFeePerc}%)</th>
                                <td colSpan="1" className="data">{instaCateFee} {CONSTANTS.STRINGS_LIST.SEK}</td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="spaceCell noBorders">&nbsp;</td>
                                <th colSpan="2" className="heading">{CONSTANTS.STRINGS_LIST.MOMS} ({momsPerc}%)</th>
                                <td colSpan="1" className="data">{moms} {CONSTANTS.STRINGS_LIST.SEK}</td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="spaceCell noBorders">&nbsp;</td>
                                <th colSpan="2" className="heading">{CONSTANTS.STRINGS_LIST.TOTAL_AMOUNT}</th>
                                <td colSpan="1" className="data">{total} {CONSTANTS.STRINGS_LIST.SEK}</td>
                            </tr>
                        </tfoot>
                    </table>
                    <div className="btnDiv">
                        {userRole.toLowerCase() === CONSTANTS.STRINGS_LIST.VENDOR.toLowerCase() ? (
                            (status === CONSTANTS.STRINGS_LIST.UNCONFIRMED || status?.includes(CONSTANTS.STRINGS_LIST.REMAINING_TIME_LIST)) && (
                                <>
                                    <button className="acceptBtn" onClick={() => acceptOrder(orderID, vendorID)}>{CONSTANTS.STRINGS_LIST.ACCEPT}</button>
                                    <button className="cancelBtn" onClick={() => rejectOrder(orderID, vendorID)}>{CONSTANTS.STRINGS_LIST.REJECT}</button>
                                </>
                            )
                            ) : (
                                (userRole.toLowerCase() === CONSTANTS.STRINGS_LIST.ADMIN.toLowerCase() &&
                                showCancel && <button className="cancelBtn" onClick={() => orderCancel(orderID, vendorID)}>
                                    {CONSTANTS.STRINGS_LIST.CANCEL}
                                </button>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
}