import { useEffect, useState, useContext } from "react";
import CreatePackageSkeleton from "./Views/CreatePackageSkeleton";
import { CONSTANTS } from "../../../Helpers/Constants";
import { getLocalItems, setItemsLocally } from "../../../LocalStorage/LocalStorage";
import { getPackageItems, getSystemConfig, vendor_login_query } from "../../../Helpers/FetchHandlers";
import { askConfimation, capitalizeFirstLetter, convertSeconds, onChangeRoute } from "../../../Helpers/Helper";
import { UserContext } from "../../../Context/UserContext";
import { doc } from "firebase/firestore";
import { db } from "../../../Firebase/Config";
import { useHistory } from "react-router-dom";


export default function CreatePackageDashboard(props) {
    const history = useHistory()
    const { currentUser, loading, setLoading, changes, setChanges } = useContext(UserContext)
    const userRef = doc(db, `vendor_users/${currentUser.userId}`);
    const edit = getLocalItems(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY)
    const [systemConfig, setSystemConfig] = useState([])
    const options = []
    const itemCategories = []
    const itemCourses = []

    const packageDefault = {
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.CREATED_BY]: userRef,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.CREATED_ON]: new Date(),
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.DESCRIPTION]: "",
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.END_SERVICE_TIME]: "23:59:00",
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IS_DIRECT_BOOKING]: true,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_URL]: "",
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.SORT_INDEX]: CONSTANTS.STRINGS_LIST.DEFAULT_INDEX_NUMBER,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MINIMUM_ORDER_CAPACITY]: "",
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MODIFIED_BY]: userRef,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MODIFIED_ON]: new Date(),
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MOMS]: 0,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.INSTACATE_FEE]: 0,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.SORT_INDEX]: CONSTANTS.STRINGS_LIST.DEFAULT_INDEX_NUMBER,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.PRICE_PER_UNIT]: 0,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.AMOUNT_CHARGED]: 0,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.START_SERVICE_TIME]: "00:00:00",
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.STATUS]: "enabled",
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.TAGS]: "",
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.TITLE]: "",
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.VENDOR_ID]: "",
    }

    const packageEdit = {
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.DESCRIPTION]: edit?.description,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.END_SERVICE_TIME]: convertSeconds(edit?.endServiceTimeSeconds).toString(),
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_NAME]: edit?.imageUrl.split(/[\\\//]/).pop().split('?')[0].split('%2F')[3].split("%20")[0],
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_URL]: edit?.imageUrl,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IS_DIRECT_BOOKING]: edit?.isDirectBooking,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MINIMUM_ORDER_CAPACITY]: edit?.minimumOrderCapacity,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MODIFIED_BY]: userRef,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MODIFIED_ON]: new Date(),
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.PRICE_PER_UNIT]: edit?.pricePerUnit,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.INSTACATE_FEE]: 0,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MOMS]: 0,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.AMOUNT_CHARGED]: 0,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.START_SERVICE_TIME]: convertSeconds(edit?.startServiceTimeSeconds),
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.STATUS]: edit?.status,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.TAGS]: "",
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.VENDOR_ID]: edit?.vendorId,
        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.TITLE]: edit?.title,
    }
    const packageItemDefault = {
        [CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.CATEGORY]: "",
        [CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.COURSE]: "",
        [CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.CREATED_BY]: userRef,
        [CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.SORT_INDEX]: CONSTANTS.STRINGS_LIST.DEFAULT_INDEX_NUMBER,
        [CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.CREATED_ON]: new Date(),
        [CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.DESCRIPTION]: "",
        [CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.IMAGE_URL]: "",
        [CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.IS_ACTIVE]: true,
        [CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.MODIFIED_BY]: userRef,
        [CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.MODIFIED_ON]: new Date(),
        [CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.TITLE]: "",
    }

    const [packageData, setPackageData] = useState(edit ? packageEdit : packageDefault)
    const [itemData, setItemData] = useState(packageItemDefault)
    const [filePreview, setFilePreview] = useState([])
    const [packageItemList, setPackageItemList] = useState([])
    const [packageItemSorted, setPackageItemSorted] = useState(false)
    const [showAddItem, setShowAddItem] = useState(false)
    const [error, setError] = useState(false)
    const [imageError, setImageError] = useState(false)
    const [menuItemEdited, setMenuItemEdited] = useState(false)
    const [selectedMenuItemEdited, setSelectedMenuItemEdited] = useState(false)
    const [deletedMenuItems, setDeletedMenuItems] = useState([])
    const defaultDragState = {
        row: -1,
        startPoint: null,
        direction: "",
        dropIndex: -1
    };
    const [dragState, setDragState] = useState({ ...defaultDragState });

    useEffect(() => {
        !packageItemSorted &&
        packageItemList.sort((a, b) => {
            return a.sortIndex - b.sortIndex
        })
    }, [packageItemList])

    const onDragStart = (e, index) => {
        setDragState({
            ...dragState,
            row: index,
            startPoint: {
                x: e.pageX,
                y: e.pageY
            }
        });
    }
    const onDragEnter = (index) => {
        if (!dragState.direction) {
            if (dragState.row !== index) {
                setDragState({
                    ...dragState,
                    direction: "row",
                    dropIndex: index
                });
                return;
            }
            return;
        }
        if (dragState.direction === "row") {
            if (index !== dragState.dropIndex) {
                setDragState({
                    ...dragState,
                    dropIndex: index
                });
            }
            return
        }
    }
    const onDragEnd = (
        data
    ) => {
        let tempData = [...data];
        tempData.forEach((item, index) => {
            item.sortIndex = index + 1
        })
        setChanges(true)
        setPackageItemSorted(true)
        setMenuItemEdited(true)
        setPackageItemList(tempData)
    }

    useEffect(() => {
        let block;
        if (changes) {
            askConfimation(setChanges);
            block = history.block((location, action) => onChangeRoute(setChanges))
        }
        return () => {
            block && block()
        }

    }, [changes, history])

    useEffect(async () => {
        setLoading(true)
        const tempData = await getSystemConfig("REp6lXl9OP6NmJUh6KrY", "system_config")
        const vendorRef = await getSystemConfig(currentUser.userId, "vendor_users")
        const packageItems = await getPackageItems(edit?.dId)
        edit && setPackageItemList(packageItems)
        setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.CREATE_PACKAGE)
        setSystemConfig(tempData)
        setPackageData(
            {
                ...packageData,
                [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.VENDOR_ID]: vendorRef.vendorId,
            })
        packageData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.INSTACATE_FEE] = edit?.pricePerUnit * (Number(tempData.instacate_fee) / 100)
        packageData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MOMS] = (Number(edit?.pricePerUnit) + Number(packageData.instacate_fee)) * (Number(tempData.moms / 100))
        packageData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.AMOUNT_CHARGED] = (Number(edit?.pricePerUnit) + Number(packageData.instacate_fee) + Number(packageData.moms))
        const tempTags = []
        edit?.tags.map(item => {
            tempTags.push({ value: item, label: item })
            setPackageData({ ...packageData, [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.TAGS]: tempTags })
        })

        setLoading(false)
    }, [])



    systemConfig.tags?.map(item => {
        options.push({ value: item, label: capitalizeFirstLetter(item) })
    }
    )

    useEffect(() => {
        if (typeof filePreview === "object") {
            let link = filePreview.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))
            link.map(file => setItemData({ ...itemData, image: file, imageName: file.name, imageUrl: file.preview }))
        }
    }, [filePreview])

    const propsList = {
        packageData,
        setPackageData,
        error,
        setError,
        systemConfig,
        edit,
        options,
        imageError,
        setImageError,
        loading,
        setLoading,
        itemData,
        setItemData,
        packageItemDefault,
        showAddItem,
        setShowAddItem,
        packageItemList,
        setPackageItemList,
        filePreview,
        setFilePreview,
        changes,
        setChanges,
        menuItemEdited,
        setMenuItemEdited,
        deletedMenuItems,
        setDeletedMenuItems,
        itemCategories,
        itemCourses,
        selectedMenuItemEdited,
        setSelectedMenuItemEdited,
        onDragEnd,
        onDragStart,
        onDragEnter,
        dragState,
        packageItemSorted,
        props
    }

    return (
        <div className="main-container">
            <CreatePackageSkeleton
                propsList={propsList}
            />
        </div>
    )
}