import { capitalizeFirstLetter } from "../../../../Helpers/Helper"
export default function DetailCard({ data, heading }) {
    return (
        <div className="order-card">
            <div className="card_heading">
                {heading}
            </div>
            <div className="detailed_fields">
                {data?.data && Object.keys(data?.data).map((key, index) => {
                    return (
                        <>
                            <div key={index}>
                                {
                                    capitalizeFirstLetter(key)} : <span className={`${data?.data[key] === 'Accepted' ? 'acceptedText' :
                                        data?.data[key] === 'Rejected' ? 'rejectedText' :
                                        data?.data[key] === 'Cancelled' ? 'rejectedText' : ''
                                    }
                            }`}>{data.data[key]} </span>
                            </div>
                        </>
                    )
                })}
            </div>
        </div>
    )
}