import swal from "sweetalert";
import { CONSTANTS } from "../Helpers/Constants";
import { logOutUser, forget_password, loginAccount } from "./AuthFunctions";
import { clearItemsLocally, setItemsLocally } from "../LocalStorage/LocalStorage";
import Swal from "sweetalert2";

export const userLogin = async (email, password, setCurrentUser, error, setError, userType, userName, setLoading) => {
    try {
        const userCredential = await loginAccount(email, password, setCurrentUser, error, setError)
        const user = {
            userType,
            userName,
            userId: userCredential.user.uid,
            idToken: userCredential._tokenResponse.idToken,
            refreshToken: userCredential._tokenResponse.refreshToken
        }
        setItemsLocally('user', user)
        setCurrentUser(user)
        setLoading(false)
    }
    catch (err) {
        console.log(err)
        const errorCode = err.code;
        if (errorCode.includes('password')) {
            setError({ email: false, password: true })
        } else {
            setError({ email: true, password: false })
        }
        setLoading(false)
    }
}

export const logOut = async (setCurrentUser, setChanges) => {
    try {
        await logOutUser()
        setChanges(false)
        setCurrentUser(null)
        clearItemsLocally()
    }
    catch (error) {
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: CONSTANTS.STRINGS_LIST.SOMETHING_WENT_WRONG,
        })
    }
}

//has to be confirmed //write function
export const forgetPassword = async (email, setError, history) => {
    try {
        await forget_password(email)
        swal(`${CONSTANTS.STRINGS_LIST.RESET_MAIL_SENT}!`)
            .then(
                function () {
                    history.push("/")
                }
            )
    }
    catch (error) {
        setError({ email: true })
    }
}