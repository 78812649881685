
import { CONSTANTS } from "../../../../Helpers/Constants"
import { submitHandler } from '../../../../Helpers/Helper';
import FormInputs from "../../../../Components/Controls/FormInputs";
import { create_package } from "../../../../Helpers/WriteHandlers";
import { updatePackage } from "../../../../Helpers/UpdateHandlers";
import Dropzone from "../../../../Components/DragAndDropInput";
import { packageChangeHandler } from "./ComponentHelper/ComponentHandler";
import Select from 'react-select';
import { endDateHandler } from "./ComponentHelper/ComponentHandler";
import Toggle from "../../../../Components/Controls/Toggle";


export default function CreatePackageForm({ propsList, setCreate, packageCreated, setPackageCreated }) {
    const { packageData, setPackageData, error, setError, edit, systemConfig, options, imageError, setImageError, setLoading, packageItemList, setChanges, menuItemEdited, deletedMenuItems, selectedMenuItemEdited, packageItemSorted } = propsList

    return (
        <form onSubmit={e => submitHandler(e)} id="my-form">
            <div className="create-package-form">
                <div className='input-group first-section'>
                    <FormInputs
                        title={CONSTANTS.STRINGS_LIST.TITLE}
                        name={CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.TITLE}
                        placeholder={CONSTANTS.STRINGS_LIST.TITLE.toLocaleLowerCase()}
                        handlerFunction={(event) => packageChangeHandler(event, setPackageData, packageData, systemConfig, setChanges)}
                        value={packageData.title}
                        required="required"
                    />
                    <Dropzone
                        title={CONSTANTS.STRINGS_LIST.IMAGE}
                        value={edit ? packageData.imageName : packageData.imageUrl}
                        onChange={
                            (files) => { setPackageData({ ...packageData, imageUrl: files }) }
                        }
                        imageError={imageError}
                        errorMessage="Please add Image"
                        state={packageData}
                        setState={setPackageData}
                        fieldName={"imageName"}
                        setChanges={setChanges}
                    />
                </div>

                <div className='input-group second-section'>
                    <div className="time">
                        <FormInputs
                            name="startServiceTimeSeconds"
                            title={CONSTANTS.STRINGS_LIST.TIME}
                            placeholder={CONSTANTS.STRINGS_LIST.FROM}
                            type="time"
                            value={packageData.startServiceTimeSeconds}
                            handlerFunction={(event) => packageChangeHandler(event, setPackageData, packageData, systemConfig, setChanges)}
                            required="required"

                        />
                        <div className="time-input">
                            <FormInputs
                                name="endServiceTimeSeconds"
                                placeholder={CONSTANTS.STRINGS_LIST.TO}
                                type="time"
                                value={packageData.endServiceTimeSeconds}
                                handlerFunction={(event) => packageChangeHandler(event, setPackageData, packageData, systemConfig, setChanges)}
                                error={error}
                                errorMessage={CONSTANTS.STRINGS_LIST.END_TIME_ERROR}
                                onBlur={(event) => endDateHandler(event, packageData, setError)}
                                required="required"

                            />
                        </div>
                    </div>
                    <div className="capacity">
                        <FormInputs
                            name="minimumOrderCapacity"
                            title={CONSTANTS.STRINGS_LIST.MINIMUM_CAPACITY}
                            placeholder={CONSTANTS.STRINGS_LIST.MINIMUM_CAPACITY.toLocaleLowerCase()}
                            value={packageData.minimumOrderCapacity}
                            type="number"
                            pattern="\d*"
                            handlerFunction={(event) => packageChangeHandler(event, setPackageData, packageData, systemConfig, setChanges)}
                            required="required"
                        />
                    </div>
                </div>

                <div className='input-group third-section'>
                    <p>{CONSTANTS.STRINGS_LIST.DESCRIPTION}</p>
                    <textarea
                        className="description"
                        name="description"
                        title={CONSTANTS.STRINGS_LIST.DESCRIPTION}
                        placeholder={CONSTANTS.STRINGS_LIST.DESCRIPTION.toLocaleLowerCase()}
                        value={packageData.description}
                        onChange={(event) => packageChangeHandler(event, setPackageData, packageData, systemConfig, setChanges)}
                        required="required"
                    />
                </div>

                <div className='input-group forth-section'>
                    <div className="amount-calculation">
                        <FormInputs
                            name="pricePerUnit"
                            title={CONSTANTS.STRINGS_LIST.PRICE_PER_UNIT}
                            placeholder={CONSTANTS.STRINGS_LIST.PRICE.toLocaleLowerCase()}
                            value={packageData.pricePerUnit}
                            type="number"
                            pattern="\d*"
                            handlerFunction={(event) => packageChangeHandler(event, setPackageData, packageData, systemConfig, setChanges)}
                            required="required"
                        />
                        <FormInputs
                            name="instacate_fee"
                            titleClass="titleClass"
                            title={CONSTANTS.STRINGS_LIST.INSTACATE_FEE}
                            disabled
                            value={`${packageData?.instacate_fee?.toFixed(2)} ${CONSTANTS.STRINGS_LIST.SEK}`}
                            handlerFunction={(event) => packageChangeHandler(event, setPackageData, packageData, systemConfig, setChanges)}
                            required="required"
                        />
                        <FormInputs
                            name="moms"
                            titleClass="titleClass"
                            title={CONSTANTS.STRINGS_LIST.MOMS}
                            disabled
                            value={`${packageData?.moms?.toFixed(2)} ${CONSTANTS.STRINGS_LIST.SEK}`}
                            handlerFunction={(event) => packageChangeHandler(event, setPackageData, packageData, systemConfig, setChanges)}
                        />
                    </div>
                    <div className="amount-final">
                        <div className="operator">=</div>
                        <FormInputs
                            name="amountCharged"
                            titleClass="titleClass"
                            title={CONSTANTS.STRINGS_LIST.AMOUNT_CHARGED}
                            disabled
                            value={`${packageData?.amountCharged?.toFixed(2)} ${CONSTANTS.STRINGS_LIST.SEK}`}
                            handlerFunction={(event) => packageChangeHandler(event, setPackageData, packageData, systemConfig, setChanges)}
                        />
                    </div>
                </div>

                <div className='input-group sixth-section'>
                    <div className="toggle-btn-group">
                        <Toggle
                            onChange={(event) => {
                                setPackageData({ ...packageData, [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IS_DIRECT_BOOKING]: event })
                            }}
                            title={CONSTANTS.STRINGS_LIST.DIRECT_BOOKING}
                            value={packageData.isDirectBooking}
                        />
                    </div>
                    <div className="toggle-btn-group">
                        <Toggle
                            onChange={(event) => {
                                event ?
                                    setPackageData({
                                        ...packageData,
                                        [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.STATUS]: CONSTANTS.STRINGS_LIST.ENABLED.toLocaleLowerCase()
                                    })
                                    :
                                    setPackageData({
                                        ...packageData, [CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.STATUS]: CONSTANTS.STRINGS_LIST.DISABLED.toLocaleLowerCase()
                                    })
                            }}
                            title={CONSTANTS.STRINGS_LIST.ENABLED}
                            value={packageData.status === 'enabled' ? true : false}
                        />
                    </div>

                    <div className="select">
                        <Select isMulti={true} options={options}
                            placeholder={CONSTANTS.STRINGS_LIST.TAGS}
                            value={packageData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.TAGS]}
                            onChange={(event) => packageChangeHandler(event, setPackageData, packageData, systemConfig, setChanges, "tags")}
                        />
                    </div>
                </div>
                <div className='input-group btn-section'>
                    <button type='submit'
                        id={CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.CREATE_PACKAGE}
                        onClick={
                            edit ?
                                () => {
                                    updatePackage(
                                        packageData,
                                        setCreate,
                                        setLoading,
                                        packageItemList,
                                        packageCreated,
                                        setPackageCreated,
                                        error,
                                        edit.dId,
                                        menuItemEdited,
                                        deletedMenuItems,
                                        setChanges,
                                        packageItemSorted
                                    )
                                } :
                                () => {
                                    create_package(
                                        packageData,
                                        setImageError,
                                        setCreate,
                                        setLoading,
                                        packageItemList,
                                        packageCreated,
                                        setPackageCreated,
                                        error,
                                        setChanges,
                                    )
                                }}>
                        {edit ? CONSTANTS.STRINGS_LIST.EDIT_PACKAGE : CONSTANTS.STRINGS_LIST.CREATE_PACKAGE}
                    </button>
                </div>
            </div>
        </form>
    )
}