import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../Context/UserContext';
import { getConfig } from '../../../Helpers/FetchHandlers';
import { addTags } from '../../../Helpers/WriteHandlers';
import AdminSettingsSkeleton from './Views/AdminSettingsSkeleton';
import { trim, askConfimation, onChangeRoute } from '../../../Helpers/Helper';
import { CONSTANTS } from '../../../Helpers/Constants';
import { setItemsLocally } from '../../../LocalStorage/LocalStorage';
import validateSettings from './ValidateSettings';
import swal from 'sweetalert';
import { useHistory } from "react-router";
import { updateSettings } from '../../../Helpers/UpdateHandlers';

export default function AdminSettingsDashboard() {
    const history = useHistory();
    const [tags, setTags] = useState([]);
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [moms, setMoms] = useState('');
    const [fees, setFees] = useState('');
    const [filter, setFilter] = React.useState([]);
    const { loading, setLoading, changes, setChanges } = useContext(UserContext);

    useEffect(() => {
        let block;
        if (changes){
            askConfimation(setChanges);
            block = history.block((location, action) => onChangeRoute(setChanges))
        }
        return () => {
            block && block()
        };
    }, [changes, history])

    useEffect(() => {
        setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.SETTINGS)
        settingsData()
    }, [])

    const settingsData = async () => {
        setLoading(true);
        const tempData = await getConfig()
        setTags(tempData.tags);
        setMoms(tempData.moms);
        setFees(tempData.instacate_fee);
        setData(tempData);
        setFilter(tempData.tags);
        setLoading(false);
    }

    const handleSearch = (e) => {
        const filter = tags?.filter(tag => tag.toLowerCase().includes(e.target.value.toLowerCase()))
        setFilter(filter)
        setSearch(e.target.value)
    }

    const addTag = async (e) => {
        if (filter.length === 0) {
            const tempTags = tags;
            const tag = trim(search);
            const updatedTags = tempTags.push(tag);
            setTags(tempTags)
            addTags(tags, data._id)
            setFilter(tags);
            setSearch('');
        }
        else if (filter.length !== 0) {
            swal({
                title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                text: CONSTANTS.STRINGS_LIST.TAG_ADDED_FAILED,
                icon: 'error'
            }).then(function () {
                setTags(tags);
                setFilter(tags);
                setSearch('');
            })
        }
    }

    const saveSettingsAlert = () => {
        swal(CONSTANTS.STRINGS_LIST.SAVE_UPDATED_CHANGES, {
            buttons: {
                cancel: CONSTANTS.STRINGS_LIST.CANCEL,
                save: CONSTANTS.STRINGS_LIST.SAVE
            }
        }).then((value) => {
            switch (value) {
                case "save":
                    updateSettings(data._id, fees, moms, tags)
                    break;
                default:
                    break;
            }
        })
        setChanges(false);
    }

    const saveSettings = async (e) => {
        e.preventDefault();
        const { error, message } = validateSettings({ moms, fees, tags });
        if (error && message) {
            swal({
                title: CONSTANTS.STRINGS_LIST.SETTINGS_UPDATED_FAILED,
                text: message,
                icon: "error"
            })
        }
        else {
            saveSettingsAlert();
        }
    }

    return (
        <div className="main-container">
            <AdminSettingsSkeleton
                tags={tags}
                fees={fees}
                setFees={setFees}
                moms={moms}
                setMoms={setMoms}
                addTag={addTag}
                search={search}
                data={data}
                changes={changes}
                loading={loading}
                setSearch={setSearch}
                handleSearch={handleSearch}
                saveSettings={saveSettings}
                setChanges={setChanges}
                filter={filter}
            />
        </div>
    )
}