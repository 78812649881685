import { get } from '.';

async function cancelOrder(orderId) {
  const json = get(`sendEmailOrderCancelled/?orderId=${orderId}`);
  return json
}

async function rejectOrder(orderId) {
  const json = get(`sendEmailOrderRejected/?orderId=${orderId}`);
  return json
}

async function acceptOrder(orderId) {
  const json = get(`sendEmailOrderAccepted/?orderId=${orderId}`);
  return json
}

export const IC_order = {
  cancelOrder,
  acceptOrder,
  rejectOrder
};
