import { CONSTANTS } from "../../../../Helpers/Constants";
import { Images } from "../../../../Assets/Images/Images";
import { close_modal_handler, faq_change_handler, editFaqHandler, addFaqHandler } from "../Views/ComponentHelpers";
import { getLocalItems } from "../../../../LocalStorage/LocalStorage";
import { doc } from "firebase/firestore";
import { db } from "../../../../Firebase/Config";
import { useState, useContext } from "react";
import { UserContext } from "../../../../Context/UserContext";


export default function CreateFAQ({ propList }) {
    const { setModal, faqData, setFaqData, setLoading } = propList
    const currentFaq = getLocalItems(CONSTANTS.LOCAL_STORAGE_ITEMS.FAQ_KEY);
    const { currentUser } = useContext(UserContext);
    const userRef = doc(db, `${CONSTANTS.API.COLLECTION_NAMES.ADMIN_USERS}/${currentUser.userId}`);

    const createFaq = {
        [CONSTANTS.FIELDS.FAQ_KEYS.QUESTION]: '',
        [CONSTANTS.FIELDS.FAQ_KEYS.ANSWER]: '',
        [CONSTANTS.FIELDS.FAQ_KEYS.CREATED_BY]: userRef,
        [CONSTANTS.FIELDS.FAQ_KEYS.CREATED_ON]: new Date(),
        [CONSTANTS.FIELDS.FAQ_KEYS.IS_ACTIVE]: true,
        [CONSTANTS.FIELDS.FAQ_KEYS.MODIFIED_BY]: userRef,
        [CONSTANTS.FIELDS.FAQ_KEYS.MODIFIED_ON]: new Date(),
    }

    const editFaq = {
        [CONSTANTS.FIELDS.FAQ_KEYS.ID]: currentFaq?.id,
        [CONSTANTS.FIELDS.FAQ_KEYS.QUESTION]: currentFaq?.question,
        [CONSTANTS.FIELDS.FAQ_KEYS.ANSWER]: currentFaq?.answer,
        [CONSTANTS.FIELDS.FAQ_KEYS.IS_ACTIVE]: currentFaq?.isActive,
        [CONSTANTS.FIELDS.FAQ_KEYS.MODIFIED_BY]: userRef,
        [CONSTANTS.FIELDS.FAQ_KEYS.MODIFIED_ON]: new Date(),
    }
    const [createAndEditFaq, setCreateAndEditFaq] = useState(currentFaq ? editFaq : createFaq)

    const variable_for_handlers = {
        createAndEditFaq, setModal, setLoading, currentFaq, faqData, setFaqData,
    }

    return (
        <div className='main-modal'>
            <div className="modal-form">
                <div className='form-heading'>
                    <p>{currentFaq ? CONSTANTS.STRINGS_LIST.EDIT_FAQ : CONSTANTS.STRINGS_LIST.CREATE_FAQ}</p>
                    <img onClick={() => close_modal_handler(setModal)} src={Images.close_icon} alt='close' />
                </div>

                <form onSubmit={e => e.preventDefault()}>
                    <div className='question-input-group'>
                        <p className='question-title'>{CONSTANTS.STRINGS_LIST.FAQ}</p>
                        <input name='question' onChange={(event) => faq_change_handler(event, createAndEditFaq, setCreateAndEditFaq)} value={createAndEditFaq.question} placeholder={CONSTANTS.STRINGS_LIST.WRITE_QUESTION} className='question-input' required />
                    </div>

                    <div className='answer-input-group'>
                        <p className='answer-title'>{CONSTANTS.STRINGS_LIST.ANSWER}</p>
                        <textarea name='answer' onChange={(event) => faq_change_handler(event, createAndEditFaq, setCreateAndEditFaq)} value={createAndEditFaq.answer} placeholder={CONSTANTS.STRINGS_LIST.WRITE_ANSWER} className='answer-input' required />
                    </div>

                    <div className='create-faq-button'>
                        <button onClick={currentFaq ? () => editFaqHandler(variable_for_handlers) : () => addFaqHandler(variable_for_handlers)}>{currentFaq ? CONSTANTS.STRINGS_LIST.EDIT_FAQ : CONSTANTS.STRINGS_LIST.ADD_FAQ}</button>
                    </div>
                </form>

            </div>
        </div>
    )
}