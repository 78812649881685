import React from 'react';
import { Images } from '../../../../Assets/Images/Images';
import TopStrip from '../../../../Components/TopStrip';
import { CONSTANTS } from '../../../../Helpers/Constants';
import Loader from '../../../../Components/Loader';
import { capitalizeString, isString, validateURL } from '../../../../Helpers/Helper';
import { offsetIndex } from '../../../../Helpers/FetchHandlers';
import { setItemsLocally } from '../../../../LocalStorage/LocalStorage';

export default function AdminVendorsListSortTable({
    data,
    loading,
    heads,
    listNames,
    sortList,
    setSortList,
    saveSortedOrder,
    dragState,
    setDragState,
    defaultDragState,
    sortedData,
    onDragEnd,
    isSorted
}) {
    let _row = data;

    if (dragState.direction === "row") {
        _row = offsetIndex(dragState.row, dragState.dropIndex, _row);
    }

    return (
        <>
            <TopStrip
                path={[CONSTANTS.STRINGS_LIST.PACKAGES]}
                primaryBreadCrumb={() => (
                    setSortList(!sortList),
                    setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.PACKAGES)
                )}
                image={Images.vendor_icon}
                secondPath={[
                    {
                        label: CONSTANTS.STRINGS_LIST.SORT_LIST,
                        action: () => { }
                    }
                ]}
                buttons={[
                    {
                        btnText: CONSTANTS.STRINGS_LIST.SAVE_SORT_ORDERS,
                        btnAction: (e) => {
                            saveSortedOrder(e, sortedData?._row);
                        },
                        showBtn: true,
                        disabled: isSorted ? false : true
                    },
                    {
                        btnText: CONSTANTS.STRINGS_LIST.CANCEL,
                        btnAction: () => {
                            setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.PACKAGES);
                            setSortList(!sortList);
                        },
                        showBtn: true,
                    }
                ]}
            />
            <div className="table">
                <div className="tableContainer">
                    {loading && <Loader />}
                    <div className="overFlowAuto">
                        <div className="order-card">
                            <div className="package-list">
                                <div className="package-table">
                                    <table className="generalTable">
                                        <thead>
                                            <tr>
                                                {heads?.map((item, index) => {
                                                    return (
                                                        <th key={'ine321' + index}>{item.label}</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.length > 0 ? data.map((item, index) => {
                                                return (
                                                    <tr
                                                        key={index}
                                                        className="dataRow"
                                                        draggable="true"
                                                        onDragStart={(e) => {
                                                            setDragState({
                                                                ...dragState,
                                                                row: index,
                                                                startPoint: {
                                                                    x: e.pageX,
                                                                    y: e.pageY
                                                                }
                                                            });
                                                        }}
                                                        onDragEnter={() => {
                                                            if (!dragState.direction) {
                                                                if (dragState.row !== index) {
                                                                    setDragState({
                                                                        ...dragState,
                                                                        direction: "row",
                                                                        dropIndex: index
                                                                    });
                                                                    return;
                                                                }
                                                                return;
                                                            }
                                                            if (dragState.direction === "row") {
                                                                if (index !== dragState.dropIndex) {
                                                                    setDragState({
                                                                        ...dragState,
                                                                        dropIndex: index
                                                                    });
                                                                }
                                                                return;
                                                            }
                                                        }}
                                                        onDragEnd={() => {
                                                            onDragEnd(
                                                                dragState.direction,
                                                                dragState.row,
                                                                dragState.dropIndex,
                                                                { listNames, _row }
                                                            );
                                                            setDragState({ ...defaultDragState });
                                                        }}
                                                    >
                                                        {listNames.map((head, index1) => {
                                                            return (
                                                                <>
                                                                    <td className={`${isString(item[head]) && item[head]?.toLowerCase() === 'accepted' ? 'acceptedText' : item[head] === 'rejected' ? 'rejectedText' : ''}`}
                                                                        key={index + 'ine321' + index1} >
                                                                        {head === 'imageUrl' ? (
                                                                            <img className="tableImage" src={validateURL(item[head]) ? item[head] : Images.main_detail_img} target="_blank" rel="noopener noreferrer" alt='img' />
                                                                        ) : (
                                                                            <>{
                                                                                isString(item[head]) ?
                                                                                    item[head]?.toLowerCase() === ('accepted' || 'rejected' || 'cancelled') ?
                                                                                        capitalizeString(item[head]) :
                                                                                        item[head]
                                                                                    :
                                                                                    item[head]
                                                                            }
                                                                            </>
                                                                        )}
                                                                    </td>
                                                                </>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            }) : (
                                                <tr className="noData">
                                                    <td colSpan="6">{'No Data'}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </div>
        </>
    )
}