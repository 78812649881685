import { useHistory } from "react-router";
import { Images } from "../../../Assets/Images/Images";
import { CONSTANTS } from "../../../Helpers/Constants";

export default function ErrorPage() {
    const history = useHistory()
    return (
        <>
            <div className='login-screen'>
                <div className='login-top-strip'></div>
                <div className='login-card'>
                    <div className='login-logo' style={{ width: "200px" }}>
                        <img src={Images.login_logo} alt='logo' />
                    </div>
                    <div className='login-heading' style={{ fontSize: "34px", textAlign: "center", marginTop: "200px" }}>{CONSTANTS.STRINGS_LIST.ERROR404}</div>
                    <p style={{ textAlign: "center" }}>{CONSTANTS.STRINGS_LIST.PAGE_DOES_NOT_EXIST}</p>
                </div>
                <div className='login-btn' style={{ textAlign: "center" }} >
                    <button onClick={() => history.goBack()}>{CONSTANTS.STRINGS_LIST.BACK}</button>
                </div>
            </div>
        </>
    )
}