import { Images } from "../../../../Assets/Images/Images";
import TopStrip from "../../../../Components/TopStrip";
import { CONSTANTS } from "../../../../Helpers/Constants";
import AdminSettingsView from "./AdminSettingsView";

export default function AdminSettingsSkeleton(props) {
    return (
        <div className="dashboardSkeleton">
            <div className="skeletonContainer">
                <TopStrip
                    path={[CONSTANTS.STRINGS_LIST.SETTINGS]}
                    image={Images.setting_black}
                />
                <AdminSettingsView
                    props={props}
                />
            </div>
        </div>
    )
}