import React from 'react'
import Loader from '../../../../Components/Loader';
import { CONSTANTS } from "../../../../Helpers/Constants";

export default function AdminSettingsTable(props) {
    const propsList = props.props
    return (
        <div className="table">
            <div className="tableContainer">
                {propsList.loading && <Loader />}
                <div className="setting-table-selectors">
                    <div className="setting-table-content">
                        <div className='table-sides'>
                            <div className='setting-table-left'>
                                <div className='left-div-first'>
                                    <p>{CONSTANTS.STRINGS_LIST.FEES} *</p>
                                    <input
                                        onChange={(e) => { propsList.setFees(e.target.value); propsList.setChanges(true) }}
                                        value={propsList?.fees}
                                        type='number'
                                        pattern="\d*"
                                        className="searchBox"
                                        placeholder={CONSTANTS.STRINGS_LIST.FEES}
                                        required
                                    />
                                    <span>
                                        %
                                    </span>
                                </div>
                                <div className='left-div-second'>
                                    <p>{CONSTANTS.STRINGS_LIST.MOMS} *</p>
                                    <input
                                        onChange={(e) => { propsList.setMoms(e.target.value); propsList.setChanges(true) }}
                                        value={propsList?.moms}
                                        type='number'
                                        pattern="\d*"
                                        className="searchBox"
                                        placeholder={CONSTANTS.STRINGS_LIST.MOMS}
                                        required
                                    />
                                    <span>
                                        %
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div className='setting-table-right'>
                                    <div className='right-div-first'>
                                        <p>{CONSTANTS.STRINGS_LIST.TAGS} *</p>
                                        <input
                                            onChange={(e) => propsList.handleSearch(e)}
                                            value={propsList?.search}
                                            placeholder={CONSTANTS.STRINGS_LIST.ADD_TAGS}
                                            required
                                        />
                                        <button onClick={(e) => propsList.addTag(e)} disabled={propsList.search === '' ? true : false}>
                                            {CONSTANTS.STRINGS_LIST.ADD}
                                        </button>

                                    </div>
                                    <div className='right-div-second'>
                                        <ul>
                                            {propsList.filter.length > 0 ?
                                                (propsList.filter?.map((tag, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div>{tag}</div>
                                                        </li>
                                                    )
                                                }))
                                                :
                                                (
                                                    <li>{CONSTANTS.STRINGS_LIST.NO_TAG_AVAILABLE}</li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='save-btn'>
                            <button onClick={(e) => propsList.saveSettings(e)} disabled={!propsList.changes}>{CONSTANTS.STRINGS_LIST.SAVE}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
