import React, { useContext, useEffect, useState } from 'react'
import VendorOrderSkeleton from './Views/VendorPackagesSkeleton'
import { CONSTANTS, QUERY_PARAMS } from '../../../Helpers/Constants'
import moment from 'moment'
import { convertSecondsToHHMM, trim } from '../../../Helpers/Helper'
import {
    searchFetch,
    getUserData,
    fetchPackageVendor,
    prevPageDataPackages,
    nextPageDataPackages,
    checkNextPageDataPackages,
    checkPrevPageDataPackages,
} from '../../../Helpers/FetchHandlers'
import { getLocalItems, removeItemsLocally, setItemsLocally } from '../../../LocalStorage/LocalStorage'
import { UserContext } from '../../../Context/UserContext'

const heads = [
    {
        id: 'img', label: CONSTANTS.STRINGS_LIST.IMAGE, disableSort: true, align: 'left',
    }, {
        id: 'title', label: CONSTANTS.STRINGS_LIST.TITLE, disableSort: true, align: 'left',
    },
    // {
    //     id: 'rating', label: CONSTANTS.STRINGS_LIST.RATING, disableSort: true, align: 'left',
    // },
    {
        id: 'pricePerUnit', label: CONSTANTS.STRINGS_LIST.PRICE_PER_UNIT, align: 'left', disableSort: true,
    }, {
        id: 'serviceTime', label: CONSTANTS.STRINGS_LIST.DELIVERY_TIME, align: 'left', disableSort: true,
    }, {
        id: 'editable', label: '', align: 'left',
    }
]

const statusFields = [
    [CONSTANTS.STRINGS_LIST.ACCEPTED],
    [CONSTANTS.STRINGS_LIST.UNCONFIRMED]
]

const dashBoardHeader = [
    [CONSTANTS.STRINGS_LIST.ENABLED],
    [CONSTANTS.STRINGS_LIST.DISABLED],
]

const listNames = [
    "imageUrl",
    'title',
    'pricePerUnit',
    'serviceTime',
]

const searchListNames = [
    CONSTANTS.STRINGS_LIST.ID,
    CONSTANTS.STRINGS_LIST.COMPANY,
    CONSTANTS.STRINGS_LIST.VENDOR,
]

export default function VendorPackagesDashboard() {
    const [createPackage, setCreatePackage] = useState(false)
    const [packageCreated, setPackageCreated] = useState(false)
    const [ sortList, setSortList ] = useState(false);
    const [create, setCreate] = useState(false)
    const [data, setData] = React.useState([])
    const [filterActive, setFilterActive] = React.useState(false)
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)
    const pages = [10, 20, 30]
    const { loading, setLoading } = useContext(UserContext);

    const [order, setOrder] = React.useState('');
    const [orderBy, setOrderBy] = React.useState();
    const [activeState, setActiveState] = React.useState(CONSTANTS.STRINGS_LIST.ENABLED);
    const [search, setSearch] = React.useState('');
    const [dateActive, setDateActive] = React.useState(true);
    const [orderSizeActive, setOrderSizeActive] = React.useState(true);
    const [orderStatusActive, setOrderStatusActive] = React.useState(true);
    const [searchActive, setSearchActive] = React.useState(true);
    const [orderStatus, setOrderStatus] = React.useState([CONSTANTS.STRINGS_LIST.ORDER_STATUS]);
    const statusText = CONSTANTS.STRINGS_LIST.ORDER_STATUS;
    const [searchOptions, setSearchOptions] = React.useState(CONSTANTS.STRINGS_LIST.SELECT_FIELD);
    const [firstStatusVisible, setFirstStatusVisible] = React.useState(true);
    const firstStatusOptionText = [CONSTANTS.STRINGS_LIST.ORDER_STATUS];
    const searchFieldActive = false;
    const [lastRow, setLastRow] = React.useState([]);
    const [firstRow, setFirstRow] = React.useState([]);
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [showPrevIcon, setShowPrevIcon] = React.useState(true)
    const [showNextIcon, setShowNextIcon] = React.useState(true)

    useEffect(() => {
        if (activeState === CONSTANTS.STRINGS_LIST.ENABLED) {
            fetchEnabled()
        } else if (activeState === CONSTANTS.STRINGS_LIST.DISABLED) {
            fetchDisabled()
        }
        if (getLocalItems(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY) === CONSTANTS.STRINGS_LIST.CREATE_PACKAGE) {
            setCreate(true)
        } else {
            setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.PACKAGES);
            removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY)
            removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.ITEM_KEY)
        }
    }, [packageCreated, activeState, sortList])

    const fetchEnabled = async () => {
        setLoading(true);
        const userData = await getUserData(getLocalItems('user').userId, 'vendor_users')
        const userIDRef = userData.vendorId
        const tempVendors = await fetchPackageVendor(QUERY_PARAMS.VENDOR_QUERIES.fetchVendorPackages, userIDRef)
        const tempData = tempVendors?.data?.map(item => {
            const serviceTime = convertSecondsToHHMM(item.startServiceTimeSeconds) + ' - ' + convertSecondsToHHMM(item.endServiceTimeSeconds)
            return {
                ...item,
                serviceTime: serviceTime,
                createdOn: moment(new Date(item.createdOn.toDate().toUTCString())).format('LLL'),
            }
        })
        if (tempData.length > 0) {
            setLastRow(tempVendors.last)
            setFirstRow(tempVendors.first)
            let nextRecord = []
            let prevRecord = []
            if (activeState === CONSTANTS.STRINGS_LIST.ENABLED) {
                nextRecord = await checkNextPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    tempVendors.last,
                )
                prevRecord = await checkPrevPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    tempVendors.first,
                )
            } else {
                nextRecord = await checkNextPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    tempVendors.last,
                )
                prevRecord = await checkPrevPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    tempVendors.first,
                )
            }
            if (nextRecord === 0) {
                setShowNextIcon(false)
            } else if (nextRecord === 1) {
                setShowNextIcon(true)
            }
            if (prevRecord === 0) {
                setShowPrevIcon(false)
            } else if (prevRecord === 1) {
                setShowPrevIcon(true)
            }
        }
        setData(tempData)
        clearFilter()
        setLoading(false);
    }

    const fetchDisabled = async () => {
        setLoading(true);
        const userData = await getUserData(getLocalItems('user').userId, 'vendor_users')
        const userIDRef = userData.vendorId
        const tempVendors = await fetchPackageVendor(QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_DISABLED, userIDRef)
        const tempData = tempVendors?.data?.map(item => {
            const serviceTime = convertSecondsToHHMM(item.startServiceTimeSeconds) + ' - ' + convertSecondsToHHMM(item.endServiceTimeSeconds)
            return {
                ...item,
                serviceTime: serviceTime,
                createdOn: moment(new Date(item.createdOn.toDate().toUTCString())).format('LLL'),
            }
        })

        if (tempData.length > 0) {
            setLastRow(tempVendors.last)
            setFirstRow(tempVendors.first)
            let nextRecord = []
            let prevRecord = []
            nextRecord = await checkNextPageDataPackages(
                QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_DISABLED,
                userIDRef,
                tempVendors.last,
            )
            prevRecord = await checkPrevPageDataPackages(
                QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_DISABLED,
                userIDRef,
                tempVendors.first,
            )
            if (nextRecord === 0) {
                setShowNextIcon(false)
            } else if (nextRecord === 1) {
                setShowNextIcon(true)
            }
            if (prevRecord === 0) {
                setShowPrevIcon(false)
            } else if (prevRecord === 1) {
                setShowPrevIcon(true)
            }
        }
        clearFilter()
        setData(tempData)
        setLoading(false);
    }

    const handleHeaderClick = (header) => {
        if (header === CONSTANTS.STRINGS_LIST.ENABLED) {
            fetchEnabled()
        } else if (header === CONSTANTS.STRINGS_LIST.DISABLED) {
            fetchDisabled()
        }
        setActiveState(header)
    }


    const setSearchFn = (search) => {
        let target = search.target;
        setSearch(target.value);
    }

    const searchData = async (input) => {
        setLoading(true);
        setFilterActive(true);
        let tempPackages = []
        const search = trim(input)
        const userData = await getUserData(getLocalItems('user').userId, 'vendor_users')
        const userIDRef = userData.vendorId
        if (activeState === CONSTANTS.STRINGS_LIST.ENABLED) {
            tempPackages = await fetchPackageVendor(QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED, userIDRef, search)
        } else {
            tempPackages = await fetchPackageVendor(QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_DISABLED, userIDRef, search)
        }
        const tempData = tempPackages?.data?.map(item => {
            const serviceTime = convertSecondsToHHMM(item.startServiceTimeSeconds) + ' - ' + convertSecondsToHHMM(item.endServiceTimeSeconds)
            return {
                ...item,
                serviceTime: serviceTime,
                createdOn: moment(new Date(item.createdOn.toDate().toUTCString())).format('LLL'),
            }
        })
        if (tempData.length > 0) {
            setLastRow(tempPackages.last)
            setFirstRow(tempPackages.first)
            let nextRecord = []
            let prevRecord = []
            if (activeState === CONSTANTS.STRINGS_LIST.ENABLED) {
                nextRecord = await checkNextPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    tempPackages.last,
                    search,
                )
                prevRecord = await checkPrevPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    tempPackages.first,
                    search,
                )
            } else {
                nextRecord = await checkNextPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    tempPackages.last,
                    search,
                )
                prevRecord = await checkPrevPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    tempPackages.first,
                    search,
                )
            }
            if (nextRecord === 0) {
                setShowNextIcon(false)
            } else if (nextRecord === 1) {
                setShowNextIcon(true)
            }
            if (prevRecord === 0) {
                setShowPrevIcon(false)
            } else if (prevRecord === 1) {
                setShowPrevIcon(true)
            }
        }
        setData(tempData)
        setLoading(false);
    }

    const handleSearch = async e => {
        let target = e.target;
        setSearch(target.value);
        if (e.key === CONSTANTS.STRINGS_LIST.ENTER) {
            searchData(target.value);
        }
    }


    const firstPageFn = async () => {
        const tempOrders = await searchFetch(QUERY_PARAMS.ORDER_QUERIES.fetchOrdersInProcess)
        const tempData = tempOrders.map(item => {
            const serviceTime = convertSecondsToHHMM(item.startServiceTimeSeconds) + ' - ' + convertSecondsToHHMM(item.endServiceTimeSeconds)
            return {
                ...item,
                serviceTime: serviceTime,
                createdOn: moment(new Date(item.createdOn.toDate().toUTCString())).format('LLL'),
            }
        })
        if (tempData.length > 0) {
            setLastRow(tempData[tempData.length - 1]?.orderId)
            setFirstRow(tempData[0]?.orderId)
            setData(tempData)
        }
    }

    const prevPageFn = async () => {
        setLoading(true);
        let tempData = []
        const userData = await getUserData(getLocalItems('user').userId, 'vendor_users')
        const userIDRef = userData.vendorId
        let result = []
        if (activeState === CONSTANTS.STRINGS_LIST.ENABLED) {
            result = await prevPageDataPackages(QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED, userIDRef, firstRow, search)
        } else if (activeState === CONSTANTS.STRINGS_LIST.DISABLED) {
            result = await prevPageDataPackages(QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED, userIDRef, firstRow, search)
        }
        tempData = result?.data?.map(item => {
            const serviceTime = convertSecondsToHHMM(item.startServiceTimeSeconds) + ' - ' + convertSecondsToHHMM(item.endServiceTimeSeconds)
            return {
                ...item,
                serviceTime: serviceTime,
                createdOn: moment(new Date(item.createdOn.toDate().toUTCString())).format('LLL'),
            }
        })
        if (tempData.length > 0) {
            setLastRow(result.last)
            setFirstRow(result.first)
            setData(tempData)
            let nextRecord = []
            let prevRecord = []
            if (activeState === CONSTANTS.STRINGS_LIST.ENABLED) {
                nextRecord = await checkNextPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    result.last,
                    search
                )
                prevRecord = await checkPrevPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    result.first,
                    search
                )
            } else {
                nextRecord = await checkNextPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    result.last,
                )
                prevRecord = await checkPrevPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    result.first,
                )
            }
            if (nextRecord === 0) {
                setShowNextIcon(false)
            } else if (nextRecord === 1) {
                setShowNextIcon(true)
            }
            if (prevRecord === 0) {
                setShowPrevIcon(false)
            } else if (prevRecord === 1) {
                setShowPrevIcon(true)
            }
        }
        setLoading(false);
    }

    const nextPageFn = async () => {
        setLoading(true);
        let tempData = []
        let result = []
        const userData = await getUserData(getLocalItems('user').userId, 'vendor_users')
        const userIDRef = userData.vendorId
        if (activeState === CONSTANTS.STRINGS_LIST.ENABLED) {
            result = await nextPageDataPackages(QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED, userIDRef, lastRow, search)
        } else if (activeState === CONSTANTS.STRINGS_LIST.DISABLED) {
            result = await nextPageDataPackages(QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_DISABLED, userIDRef, lastRow, search)
        }
        tempData = result?.data?.map(item => {
            const serviceTime = convertSecondsToHHMM(item.startServiceTimeSeconds) + ' - ' + convertSecondsToHHMM(item.endServiceTimeSeconds)
            return {
                ...item,
                serviceTime: serviceTime,
                createdOn: moment(new Date(item.createdOn.toDate().toUTCString())).format('LLL'),
            }
        })
        if (tempData.length > 0) {
            setLastRow(result.last)
            setFirstRow(result.first)
            setData(tempData)
            let nextRecord = []
            let prevRecord = []
            if (activeState === CONSTANTS.STRINGS_LIST.ENABLED) {
                nextRecord = await checkNextPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    result.last,
                    search
                )
                prevRecord = await checkPrevPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    result.first,
                    search
                )
            } else {
                nextRecord = await checkNextPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    result.last,
                    search
                )
                prevRecord = await checkPrevPageDataPackages(
                    QUERY_PARAMS.PAGINATION_QUERIES.PAGE_PACKAGES_ENABLED,
                    userIDRef,
                    result.first,
                    search
                )
            }
            if (nextRecord === 0) {
                setShowNextIcon(false)
            } else if (nextRecord === 1) {
                setShowNextIcon(true)
            }
            if (prevRecord === 0) {
                setShowPrevIcon(false)
            } else if (prevRecord === 1) {
                setShowPrevIcon(true)
            }
        }
        setLoading(false);
    }

    const lastPageFn = async () => {
        return
    }

    const clearFilter = async () => {
        setLoading(true);
        let tempData = []
        const userData = await getUserData(getLocalItems('user').userId, 'vendor_users')
        const userIDRef = userData.vendorId
        if (activeState === CONSTANTS.STRINGS_LIST.ENABLED) {
            tempData = await fetchPackageVendor(QUERY_PARAMS.VENDOR_QUERIES.fetchVendorPackages, userIDRef)
        } else if (activeState === CONSTANTS.STRINGS_LIST.DISABLED) {
            tempData = await fetchPackageVendor(QUERY_PARAMS.VENDOR_QUERIES.fetchVendorPackagesDisabled, userIDRef)
        }

        const result = tempData?.data.map(item => {
            const serviceTime = convertSecondsToHHMM(item.startServiceTimeSeconds) + ' - ' + convertSecondsToHHMM(item.endServiceTimeSeconds)
            return {
                ...item,
                serviceTime: serviceTime,
                createdOn: moment(new Date(item.createdOn.toDate().toUTCString())).format('LLL'),
            }
        })

        setOrderStatus([CONSTANTS.STRINGS_LIST.ORDER_STATUS])
        setSearchOptions([CONSTANTS.STRINGS_LIST.SEARCH_FIELD])
        setFirstRow(tempData.first)
        setLastRow(tempData.last)
        setFirstStatusVisible(true)
        setData(result)
        setFilterActive(false)
        setSearch('')
        setStartDate(null)
        setEndDate(null)
        setOrderBy('')
        setOrder('')
        setDateActive(true)
        setOrderStatusActive(true)
        setOrderSizeActive(true)
        setSearchActive(true)
        setLoading(false);
    }

    return (
        <div className=" main-container btn-danger">
            <VendorOrderSkeleton
                data={data}
                filterActive={filterActive}
                handleSearch={handleSearch}
                startDate={startDate}
                endDate={endDate}
                search_change_handler={setSearchFn}
                statusFields={statusFields}
                clearFilter={clearFilter}
                search={search}
                dateActive={dateActive}
                orderSizeActive={orderSizeActive}
                orderStatusActive={orderStatusActive}
                searchActive={true}
                statusText={statusText}
                sortList={sortList}
                setSortList={setSortList}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                order={order}
                orderBy={orderBy}
                page={page}
                rowsPerPage={rowsPerPage}
                pages={pages}
                heads={heads}
                activeTab={activeState}
                listNames={listNames}
                filterVisible={false}
                searchVisible={true}
                setActiveTab={setActiveState}
                dashBoardHeader={dashBoardHeader}
                searchData={searchData}
                setSearchOptions={setSearchOptions}
                // searchOptions={searchOptions}
                searchListNames={searchListNames}
                showFirstStatus={firstStatusVisible}
                setFirstStatusVisible={setFirstStatusVisible}
                firstStatusOption={firstStatusOptionText}
                orderStatus={orderStatus}
                searchOptionVisible={false}
                searchFieldActive={searchFieldActive}
                firstPageFn={firstPageFn}
                prevPageFn={prevPageFn}
                nextPageFn={nextPageFn}
                lastPageFn={lastPageFn}
                handleHeaderClick={handleHeaderClick}
                editable={true}
                create={create}
                setCreate={setCreate}
                createPackage={createPackage}
                setCreatePackage={setCreatePackage}
                searchPlaceholder={CONSTANTS.STRINGS_LIST.SEARCH_PLACEHOLDER_PACKAGES}
                showPrevPgBtn={showPrevIcon}
                showNextPgBtn={showNextIcon}
                loading={loading}
                packageCreated={packageCreated}
                setPackageCreated={setPackageCreated}
            />
        </div>
    )
}