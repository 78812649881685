import { updateDoc, doc, addDoc, collection } from "firebase/firestore";
import { db } from '../Firebase/Config'
import { CONSTANTS } from "./Constants";
import Swal from "sweetalert2";
import { IC_order } from '../Services/OrderApi'
import { DaysToTimestamp, addArrayInDB, getUserData, getVendorProfile } from "./FetchHandlers";
import { deleteDocument, storeImages } from './Helper';
import swal from "sweetalert";
import { convertHMS } from "./WriteHandlers";
import { getLocalItems, removeItemsLocally, setItemsLocally } from "../LocalStorage/LocalStorage";
import { MAX_VALUE_MILLIS } from "@firebase/util";

export const updateOrderStatus = async (orderId, field, status, vendorId) => {
    try {
        await updateDoc(doc(db, 'customer_orders', orderId), field, status);
        if (status.toLowerCase() === CONSTANTS.STRINGS_LIST.CANCELLED.toLowerCase()) {
            await IC_order.cancelOrder(orderId);
        } else if (status.toLowerCase() === CONSTANTS.STRINGS_LIST.ACCEPTED.toLowerCase()) {
            await IC_order.acceptOrder(orderId);
        } else if (status.toLowerCase() === CONSTANTS.STRINGS_LIST.REJECTED.toLowerCase()) {
            await IC_order.rejectOrder(orderId);
        }
    } catch (error) {
        console.log(error)
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: CONSTANTS.STRINGS_LIST.SOMETHING_WENT_WRONG
        })
    }
}

export const updateSettings = async (_id, fees, _moms, _tags) => {
    try {
        await updateDoc(doc(db, 'system_config', _id), {
            instacate_fee: fees,
            moms: _moms,
            tags: _tags
        })
        Swal.fire({
            icon: 'success',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: CONSTANTS.STRINGS_LIST.SETTINGS_UPDATED_SUCCESS,
        })
    } catch (err) {
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: CONSTANTS.STRINGS_LIST.SOMETHING_WENT_WRONG,
        })
    }
}

export const updateVendorSettings = async (_id, data) => {
    const nonBookabledatesinTimeStamp = DaysToTimestamp(data.updatedNonbookableDates);
    const _city = await addArrayInDB(data.city);
    const _location = await addArrayInDB(data.locations);
    const _supplementMarkup = parseInt(data.supplementMarkup);
    const previous_logo = data.logoImageUrl;
    const _logo = Array.isArray(data.logo)
        ? await storeImages(data.vendorId._key.path.segments[6], data.logo[0].name, data.logo[0])
        :
        data.logo !== "" ? previous_logo
            : "https://firebasestorage.googleapis.com/v0/b/instacate-development-en-472ed.appspot.com/o/images%2Fvendors%2FDefault%20Image%2FLogo.png?alt=media&token=12b12d1f-e882-497c-82bf-60eeff960b99"
    try {
        await updateDoc(doc(db, 'vendor_profiles', _id), {
            description: data.description,
            city: _city,
            bookBeforeDays: data.bookBeforeDays,
            isDeliveryEnabled: data.isDeliveryEnabled,
            isPickupEnabled: data.isPickupEnabled,
            isSupplementEnabled: data.isSupplementEnabled,
            maximumOrderCapacity: Number(data.maximumOrderCapacity),
            locations: _location,
            nonBookableDates: nonBookabledatesinTimeStamp,
            supplementMarkUp: _supplementMarkup,
            logoImageUrl: _logo
        })
        Swal.fire({
            icon: 'success',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: CONSTANTS.STRINGS_LIST.SETTINGS_UPDATED_SUCCESS,
        })
    } catch (err) {
        console.log('err', err)
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: CONSTANTS.STRINGS_LIST.SOMETHING_WENT_WRONG,
        })
    }
}

export const updateVendorSortIndex = async (_id, sortIndex) => {
    try {
        await updateDoc(doc(db, 'vendor_profiles', _id), {
            sortIndex: sortIndex,
        })
    }
    catch {
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: CONSTANTS.STRINGS_LIST.SOMETHING_WENT_WRONG,
        })
    }
}

export const updatePackageSortIndex = async (_id, sortIndex) => {
    try {
        await updateDoc(doc(db, 'vendor_packages', _id), {
            sortIndex: sortIndex,
        })
    }
    catch {
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: CONSTANTS.STRINGS_LIST.SOMETHING_WENT_WRONG,
        })
    }
}

export const updatePackage = async (packageData, setCreate, setLoading, packageItemList, packageCreated, setPackageCreated, error, mainId, menuItemEdited, deletedMenuItems, setChanges, packageItemSorted) => {
    try {
        const vendorProfile = await getVendorProfile(getLocalItems('user').userId)
        const maximumCapacity = vendorProfile.vendorProfile.maximumOrderCapacity
        let tempError = false
        let capacityError = false
        let capacityError2 = false
        Object.keys(packageData).map(key => {
            if (key !== CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_URL) {
                if (key === CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MINIMUM_ORDER_CAPACITY) {
                    if (packageData[key] === "") tempError = true
                    else if (packageData[key] > maximumCapacity) capacityError = true
                    else if (packageData[key] < 1) capacityError2 = true
                } else if (key !== CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.TAGS && key !== CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_NAME) {
                    if (packageData[key] === "") tempError = true
                } else if (key !== CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_NAME) {
                    if (packageData[key].length == 0) {
                        tempError = true
                        Swal.fire({
                            text: CONSTANTS.STRINGS_LIST.ADD_ONE_TAG_ATLEAST,
                        })
                    }
                }
            }
        })
        if (tempError) {
            return
        } else if (error) {
            Swal.fire({
                icon: 'error',
                title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                text: CONSTANTS.STRINGS_LIST.FIX_ERROR,
            })
            return
        }
        else if (packageItemList.length === 0) {
            Swal.fire({
                icon: 'error',
                title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                text: CONSTANTS.STRINGS_LIST.ADD_ONE_ITEM_ATLEAST,
            })
        } else if (capacityError) {
            Swal.fire({
                icon: 'error',
                title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                text: CONSTANTS.STRINGS_LIST.MINIMUM_CAPACITY_LESS_THAN + maximumCapacity,
            })
        } else if (capacityError2) {
            Swal.fire({
                icon: 'error',
                title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                text: CONSTANTS.STRINGS_LIST.MINIMUM_CAPACITY_ZERO,
            })
        }
        else {
            swal({
                text: CONSTANTS.STRINGS_LIST.ARE_YOU_SURE_TO_CREATE_PACKAGE,
                buttons: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        setLoading(true)
                        const create = async () => {
                            const name = "tags"
                            const value = []
                            packageData.tags.map(val => {
                                value.push(val.label)
                            })
                            const tempData = { ...packageData, [name]: value }
                            let imageUrl
                            if (Array.isArray(tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_URL]) && tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_URL].length !== 0) {
                                imageUrl = await storeImages(tempData.vendorId._key.path.segments[6], tempData.imageUrl[0].name + " - " + new Date(), tempData.imageUrl[0])
                            } else if (tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_URL] === "") {
                                imageUrl = CONSTANTS.DEFAULT_IMAGE_URL
                            } else {
                                imageUrl = tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_URL]
                            }

                            delete tempData.amountCharged
                            delete tempData.instacate_fee
                            delete tempData.moms
                            delete tempData.imageName
                            tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_URL] = imageUrl
                            tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.START_SERVICE_TIME] = convertHMS(tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.START_SERVICE_TIME])
                            tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.END_SERVICE_TIME] = convertHMS(tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.END_SERVICE_TIME])
                            tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MINIMUM_ORDER_CAPACITY] = Number(tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MINIMUM_ORDER_CAPACITY])
                            tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.PRICE_PER_UNIT] = Number(tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.PRICE_PER_UNIT])

                            menuItemEdited && packageItemList.map(async (item, index) => {
                                if (!item.id) {
                                    //this condition is for new items
                                    const tempItemData = { ...item }
                                    if (packageItemSorted || tempItemData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.SORT_INDEX] === CONSTANTS.STRINGS_LIST.DEFAULT_INDEX_NUMBER) {
                                        tempItemData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.SORT_INDEX] = index + 1
                                    }
                                    if (item.image && item.imageName) {
                                        // const tempItemData = { ...item }
                                        //condition will validate the image is not empty
                                        delete tempItemData.image
                                        delete tempItemData.imageName
                                        const imageUrl = await storeImages(tempData.vendorId._key.path.segments[6], item.imageName + " - " + new Date(), item.image)
                                        tempItemData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_URL] = imageUrl

                                        await addDoc(collection(db, "vendor_packages", mainId, "vendor_package_items"), tempItemData)
                                    } if (item.imageName === '') {
                                        //if we add new item with image and remove the image this condition will work
                                        // const tempItemData = { ...item }
                                        tempItemData[CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.IMAGE_URL] = CONSTANTS.DEFAULT_IMAGE_URL
                                        delete tempItemData.imageName
                                        delete tempItemData.image

                                        await addDoc(collection(db, "vendor_packages", mainId, "vendor_package_items"), tempItemData)
                                    } else if (!item.image) {
                                        //this condition will work when no image added
                                        // const tempItemData = { ...item }
                                        tempItemData[CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.IMAGE_URL] = CONSTANTS.DEFAULT_IMAGE_URL
                                        delete tempItemData.imageName
                                        delete tempItemData.image
                                        await addDoc(collection(db, "vendor_packages", mainId, "vendor_package_items"), tempItemData)
                                    }
                                } else if (item.id) {
                                    //this condition if for existing menuItems
                                    const tempItemData = { ...item }
                                    if (packageItemSorted || tempItemData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.SORT_INDEX] === CONSTANTS.STRINGS_LIST.DEFAULT_INDEX_NUMBER) {
                                        tempItemData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.SORT_INDEX] = index + 1
                                        delete tempItemData.id
                                        await updateDoc(doc(db, "vendor_packages", mainId, "vendor_package_items", item.id), tempItemData)
                                    }
                                    if (!item.image && item.imageName) {
                                        //when we are not adding any new item, we will not get the image field, but the imageName field will be there and we don't need to update imageUrl
                                        delete tempItemData.createdById
                                        delete tempItemData.createdOn
                                        delete tempItemData.id
                                        delete tempItemData.imageName
                                        await updateDoc(doc(db, "vendor_packages", mainId, "vendor_package_items", item.id), tempItemData)
                                    } else if (item.imageName === '') {
                                        //if we remove the image of existing item then this condition will sett the imageURL of defaultImage 
                                        tempItemData[CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.IMAGE_URL] = CONSTANTS.DEFAULT_IMAGE_URL
                                        tempItemData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.SORT_INDEX] = index + 1
                                        delete tempItemData.createdById
                                        delete tempItemData.createdOn
                                        delete tempItemData.id
                                        delete tempItemData.imageName

                                        await updateDoc(doc(db, "vendor_packages", mainId, "vendor_package_items", item.id), tempItemData)
                                    } else if (item.image) {
                                        //if we change the image, we get the new field of image which will hold the image data of new image and will help to generate new URL for new image

                                        let tempItemData = { ...item }
                                        delete tempItemData.createdById
                                        delete tempItemData.createdOn
                                        delete tempItemData.id
                                        delete tempItemData.imageName
                                        delete tempItemData.image
                                        const itemImageUrl = await storeImages(tempData.vendorId._key.path.segments[6], item.imageName + " - " + new Date(), item.image)
                                        tempItemData[CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.IMAGE_URL] = itemImageUrl
                                        tempItemData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.SORT_INDEX] = index + 1
                                        await updateDoc(doc(db, "vendor_packages", mainId, "vendor_package_items", item.id), tempItemData)
                                    }
                                }
                            })

                            deletedMenuItems.length > 0 && deletedMenuItems.map(item => deleteDocument("vendor_packages", mainId, "vendor_package_items", item.id))
                            delete tempData.vendorId
                            await updateDoc(doc(db, 'vendor_packages', mainId), tempData)
                            setLoading(false)
                            setChanges(false)
                            swal(CONSTANTS.STRINGS_LIST.EMPTY_STRING, CONSTANTS.STRINGS_LIST.PACKAGE_CREATED, "success");
                            setCreate(false)
                            setPackageCreated(!packageCreated)
                            setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.PACKAGES)
                        }
                        create()
                    }
                }
                )
        };
    }
    catch (error) {
        setLoading(false)
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: error.message,
        })
    }
}