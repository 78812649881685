import ItemDetailsCard from "./ItemDetailsCard"
import { Images } from "../../../../../Assets/Images/Images"
import { CONSTANTS } from "../../../../../Helpers/Constants"

export default function ItemDetailsComponent({ itemList }) {

    return (
        <div className="item_details_main">
            <div className="item_detail_title">{CONSTANTS.STRINGS_LIST.ITEMS}</div>
            <div className="item_cards">
                {itemList?.map(item => <ItemDetailsCard items={item} />)}
            </div>
        </div>
    )
}