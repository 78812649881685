import AdminVendorsListSortTable from "./AdminVendorsListSortTable";

export default function AdminVendorsListSortSkeleton({
    data,
    loading,
    heads,
    listNames,
    sortList,
    setSortList,
    saveSortedOrder,
    dragState,
    setDragState,
    defaultDragState,
    sortedData,
    onDragEnd,
    isSorted
}) {
    return (
        <div className="dashboardSkeleton">
            <div className="skeletonContainer">
                <AdminVendorsListSortTable
                    data={data}
                    loading={loading}
                    heads={heads}
                    listNames={listNames}
                    sortList={sortList}
                    setSortList={setSortList}
                    saveSortedOrder={saveSortedOrder}
                    dragState={dragState}
                    setDragState={setDragState}
                    defaultDragState={defaultDragState}
                    sortedData={sortedData}
                    onDragEnd={onDragEnd}
                    isSorted={isSorted}
                />
            </div>
        </div>
    )
}