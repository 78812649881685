import React from 'react';
import TopStrip from '../../../../Components/TopStrip';
import { Images } from '../../../../Assets/Images/Images';
import { CONSTANTS } from '../../../../Helpers/Constants';
import VendorSettingsView from './VendorSettingsView';

export default function VendorSettingsSkeleton({ loading, formData, changes, setChanges, _nonBookableDates, changeHandler, onSaveSettings, handleDayClick }) {
    return (
        <div className="dashboardSkeleton">
            <div className="skeletonContainer">
                <TopStrip
                    path={CONSTANTS.STRINGS_LIST.SETTINGS}
                    image={Images.setting_black}
                />
                <VendorSettingsView 
                    loading= {loading}
                    formData={formData}
                    changes={changes}
                    setChanges={setChanges}
                    _nonBookableDates={_nonBookableDates}
                    changeHandler={changeHandler}
                    onSaveSettings={onSaveSettings}
                    handleDayClick={handleDayClick}
                />
            </div>
        </div>
    )
}