import { BrowserRouter, Switch, Route } from "react-router-dom";
import AdminLogin from '../Containers/Admin/AdminLogin';
import AdminVendorDashboard from "../Containers/Admin/AdminVendors";
import AdminFAQDashboard from "../Containers/Admin/AdminFAQ";
import AdminSettingsDashboard from "../Containers/Admin/AdminSettings";
import VendorLogin from "../Containers/Vendor/VendorLogin";
import VendorSettings from '../Containers/Vendor/VendorSettings';
import PasswordReset from "../Containers/Screens/PasswordReset";
import LandingScreen from "../Containers/Screens/LandingScreen";
import { AdminProtected, AdminLoginProtected } from './AdminProtected';
import { VendorProtected, VendorLoginProtected } from "./VendorProtected";
import Dashboard from "../Containers/Admin/AdminOrders";
import ErrorPage from "../Containers/Screens/ErrorPage"
import InstaHOC from "../Components/HOC";
import VendorPackagesDashboard from "../Containers/Vendor/VendorPackages";
import DashboardVendor from "../Containers/Vendor/VendorOrders";


export default function AppRouter() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={LandingScreen} />
                <AdminLoginProtected exact path="/Admin" component={AdminLogin} />
                <AdminLoginProtected exact path="/Admin/Forget_Password" component={PasswordReset} />
                <AdminProtected exact path="/Admin/Orders" component={InstaHOC(Dashboard)} />
                <AdminProtected exact path="/Admin/Vendors" component={InstaHOC(AdminVendorDashboard)} />
                <AdminProtected exact path="/Admin/FAQ" component={InstaHOC(AdminFAQDashboard)} />
                <AdminProtected exact path="/Admin/Settings" component={InstaHOC(AdminSettingsDashboard)} />

                <VendorLoginProtected exact path="/Vendor" component={VendorLogin} />
                <VendorLoginProtected exact path="/Vendor/Forget_Password" component={PasswordReset} />
                <VendorProtected exact path="/Vendor/Vendors" component={InstaHOC(AdminVendorDashboard)} />
                <VendorProtected exact path="/Vendor/Orders" component={InstaHOC(DashboardVendor)} />
                <VendorProtected exact path="/Vendor/FAQ" component={InstaHOC(AdminFAQDashboard)} />
                <VendorProtected exact path="/Vendor/Packages" component={InstaHOC(VendorPackagesDashboard)} />
                <VendorProtected exact path="/Vendor/Settings" component={InstaHOC(VendorSettings)} />

                <Route exact component={ErrorPage} />
            </Switch>
        </BrowserRouter>
    );
}
