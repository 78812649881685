import { Images } from "../Assets/Images/Images"

export const CONSTANTS = {
    STRINGS_LIST: {
        AREA: "Area",
        MAXIMUM_ORDER_CAPACITY: "Maximum Order Capacity",
        INSTACATE_TEMPORARY_LANDING_PAGE: "INSTACATE TEMPORARY LANDING PAGE",
        LOGIN_AS_ADMIN: "Login as Admin",
        LOGIN_AS_VENDOR: "Login as Vendor",
        PAGE_DOES_NOT_EXIST: "Sorry! page does not exist",
        ERROR404: "404 ERROR",
        SEARCH_PLACEHOLDER_PACKAGES: "Search by title",
        EMPTY_STRING: "",
        ADMIN_LOGIN: 'Admin Login',
        VENDOR_LOGIN: 'Vendor Login',
        EMAIL: 'Email',
        EMAIL_SMALL: 'email',
        EMAIL_ADDRESS: 'Email Address',
        TYPE_EMAIL: 'Type your email',
        LOGIN_BUTTON: 'Login',
        FORGOT_YOUR_PASSWORD: 'Forgot Your Password ?',
        PASSWORD: 'Password',
        PASSWORD_SMALL: 'password',
        TYPE_PASSWORD: 'Type your password',
        FORGET_PASSWORD: 'Forget Password',
        SEND_RESET_EMAIL: 'Send Reset Email',
        ADMIN: 'Admin',
        ORDERS: 'Orders',
        ORDERS_LOWERCASE: "orders",
        ORDER_DETAILS: 'Order Details',
        VENDOR: 'Vendor',
        VENDOR_LOWERCASE: "vendor",
        VENDORS: 'Vendors',
        CREATE_VENDOR: 'Create Vendor',
        FAQ: 'FAQ',
        CREATE_FAQ: 'Create FAQ',
        SETTINGS: 'Settings',
        IN_PROCESS: 'In process',
        ACCEPTED: 'Accepted',
        SELECT_AREA: "Please! select an area",
        ACCEPT: 'Accept',
        SEARCH_FIELD: 'Search Field',
        REJECTED: 'Rejected',
        REJECT: 'Reject',
        CANCEL: 'Cancel',
        SAVE: 'Save',
        CANCELLED: 'Cancelled',
        ID: 'ID',
        COMPANY: 'Company',
        ADDRESS_AREA: 'Address Area',
        DELIVERY_DATE: 'Delivery Date',
        DELIVERY_TIME: 'Delivery Time',
        PICKUP_ADDRESS: "Pickup Address",
        PICKUP_DATE: 'Pickup Date',
        PICKUP_TIME: 'Pickup Time',
        DELIVERY_MODE: 'Delivery Mode',
        STATUS: 'Status',
        ENABLED: 'Enabled',
        ENABLED_LOWERCASE: "enabled",
        DISABLED: 'Disabled',
        ORGANIZATION_NUMBER: 'Organization Number',
        CONTACT_PERSON: 'Contact Person',
        CONTACT_NUMBER: 'Contact Number',
        FEES: 'Fees',
        TAGS: 'Tags',
        ADD_TAGS: 'Add tags',
        TAG_ADDED_MESSAGE: 'Tag Added Successfully',
        TAG_ADDED_FAILED: 'Tag Already Exists',
        ORDER_CANCEL_MESSAGE: 'Order Cancelled Successfully',
        ORDER_ACCEPT_MESSAGE: 'Order Accepted Successfully',
        ORDER_REJECT_MESSAGE: 'Order Rejected Successfully',
        SETTINGS_UPDATED_SUCCESS: 'Settings Updated Successfully',
        SETTINGS_UPDATED_FAILED: 'Settings Update Failed',
        VENDOR_LIST_SORT_UPDATED_SUCCESS: 'Vendor List Sort Updated Success',
        VENDOR_LIST_SORT_UPDATED_FAILED: 'Please Sort before Updating',
        VENDOR_LIST_SORT_UPDATED_FAILED: 'Vendor List Sort Updated Failed',
        PACKAGE_LIST_SORT_UPDATED_SUCCESS: 'Package List Sort Updated Success',
        ADD: 'Add',
        MOMS: 'MOMS',
        CUSTOMER_INFO: 'Customer Information',
        ORDER_INFO: 'Order Information',
        DELIVERY_INFO: 'Delivery Information',
        PICKUP_INFO: 'Pickup Information',
        ORDER_STATUS: 'Order Status',
        ORDER_SIZE: 'Order Size',
        UNCONFIRMED: 'Unconfirmed',
        PACKAGES: 'Packages',
        REMAINING_TIME: 'Remaining time to confirm:',
        REMAINING_TIME_LIST: 'Remaining time:',
        IMAGE: 'Image',
        TITLE: 'Title',
        ALLERGIES: 'Allergies',
        RATING: 'Rating',
        PRICE_PER_UNIT: 'Price Per Unit',
        QUANTITY: 'Quantitiy',
        TOTAL_AMOUNT: 'Total Amount',
        SUB_TOTAL: 'Subtotal',
        SUPLEMENTS: 'Suplements',
        INSTACATE_FEE: 'Instacate Fee',
        CUSTOMER_NAME: 'Customer Name',
        BILLING_ADDRESS: 'Billing Address',
        TOTAL_QUANTITY: 'Total Quantity',
        NUMBER_OF_PEOPLE: 'Number of People',
        ORDER_TOTAL: 'Order Total',
        ORDER_DATE: 'Order Date',
        ORDER_TIME: 'Order Time',
        SERVICE_MODE: 'Service Mode',
        SERVICE_DATE: 'Service Date',
        SERVICE_TIME: 'Service Time',
        SERVICE_ADDRESS: 'Service Address',
        DELIVERY_ADDRESS: 'Delivery Address',
        COMPANY_INFORMATION: 'Company Information',
        COMPANY_NAME: 'Company Name',
        VENDOR_NAME: 'Vendor Name',
        CITY: 'City',
        ADDRESS: 'Address',
        COMPLETE_ADDRESS: 'complete address',
        POSTAL_CODE: 'Postal Code',
        NAME: 'Name',
        TELEPHONE_NUMBER: 'Telephone Number',
        PHONE: 'phone',
        USER: 'User',
        CREATE_PASSWORD: 'Create Password',
        ENTER_PASSWORD: 'enter password',
        CONFIRM_PASSWORD: 'Confirm Password',
        EMAIL_ERROR_MESSAGE: 'Enter a valid email',
        PASSWORD_ERROR_MESSAGE: 'Wrong password. Try again.',
        PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
        EDIT_VENDOR: 'Edit Vendor',
        PHONE_VALIDATION: 'Please enter valid phone number! (only 7 to 9 digits with country code)',
        FIX_ERROR: 'Please fix the error',
        DONE: 'DONE!',
        VENDOR_CREATED: 'Vendor has been created.',
        VENDOR_UPDATED: 'Vendor has been updated.',
        FAQ_CREATED: 'FAQ has been created.',
        FAQ_UPDATED: 'FAQ has been updated.',
        SUCCESS: 'success',
        ORGANIZATION_NUMBER_ERROR: 'Organization number already exists.',
        EMAIL_EXIST_ERROR: 'Email address already in use.',
        EDIT_FAQ: 'Edit FAQ',
        ADD_FAQ: 'Add FAQ',
        WRITE_QUESTION: 'Write question',
        WRITE_ANSWER: 'Write answer',
        ANSWER: 'Answer',
        REQUIRED_FIELDS_MESSAGE: 'Please enter the requried fields',
        START_DATE: 'Start Date',
        END_DATE: 'End Date',
        SEARCH: 'Search',
        RESET_MAIL_SENT: 'Check your inbox for reset link',
        BACK: 'Back',
        PACKAGE_DETAILS: 'Package Details',
        CREATE_PACKAGE: 'Create Package',
        EDIT_PACKAGE: 'Save Package',
        TIME: "Time",
        CAPACITY: "Capacity",
        MINIMUM_CAPACITY: "Minimum Order Capacity",
        MINIMUM_CAPACITY_LESS_THAN: "Please enter valid minimum order capacity. Currently, your maximum capacity is: ",
        DESCRIPTION: "Description",
        PRICE: "Price",
        AMOUNT_CHARGED: "Amount Charged",
        ALLERGIES_CAN_HANDLE: "Allergies we can handle",
        DIRECT_BOOKING: "Direct Booking",
        FROM: "From",
        TO: "To",
        MENU_ITEMS: "Menu Items",
        ADD_FIRST_ITEM: " Add your first item",
        ADD_ITEM: "Add Item",
        EDIT_ITEM: "Edit Item",
        SAVE_ITEM: "Save Item",
        ITEMS: "Items",
        MODE_KEYWORD: "mode",
        CLEAR_FILTER: "Clear Filter",
        ENTER: "Enter",
        SELECT_FIELD: "Select Field",
        SOMETHING_WENT_WRONG: "Something went wrong",
        ASSCENDING: "Ascending",
        DESCENDING: "Descending",
        ORDERLIST_TOPBUTTONS: "Reset",
        RESET: "Reset",
        FIELD_NOT_EMPTY: "Fields must not be empty",
        LOG_OUT: "Logout",
        NO_PACKAGES: "No Packages",
        DETAILS: "Details",
        CATEGORY: "Category",
        SAVE_UPDATED_CHANGES: 'Save updated changes?',
        ARE_YOU_SURE: "Are you sure you want to leave this page?",
        ARE_YOU_SURE_TO_CREATE_PACKAGE: "Are you sure you want to proceed?",
        ARE_YOU_SURE_DELETE_MESSAGE: "Are you sure you want to delete?",
        YOUR_ITEM_DELETED: "Item has been deleted from the package",
        CONFIRM_REJECT: "Are you sure you want to reject this order?",
        CONFIRM_CANCEL: "Are you sure you want to cancel this order?",
        CONFIRM_REJECT_TITLE: "Reject Order",
        CONFIRM_CANCEL_TITLE: "Cancel Order",
        CONFIRM_ACCEPT: "Are you sure you want to accept this order?",
        CONFIRM_ACCEPT_TITLE: "Accept Order",
        NO_TAG_AVAILABLE: 'No Tag Available',
        PASSWORD_LENGTH: 'Password must be atleast 6 characters.',
        INVALID_PASSWORD: "Invalid or weak password",
        GENERAL_ERROR_VENDOR: "Vendor is not created",
        DRAG_DROP: "Drag and Drop here or",
        BROWSE: "Browse",
        END_TIME_ERROR: "Couldn't be less than start time!",
        REMOVE_IMAGE: "remove image",
        COURSE_SECTION: "Course Section",
        LOGO: "Logo",
        CHOOSE_AREA: "Choose Area",
        SUPPLEMENTS: "Supplements",
        MARKUP: "Markup (%)",
        CHOOSE_CITY: "Choose City",
        BOOK_BEFORE_DAYS: "Book Before (days)",
        DAYS: "Days",
        MODE: "Mode",
        DELIVERY: "Delivery",
        COLLECTION: "Collection",
        NON_BOOKABLE_DAYS: "Non-bookable days",
        ADD_ONE_ITEM_ATLEAST: "Please! add atleast 1 item for creating package.",
        ADD_ONE_TAG_ATLEAST: "Please! add atleast 1 tag.",
        ITEM_ADDED: "Item added successfully",
        ITEM_EDITED: "Item edited successfully",
        PACKAGE_CREATED: "Package created successfully",
        SEK: "kr",
        ENTER_TO_SEARCH: "Please write something to search",
        SELECT_TO_SEARCH: "Please select any field to search",
        ENTER_ORDER_SIZE: "Please enter order size to search",
        MINIMUM_CAPACITY_ZERO: "Minimum order capacity can't be zero",
        SAVE_SORT_ORDERS: "Save Sort Order",
        MANAGE_SORT: "Manage Sort",
        SORT_LIST: "Sort List",
        DEFAULT_INDEX_NUMBER: 99999,
    },
    LOCAL_STORAGE_ITEMS: {
        FAQ_KEY: 'FAQ',
        MODE_KEY: 'mode',
        VENDOR_KEY: 'vendor',
        USER_KEY: 'user',
        EMAIL_KEY: 'email',
        PASSWORD_KEY: 'password',
        USER_TYPE_KEY: 'user_type',
        DETAILS_KEY: 'details',
        EDIT_KEY: 'edit',
        EDIT_ITEM_KEY: 'editItem',
        ITEM_KEY: "item",
        MENU_ITEMS_KEY: "menuItems",
        SORTED_VENDORS_KEY: "sortVendorsList",
        SORTED_PACKAGES_KEY: "sortPackagesList",
    },
    API: {
        COLLECTION_NAMES: {
            CUSTOMER_ORDERS: 'customer_orders',
            ADMIN_USERS: 'admin_users',
            VENDOR_USERS: 'vendor_users',
            CUSTOMER_USERS: 'customer_users',
        }
    },
    FIELDS: {
        FAQ_KEYS: {
            ID: 'id',
            QUESTION: "question",
            ANSWER: "answer",
            CREATED_BY: "createdById",
            CREATED_ON: "createdOn",
            IS_ACTIVE: "isActive",
            MODIFIED_BY: "modifiedById",
            MODIFIED_ON: "modifiedOn",
        },
        CREATE_VENDOR_KEYS: {
            BOOK_BEFORE_DAYS: "bookBeforeDays",
            CREATED_BY: "createdById",
            CITY: "city",
            CREATED_ON: "createdOn",
            DESCRIPTION: "description",
            EMAIL_ADDRESS: "emailAddress",
            CONTACT_EMAIL: "contactEmailAddress",
            IS_ACTIVE: "isActive",
            IS_DELIVERY_ENABLED: "isDeliveryEnabled",
            IS_PICKUP_ENABLED: "isPickupEnabled",
            IS_SUPPLEMENT_ENABLED: "isSupplementEnabled",
            LOCATIONS: "locations",
            LOGO_IMAGE_URL: "logoImageUrl",
            MAXIMUM_ORDER_CAPACITY: "maximumOrderCapacity",
            MODIFIED_BY: "modifiedById",
            MODIFIED_ON: "modifiedOn",
            NON_BOOKABLE_DATES: "nonBookableDates",
            ADDRESS: "address",
            VENDOR_CITY: "vendorCity",
            VENDOR_AREA: "vendorArea",
            CONTACT_NUMBER: "contactNumber",
            CONTACT_PERSON_NAME: "contactPersonName",
            NAME: "name",
            ORGANIZATION_NUMBER: "organizationNumber",
            POSTAL_CODE: "postalCode",
            STATUS: "status",
            SUPPLEMENT_MARKUP: "supplementMarkUp",
            SORT_INDEX: "sortIndex",
            VENDOR_ID: 'vendorId',
        },
        CREATE_PACKAGE_KEYS: {
            CREATED_BY: "createdById",
            CREATED_ON: "createdOn",
            DESCRIPTION: "description",
            END_SERVICE_TIME: "endServiceTimeSeconds",
            IMAGE_URL: "imageUrl",
            IMAGE_NAME: "imageName",
            IS_DIRECT_BOOKING: "isDirectBooking",
            MINIMUM_ORDER_CAPACITY: "minimumOrderCapacity",
            MODIFIED_BY: "modifiedById",
            MODIFIED_ON: "modifiedOn",
            PRICE_PER_UNIT: "pricePerUnit",
            START_SERVICE_TIME: "startServiceTimeSeconds",
            STATUS: "status",
            TAGS: "tags",
            TITLE: "title",
            VENDOR_ID: "vendorId",
            MOMS: "moms",
            INSTACATE_FEE: "instacate_fee",
            AMOUNT_CHARGED: "amountCharged",
            CREATE_PACKAGE: "createPackage",
            SORT_INDEX: "sortIndex",
        },
        PACKAGE_ITEMS_KEYS: {
            CATEGORY: "category",
            COURSE: "course",
            CREATED_BY: "createdById",
            CREATED_ON: "createdOn",
            DESCRIPTION: "description",
            IMAGE_URL: "imageUrl",
            IS_ACTIVE: "isActive",
            MODIFIED_BY: "modifiedById",
            MODIFIED_ON: "modifiedOn",
            TITLE: "title",
            ADD_BUTTON:"add_item",
            ADD_ITEM_OPEN:"add_item_open",
            SORT_INDEX:"sortIndex"
        },
        VENDOR_SETTINGS_KEYS: {
            DESCRIPTION: 'description',
            CITY: 'city',
            LOCATION: 'locations',
            BOOK_BEFORE_DAYS: 'bookBeforeDays',
            IS_DELIVERY_ENABLED: 'isDeliveryEnabled',
            IS_PICKUP_ENABLED: 'isPickupEnabled',
            IS_SUPPLEMENT_ENABLED: 'isSupplementEnabled',
            NON_BOOKABLE_DATES: 'nonBookableDates',
            SUPPLEMENT_MARKUP: 'supplementMarkup',
            LOGO: 'logo',
            MAXIMUM_ORDER_CAPACITY: "maximumOrderCapacity"
        },
        CUSTOMER_ORDERS: {
            CUSTOMER_COMPANY: "customerCompany",
            VENDOR_NAME: "vendorName",
            ORDER_ID: "orderId",
            STATUS: "status",
            SERVICE_DATE: "serviceDate",
            TOTAL_PACKAGE_QUANTITY: "totalPackageQuantity",
            VENDOR_ID: "vendorId",
            SERVICE_AREA: "serviceArea",
            SERVICE_MODE: "serviceMode",
        },
        VENDOR_PROFILES: {
            VENDOR_NAME: "name"
        }
    },
    DEFAULT_IMAGE_URL: "https://firebasestorage.googleapis.com/v0/b/instacate-development-en-472ed.appspot.com/o/images%2Fvendors%2FDefault%20Image%2Fimage.png?alt=media&token=2ab9155c-ec33-417e-9d89-2f1596d22e70"
}

export const TABLES = {
    ADMIN_ORDER_HEADS: [
        {
            id: 'id', label: CONSTANTS.STRINGS_LIST.ID, sortable: true, align: 'left',
        }, {
            id: 'company', label: CONSTANTS.STRINGS_LIST.COMPANY, sortable: false, align: 'left',
        }, {
            id: 'vendor', label: CONSTANTS.STRINGS_LIST.VENDOR, sortable: true, align: 'left',
        }, {
            id: 'address_area', label: CONSTANTS.STRINGS_LIST.ADDRESS_AREA, sortable: false, align: 'left',
        }, {
            id: 'serviceDate', label: CONSTANTS.STRINGS_LIST.DELIVERY_DATE, sortable: true, align: 'left',
        }, {
            id: 'status', label: CONSTANTS.STRINGS_LIST.STATUS, sortable: false, align: 'left',
        }
    ],
    ADMIN_ORDER_LISTNAMES: [
        "orderId",
        'customerCompany',
        'vendorName',
        'serviceArea',
        'serviceDate',
        'status',
    ],
    VENDOR_ORDER_HEADS: [
        {
            id: 'orderId', label: CONSTANTS.STRINGS_LIST.ID, sortable: true, align: 'left',
        }, {
            id: 'customerCompany', label: CONSTANTS.STRINGS_LIST.COMPANY, sortable: false, align: 'left',
        }, {
            id: 'serviceArea', label: CONSTANTS.STRINGS_LIST.ADDRESS_AREA, sortable: false, align: 'left',
        }, {
            id: 'serviceDate', label: CONSTANTS.STRINGS_LIST.DELIVERY_DATE, sortable: true, align: 'left',
        }, {
            id: 'serviceMode', label: CONSTANTS.STRINGS_LIST.DELIVERY_MODE, sortable: false, align: 'left',
        }, {
            id: 'status', label: CONSTANTS.STRINGS_LIST.STATUS, sortable: false, align: 'left',
        }
    ],
    VENDOR_ORDER_LISTNAMES: [
        "orderId",
        "customerCompany",
        "serviceArea",
        "serviceDate",
        "serviceMode",
        "status",
    ],
    ORDER_DASHBOARD_HEADERS: [
        [CONSTANTS.STRINGS_LIST.IN_PROCESS],
        [CONSTANTS.STRINGS_LIST.REJECTED],
        [CONSTANTS.STRINGS_LIST.CANCELLED],
    ],
    ORDER_STATUS_FIELDS: [
        CONSTANTS.STRINGS_LIST.ACCEPTED,
        CONSTANTS.STRINGS_LIST.UNCONFIRMED
    ]
}

export const LIST = {
    ADMIN_LIST: {
        [CONSTANTS.STRINGS_LIST.ORDERS]: Images.order_icon,
        [CONSTANTS.STRINGS_LIST.VENDORS]: Images.order_icon,
        [CONSTANTS.STRINGS_LIST.FAQ]: Images.order_icon,
        [CONSTANTS.STRINGS_LIST.SETTINGS]: Images.order_icon,
    },
    VENDOR_ORDER_SEARCH_LIST: [
        CONSTANTS.STRINGS_LIST.ID,
        CONSTANTS.STRINGS_LIST.COMPANY,
    ]
};

export const QUERY_PARAMS = {
    ORDER_QUERIES: {
        fetchOrdersInProcess: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersInProcessByIdAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'orderId',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersInProcessByIdAscWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'orderId',
        },
        fetchOrdersInProcessByVendorAscWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'vendorName',
        },
        fetchOrdersInProcessByDeliveryDateAscWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'serviceDate',
        },
        fetchOrdersInProcessByIdAscWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'orderId',
        },
        fetchOrdersCancelledByIdAscWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'orderId',
        },
        fetchOrdersCancelledByIdAscWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'orderId',
        },
        fetchOrdersCancelledByIdAscWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'orderId',
        },
        fetchOrdersCancelledByIdAscWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'orderId',
        },
        fetchOrdersCancelledByIdAscWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'orderId',
        },

        fetchOrdersCancelledByVendorAscWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'vendorName',
        },
        fetchOrdersCancelledByVendorAscWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'vendorName',
        },
        fetchOrdersCancelledByVendorAscWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'vendorName',
        },
        fetchOrdersCancelledByVendorAscWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'vendorName',
        },
        fetchOrdersCancelledByVendorAscWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'vendorName',
        },
        fetchOrdersCancelledByDeliveryDateAscWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'orderId',
        },
        fetchOrdersCancelledByDeliveryDateAscWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'orderId',
        },
        fetchOrdersCancelledByDeliveryDateAscWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'orderId',
        },
        fetchOrdersCancelledByDeliveryDateAscWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'orderId',
        },
        fetchOrdersCancelledByDeliveryDateAscWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'orderId',
        },

        fetchOrdersRejectedByIDDescWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'orderId',
        },
        fetchOrdersRejectedByIDDescWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'orderId',
        },
        fetchOrdersRejectedByIDDescWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'orderId',
        },
        fetchOrdersRejectedByIDDescWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'orderId',
        },
        fetchOrdersRejectedByIDDescWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'orderId',
        },

        fetchOrdersRejectedByVendorDescWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'vendorName',
        },
        fetchOrdersRejectedByVendorDescWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'vendorName',
        },
        fetchOrdersRejectedByVendorDescWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'vendorName',
        },
        fetchOrdersRejectedByVendorDescWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'vendorName',
        },
        fetchOrdersRejectedByVendorDescWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'vendorName',
        },

        fetchOrdersRejectedByDeliveryDateDescWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'serviceDate',
        },
        fetchOrdersRejectedByDeliveryDateDescWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'serviceDate',
        },
        fetchOrdersRejectedByDeliveryDateDescWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'serviceDate',
        },
        fetchOrdersRejectedByDeliveryDateDescWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'serviceDate',
        },
        fetchOrdersRejectedByDeliveryDateDescWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'serviceDate',
        },

        fetchOrdersCancelledByIDDescWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'orderId',
        },
        fetchOrdersCancelledByIDDescWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'orderId',
        },
        fetchOrdersCancelledByIDDescWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'orderId',
        },
        fetchOrdersCancelledByIDDescWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'orderId',
        },
        fetchOrdersCancelledByIDDescWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'orderId',
        },

        fetchOrdersCancelledByVendorDescWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'vendorName',
        },
        fetchOrdersCancelledByVendorDescWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'vendorName',
        },
        fetchOrdersCancelledByVendorDescWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'vendorName',
        },
        fetchOrdersCancelledByVendorDescWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'vendorName',
        },
        fetchOrdersCancelledByVendorDescWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'vendorName',
        },

        fetchOrdersCancelledByDeliveryDateDescWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'serviceDate',
        },
        fetchOrdersCancelledByDeliveryDateDescWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'serviceDate',
        },
        fetchOrdersCancelledByDeliveryDateDescWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'serviceDate',
        },
        fetchOrdersCancelledByDeliveryDateDescWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'serviceDate',
        },
        fetchOrdersCancelledByDeliveryDateDescWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderStatus',
            comparator2: '==',
            order_by: 'serviceDate',
        },

        fetchOrdersInProcessByVendorAscWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'vendorName',
        },
        fetchOrdersInProcessByDeliveryDateAscWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'serviceDate',
        },
        fetchOrdersInProcessByIdAscWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'orderId',
        },
        fetchOrdersInProcessByVendorAscWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'vendorName',
        },

        fetchOrdersInProcessByDeliveryDateAscWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'serviceDate',
        },

        fetchOrdersInProcessByIdAscWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'accepted',
            sort_method: 'asc',
            query_limit: '10',
            order_by: 'orderId',
        },
        fetchOrdersInProcessByVendorAscWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'accepted',
            sort_method: 'asc',
            query_limit: '10',
            order_by: 'vendorName',
        },
        fetchOrdersInProcessByDeliveryDateAscWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'accepted',
            sort_method: 'asc',
            query_limit: '10',
            order_by: 'serviceDate',
        },

        fetchOrdersInProcessByIdAscWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'unconfirmed',
            sort_method: 'asc',
            query_limit: '10',
            order_by: 'orderId',
        },
        fetchOrdersInProcessByVendorAscWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'unconfirmed',
            sort_method: 'asc',
            query_limit: '10',
            order_by: 'vendorName',
        },
        fetchOrdersInProcessByDeliveryDateAscWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'unconfirmed',
            sort_method: 'asc',
            query_limit: '10',
            order_by: 'serviceDate',
        },
        fetchOrdersRejectedByIdAscWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'orderId',
        },
        fetchOrdersRejectedByIdAscWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'orderId',
        },
        fetchOrdersRejectedByIdAscWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'orderId',
        },
        fetchOrdersRejectedByIdAscWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            order_by: 'orderId',
        },
        fetchOrdersRejectedByIdAscWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            order_by: 'orderId',
        },
        fetchOrdersRejectedByVendorAscWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'vendorName',
        },
        fetchOrdersRejectedByVendorAscWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'vendorName',
        },
        fetchOrdersRejectedByVendorAscWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'vendorName',
        },
        fetchOrdersRejectedByVendorAscWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            order_by: 'vendorName',
        },
        fetchOrdersRejectedByVendorAscWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            order_by: 'vendorName',
        },
        fetchOrdersRejectedByDeliveryDateAscWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'serviceDate',
        },
        fetchOrdersRejectedByDeliveryDateAscWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'serviceDate',
        },
        fetchOrdersRejectedByDeliveryDateAscWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'serviceDate',
        },
        fetchOrdersRejectedByDeliveryDateAscWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            order_by: 'serviceDate',
        },

        fetchOrdersRejectedByDeliveryDateAscWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            sort_method: 'asc',
            query_limit: '10',
            order_by: 'serviceDate',
        },
        fetchOrdersInProcessByIDDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'desc',
            query_limit: '10',
            order_by: 'orderId',
        },
        fetchOrdersInProcessByIDDescWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'orderId',
        },
        fetchOrdersInProcessByIDDescWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'orderId',
        },
        fetchOrdersInProcessByIDDescWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'orderId',
        },
        fetchOrdersInProcessByIDDescWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'accepted',
            sort_method: 'desc',
            query_limit: '10',
            order_by: 'orderId',
        },
        fetchOrdersInProcessByIDDescWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'unconfirmed',
            sort_method: 'desc',
            query_limit: '10',
            order_by: 'orderId',
        },

        fetchOrdersInProcessByIdAscWithSearch: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'orderId',
        },

        fetchOrdersInProcessByVendorDescWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'vendorName',
        },
        fetchOrdersInProcessByVendorDescWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'vendorName',
        },
        fetchOrdersInProcessByVendorDescWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'vendorName',
        },
        fetchOrdersInProcessByVendorDescWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'accepted',
            sort_method: 'desc',
            query_limit: '10',
            order_by: 'vendorName',
        },

        fetchOrdersInProcessByDeliveryDateDescWithStartDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            order_by: 'serviceDate',
        },
        fetchOrdersInProcessByDeliveryDateDescWithEndDate: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            order_by: 'serviceDate',
        },
        fetchOrdersInProcessByDeliveryDateDescWithOrderSize: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            order_by: 'serviceDate',
        },
        fetchOrdersInProcessByDeliveryDateDescWithOrderStatusAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'accepted',
            sort_method: 'desc',
            query_limit: '10',
            order_by: 'serviceDate',
        },
        fetchOrdersInProcessByDeliveryDateDescWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'unconfirmed',
            sort_method: 'desc',
            query_limit: '10',
            order_by: 'serviceDate',
        },

        fetchOrdersInProcessByVendorDescWithOrderStatusUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'unconfirmed',
            sort_method: 'desc',
            query_limit: '10',
            order_by: 'vendorName',
        },
        fetchOrdersInProcessByIdDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersInProcessByVendorAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'vendorName',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersInProcessByVendorDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'vendorName',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersInProcessByDelDateAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'serviceDate',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersInProcessByDelDateDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersRejectedByIDAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'orderId',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersRejectedByIDDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersRejectedByVendorAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'vendorName',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersRejectedByVendorDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'vendorName',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersRejectedByDelDateAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'serviceDate',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersRejectedByDelDateDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersCancelledByIDAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'orderId',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersCancelledByIDDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersCancelledByVendorAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'vendorName',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersCancelledByVendorDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'vendorName',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersCancelledByDelDateAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'serviceDate',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersCancelledByDelDateDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
        },
        searchOrderAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'accepted',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        searchOrderUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'unconfirmed',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        searchOrderIDAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderId',
            comparator2: '==',
        },
        searchOrderIDRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderId',
            comparator2: '==',
        },
        searchOrderIDCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderId',
            comparator2: '==',
        },
        searchOrderCompanyAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'customerCompany',
            comparator2: '==',
        },
        searchOrderCompanyRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'customerCompany',
            comparator2: '==',
        },
        searchOrderCompanyCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'customerCompany',
            comparator2: '==',
        },
        searchOrderVendorAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'vendorName',
            comparator2: '==',
        },
        searchOrderVendorRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'vendorName',
            comparator2: '==',
        },
        searchOrderVendorCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'vendorName',
            comparator2: '==',
        },
        searchOrderSizeAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
        },
        searchOrderSizeRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
        },
        searchOrderSizeCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
        },
        searchOrderStartDateEndDateAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            field_name3: 'serviceDate',
            comparator3: '<=',
        },
        searchOrderStartDateAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>',
        },
        searchOrderStartDateRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
        },
        searchOrderStartDateEndDateRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            field_name3: 'serviceDate',
            comparator3: '<=',
        },
        searchOrderStartDateEndDateCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            field_name3: 'serviceDate',
            comparator3: '<=',
        },
        searchOrderStartDateCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
        },
        searchOrderEndDateAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
        },
        searchOrderEndDateRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
        },
        searchOrderEndDateCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
        },
        searchOrderDateAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            field_name3: 'serviceDate',
            comparator3: '<=',
        },
        searchOrderDateRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            field_name3: 'serviceDate',
            comparator3: '<=',
        },
        searchOrderDateCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            field_name3: 'serviceDate',
            comparator3: '<=',
        },

    },
    PACKAGES_QUERIES: {
        fetchAllPackages: {
            collection_name: 'vendor_packages',
            order_by: 'sortIndex',
            sort_method: 'asc',
        },
    },
    VENDOR_QUERIES: {
        fetchAllVendors: {
            collection_name: 'vendor_profiles',
            order_by: 'sortIndex',
            sort_method: 'asc',
        },
        fetchVendors: {
            collection_name: 'vendor_profiles',
            field_name1: 'status',
            comparator1: '==',
            field_value1: "enabled",
            order_by: 'sortIndex',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchVendorsDisbaled: {
            collection_name: 'vendor_profiles',
            field_name1: 'status',
            comparator1: '==',
            field_value1: "disabled",
            order_by: 'sortIndex',
            sort_method: 'asc',
            query_limit: '10',
        },
        searchVendorsNameEnabled: {
            collection_name: 'vendor_profiles',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'enabled',
            order_by: 'organizationNumber',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'name',
            comparator2: '==',
        },
        searchPackageTitleEnabled: {
            collection_name: 'vendor_packages',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'enabled',
            order_by: 'sortIndex',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'title',
            comparator2: '==',
        },
        searchPackageTitleDisabled: {
            collection_name: 'vendor_packages',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'disabled',
            order_by: 'sortIndex',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'title',
            comparator2: '==',
        },
        searchVendorsNameDisabled: {
            collection_name: 'vendor_profiles',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'disabled',
            order_by: 'sortIndex',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'name',
            comparator2: '==',
        },
        fetchVendorPackages: {
            collection_name: 'vendor_packages',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'enabled',
            order_by: 'sortIndex',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'vendorId',
            comparator2: '==',
        },
        fetchVendorPackagesDisabled: {
            collection_name: 'vendor_packages',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'disabled',
            order_by: 'sortIndex',
            sort_method: 'asc',
            query_limit: '10',
            field_name2: 'vendorId',
            comparator2: '==',
        },
        fetchVendorUser: {
            collection_name: "vendor_users",
            field_name1: "isActive",
            comparator1: "==",
            field_value1: true,
            order_by: "name",
            sort_method: "asc",
            query_limit: 10,
            field_name2: "vendorId",
            comparator2: "=="
        }

    },
    ORDER_DETAILS_QUERIES: {
        fetchOrderDetails: {
        }
    },
    PAGINATION_QUERIES: {
        pageOrdersActive: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
        },
        pageOrdersRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
        },
        pageOrdersCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
        },
        PAGE_PACKAGES_ENABLED: {
            collection_name: 'vendor_packages',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'enabled',
            field_name2: 'vendorId',
            comparator2: '==',
            order_by: 'sortIndex',
            sort_method: 'asc',
            query_limit: '10',
        },
        PAGE_PACKAGES_DISABLED: {
            collection_name: 'vendor_packages',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'disabled',
            field_name2: 'vendorId',
            comparator2: '==',
            order_by: 'sortIndex',
            sort_method: 'asc',
            query_limit: '10',
        }
    },
    VENDOR_ORDER_QUERIES: {
        searchOrdersInProcessCompany: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            field_name2: 'vendorId',
            comparator2: '==',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersInProcess: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            field_name2: 'vendorId',
            comparator2: '==',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersInProcessByIdAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'orderId',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersInProcessByIdDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersInProcessByVendorAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'vendorName',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersInProcessByVendorDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'vendorName',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersInProcessByDelDateAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'serviceDate',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersInProcessByDelDateDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            field_name2: 'vendorId',
            comparator2: '==',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersRejectedByIDAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'orderId',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersRejectedByIDDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersRejectedByVendorAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'vendorName',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersRejectedByVendorDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'vendorName',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersRejectedByDelDateAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'serviceDate',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersRejectedByDelDateDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            field_name2: 'vendorId',
            comparator2: '==',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersCancelledByIDAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'orderId',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersCancelledByIDDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersCancelledByVendorAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'vendorName',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersCancelledByVendorDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'vendorName',
            sort_method: 'desc',
            query_limit: '10',
        },
        fetchOrdersCancelledByDelDateAsc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'serviceDate',
            sort_method: 'asc',
            query_limit: '10',
        },
        fetchOrdersCancelledByDelDateDesc: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
        },
        searchOrderAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'accepted',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'vendorId',
            comparator2: '==',
        },
        searchOrderUnconfirmed: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'unconfirmed',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'vendorId',
            comparator2: '==',
        },
        searchOrderIDAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderId',
            comparator2: '==',
            field_name3: 'vendorId',
            comparator3: '==',
        },
        searchOrderIDRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderId',
            comparator2: '==',
            field_name3: 'vendorId',
            comparator3: '==',
        },
        searchOrderIDCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'orderId',
            comparator2: '==',
            field_name3: 'vendorId',
            comparator3: '==',

        },
        searchOrderCompanyAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'customerCompany',
            comparator2: '==',
            field_name3: 'vendorId',
            comparator3: '==',
        },
        searchOrderCompanyRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'customerCompany',
            comparator2: '==',
            field_name3: 'vendorId',
            comparator3: '==',
        },
        searchOrderCompanyCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'customerCompany',
            comparator2: '==',
            field_name3: 'vendorId',
            comparator3: '==',
        },
        searchOrderVendorAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'vendorName',
            comparator2: '==',
        },
        searchOrderVendorRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'vendorName',
            comparator2: '==',
        },
        searchOrderVendorCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'vendorName',
            comparator2: '==',
        },
        searchOrderSizeAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            field_name3: 'vendorId',
            comparator3: '==',
        },
        searchOrderSizeRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            field_name3: 'vendorId',
            comparator3: '==',
        },
        searchOrderSizeCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'orderId',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'totalPackageQuantity',
            comparator2: '==',
            field_name3: 'vendorId',
            comparator3: '==',
        },
        searchOrderStartDateAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            field_name3: 'vendorId',
            comparator3: '==',
        },
        searchOrderStartDateRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            field_name3: 'vendorId',
            comparator3: '==',
        },
        searchOrderStartDateCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            field_name3: 'vendorId',
            comparator3: '==',
        },
        searchOrderEndDateAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            field_name3: 'vendorId',
            comparator3: '==',
        },
        searchOrderEndDateRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            field_name3: 'vendorId',
            comparator3: '==',
        },
        searchOrderEndDateCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '<=',
            field_name3: 'vendorId',
            comparator3: '==',
        },
        searchOrderDateAccepted: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: 'in',
            field_value1: ['unconfirmed', 'accepted'],
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            field_name3: 'serviceDate',
            comparator3: '<=',
            field_name4: 'vendorId',
            comparator4: '==',
        },
        searchOrderDateRejected: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'rejected',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            field_name3: 'serviceDate',
            comparator3: '<=',
            field_name4: 'vendorId',
            comparator4: '==',
        },
        searchOrderDateCancelled: {
            collection_name: 'customer_orders',
            field_name1: 'status',
            comparator1: '==',
            field_value1: 'cancelled',
            order_by: 'serviceDate',
            sort_method: 'desc',
            query_limit: '10',
            field_name2: 'serviceDate',
            comparator2: '>=',
            field_name3: 'serviceDate',
            comparator3: '<=',
            field_name4: 'vendorId',
            comparator4: '==',
        },

    },
}