import CreateVendorDashboard from "../../CreateVendors";
import AdminVendorTable from "./AdminVendorTable";
import AdminVendorsListSort from "../../AdminVendorsListSort";

export default function AdminVendorSkeleton(props) {
    return (
        <div className="dashboardSkeleton">

            <div className="skeletonContainer">
                {
                    props.create ?
                        <CreateVendorDashboard
                            create={props.create}
                            setCreate={props.setCreate}
                            setShowAddedData={props.setShowAddedData}
                            showAddedData={props.showAddedData}
                        />
                        :
                        props.sortList ?
                            <AdminVendorsListSort 
                                sortList={props.sortList}
                                setSortList={props.setSortList}
                            />
                            :
                            <AdminVendorTable
                                create={props.create}
                                setCreate={props.setCreate}
                                props={props}
                            />
                }
            </div>
        </div>
    )
}