import { useHistory } from "react-router-dom"
import { Images } from "../../../Assets/Images/Images"
import { CONSTANTS } from "../../../Helpers/Constants"

export default function LandingScreen() {
    const history = useHistory()
    return (
        <>
            <div className='login-screen'>
                <div className='login-top-strip'></div>
                <div className='login-card'>
                    <div className='login-logo'>
                        <img src={Images.login_logo} alt='logo' />
                    </div>
                    <div className='login-heading'>{CONSTANTS.STRINGS_LIST.INSTACATE_TEMPORARY_LANDING_PAGE}</div>
                    <div className='login-btn'>
                        <button id="button_admin" type='submit' onClick={() => history.push("/admin")}>{CONSTANTS.STRINGS_LIST.LOGIN_AS_ADMIN}</button>
                    </div>

                    <div className='login-btn'>
                        <button id="button_vendor" type='submit' onClick={() => history.push("/vendor")}>{CONSTANTS.STRINGS_LIST.LOGIN_AS_VENDOR}</button>
                    </div>
                </div>
            </div>
        </>
    )
}