import { Route, Redirect } from "react-router"
import { UserContext } from "../../Context/UserContext"
import { useContext } from "react"
import { getLocalItems } from "../../LocalStorage/LocalStorage"

export const AdminProtected = ({ component: Component, ...rest }) => {
    const { currentUser } = useContext(UserContext)
    const user = getLocalItems("user")

    return (
        <Route {...rest} render={
            props => {
                if (user === null) {
                    return (
                        <Redirect to="/admin" />
                    )
                }
                else if (user.userType === 'vendor') {
                    return (
                        <Redirect to="/error" />
                    )
                }
                else if (user !== null) {
                    return (
                        currentUser && user.userType === 'admin'
                            ?
                            <Component {...rest}{...props} />
                            :
                            user.userType === 'admin'
                                ?
                                <Redirect to='/admin' />
                                :
                                <Redirect to="/error" />
                    )
                }
            }
        }
        />
    )
}

export const AdminLoginProtected = ({ component: Component, ...rest }) => {
    const { currentUser } = useContext(UserContext)

    return (
        <Route {...rest} render={
            props => !currentUser ? <Component {...rest}{...props} /> : <Redirect to='/admin/orders' />} />
    )
}