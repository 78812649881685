import LoginScreen from '../../../Components/LoginScreen'
import { CONSTANTS } from '../../../Helpers/Constants'
import { useState, useContext } from 'react'
import { UserContext } from '../../../Context/UserContext'
import { vendor_login_handler } from '../../../Helpers/AuthHandlers'
import Loader from '../../../Components/Loader'


export default function VendorLogin() {
    const { setCurrentUser, loading, setLoading } = useContext(UserContext)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState({
        email: false,
        password: false,
    })

    const changeHandler = (e) => {
        const value = e.target.value
        if (e.target.type === 'email') {
            setEmail(value)
            setError({ ...error, email: false })
        } else if (e.target.type === 'password') {
            setPassword(value)
            setError({ ...error, password: false })
        }
    }

    const submitHandler = (e) => {
        e.preventDefault();
        vendor_login_handler(email, password, setCurrentUser, error, setError, setLoading)
    }

    return (
        <>
            {loading && <div class="loaderParent">
                <Loader />
            </div>}
            <LoginScreen
                loginHeading={CONSTANTS.STRINGS_LIST.VENDOR_LOGIN}
                forgetPasswordLink='/vendor/forget_password'
                login_handler={submitHandler}
                changeHandler={changeHandler}
                email={email}
                password={password}
                error={error}
            />
        </>
    )
}