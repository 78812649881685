
import LoginInput from '../Controls/LoginInput'
import { Images } from '../../Assets/Images/Images'
import { CONSTANTS } from '../../Helpers/Constants'
import { Link } from 'react-router-dom'

export default function LoginScreen({ loginHeading, forgetPasswordLink, login_handler, changeHandler, email, password, error }) {
    return (
        <>
            <div className='login-screen'>
                <div className='login-top-strip'></div>
                <div className='login-card'>
                    <div className='login-logo'>
                        <img src={Images.login_logo} alt='logo' />
                    </div>
                    <div className='login-heading'>{loginHeading}</div>
                    <form onSubmit={login_handler}>

                        <LoginInput
                            error={error.email}
                            errorMessage={CONSTANTS.STRINGS_LIST.EMAIL_ERROR_MESSAGE}
                            classes={`${error.email && "error-group"} input-group`}
                            label={CONSTANTS.STRINGS_LIST.EMAIL}
                            changeHandler={changeHandler}
                            value={email}
                            placeholder={CONSTANTS.STRINGS_LIST.TYPE_EMAIL}
                            type='email'
                        />

                        <LoginInput
                            error={error.password}
                            errorMessage={CONSTANTS.STRINGS_LIST.PASSWORD_ERROR_MESSAGE}
                            classes={`${error.password && "error-group"} input-group`}
                            label={CONSTANTS.STRINGS_LIST.PASSWORD}
                            changeHandler={changeHandler}
                            value={password}
                            placeholder={CONSTANTS.STRINGS_LIST.TYPE_PASSWORD}
                            type='password'
                        />
                        <div className='forget-password'>
                            <Link to={forgetPasswordLink} id="forgot_password" className="forget_password_link">
                                <p id="forgot_password_para">{CONSTANTS.STRINGS_LIST.FORGOT_YOUR_PASSWORD}</p>
                            </Link>
                        </div>

                        <div className='login-btn'>
                            <button type='submit'>{CONSTANTS.STRINGS_LIST.LOGIN_BUTTON}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}