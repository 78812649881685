import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Images } from '../../Assets/Images/Images';
import { CONSTANTS } from '../../Helpers/Constants';

export default function Dropzone({ title, value, onChange, imageError, errorMessage, state, setState, fieldName, setChanges, fieldName2 }) {
    const [fileChange, setFileChange] = useState(false)
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
    });


    const removeFiles = () => {
        if (state && !fieldName2) {
            state[fieldName] = ""
            const temp = { ...state }
            setState(temp)
        } else if (fieldName2) {
            state[fieldName] = ""
            state[fieldName2] = ""
            const temp = { ...state }
            setState(temp)
        }
        acceptedFiles.splice(0, 1)
        setFileChange(!fileChange)
        onChange("")
        setChanges(true)
    }
    useEffect(() => {
        if (acceptedFiles.length !== 0) {
            onChange(acceptedFiles)
        }
    }, [acceptedFiles])
    return (
        <div className="dropzone-container">
            <div className="container">
                <p>{title}</p>
                <div {...getRootProps({ className: 'dropzone' })}>
                    {acceptedFiles.length === 0 && value === ''
                        ?
                        <>
                            <img src={Images.add_image_icon} alt="add-image-icon" />
                            <input {...getInputProps()} />
                            <p>{CONSTANTS.STRINGS_LIST.DRAG_DROP} <span onClick={open}>{CONSTANTS.STRINGS_LIST.BROWSE}</span></p>
                        </>
                        :
                        acceptedFiles.length === 0 && value !== ''
                            ?
                            <aside>
                                <div className='close_icon' onClick={removeFiles}><img src={Images.close_icon} alt="close_icon" title={CONSTANTS.STRINGS_LIST.REMOVE_IMAGE} /></div>
                                <ul>
                                    <li>{typeof value !== "object" && value}</li>
                                </ul>
                            </aside>
                            :
                            acceptedFiles.length !== 0
                                ?
                                <aside>
                                    <div className='close_icon' onClick={removeFiles}><img src={Images.close_icon} alt="close_icon" title={CONSTANTS.STRINGS_LIST.REMOVE_IMAGE} /></div>
                                    <ul>
                                        {acceptedFiles.map((file, index) => {
                                            if (index > 0) acceptedFiles.splice(index, 1)
                                            return (
                                                <li key={file.path}>
                                                    {file.path}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </aside>
                                :
                                []
                    }
                </div>
                {imageError ? <div className='error-message'>{errorMessage}</div> : ''}
            </div>
        </div>
    );
}