import { useEffect, useState } from "react"
import { admin_side_menu, vendor_side_menu } from "./Views/left_side_list_items"
import LEFT_SIDE_LIST from "./Views/left_side_list"
import {useLocation} from 'react-router-dom'



export default function LeftSide({ hideLeft, setHideLeft }) {
    const location = useLocation();
    const [sideMenuType, setSideMenuType] = useState([])
    const [isActive, setIsActive] = useState(location.pathname === '/admin' ? '/admin/orders' : location.pathname)

    useEffect(() => {
        let activeStatus = location.pathname
        if ((activeStatus.search(/\bvendor\b/) >= 0)) {
            setSideMenuType(vendor_side_menu)
        } else if (activeStatus.search(/\badmin\b/) >= 0) {
            setSideMenuType(admin_side_menu)
        }
    }, [])

    return (
        <>
            {hideLeft && <div className='left-side-res' id='left-side-res'>
                <LEFT_SIDE_LIST
                    menuType={sideMenuType}
                    isActive={isActive}
                    setIsActive={setIsActive}
                />
            </div>
            }
            <div className='left-side'>
                <LEFT_SIDE_LIST
                    menuType={sideMenuType}
                    isActive={isActive}
                    setIsActive={setIsActive}
                />
            </div>
        </>
    )
}