import swal from "sweetalert"
import { CONSTANTS } from "../../../../../Helpers/Constants"
import { deleteDocument } from "../../../../../Helpers/Helper"
import { getLocalItems, setItemsLocally } from "../../../../../LocalStorage/LocalStorage"


export const packageChangeHandler = async (event, setPackageData, packageData, systemConfig, setChanges, tags) => {
    setChanges(true)
    if (tags === CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.TAGS) {
        const name = tags
        const value = []
        event.map(val => value.push({ value: val.value, label: val.label }))
        setPackageData({ ...packageData, [name]: value })
    } else {
        const name = event.target.name;
        const value = event.target.value
        if (name === CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.PRICE_PER_UNIT) {
            if (value === "") {
                packageData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.INSTACATE_FEE] = 0
                packageData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MOMS] = 0
                packageData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.AMOUNT_CHARGED] = 0

            } else {
                packageData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.INSTACATE_FEE] = Number(value) * (Number(systemConfig.instacate_fee) / 100)
                packageData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MOMS] = (Number(value) + Number(packageData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.INSTACATE_FEE])) * (Number(systemConfig.moms) / 100)
                packageData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.AMOUNT_CHARGED] = Number(value) + Number(packageData.instacate_fee) + Number(packageData.moms)
            }
        }
        setPackageData({ ...packageData, [event.target.name]: value })
    }
}

export const itemChangeHandler = (event, itemData, setItemData) => {
    const value = event.target.value
    const name = event.target.name
    if (name === CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.CATEGORY || name === CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.COURSE) {
        setItemData({ ...itemData, [name]: value.toLocaleLowerCase() })
    } else {
        setItemData({ ...itemData, [name]: value.toLocaleLowerCase() })
    }
}

export const endDateHandler = (event, packageData, setError) => {
    const value = event.target.value
    if (value < packageData.startServiceTimeSeconds) setError(true)
    else setError(false)
}

export const addItemHandler = (newData, previousState, setState, setShowAddItem, setItemData, packageItemDefault, selectedMenuItemEdited) => {
    let tempError = false
    let currentData = { ...newData }
    Object.keys(currentData).map(key => {
        if (key !== CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.IMAGE_URL && key !== 'image' && key !== 'imageName') {
            if (key !== CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.DESCRIPTION) {
                return currentData[key] === "" ? tempError = true : tempError
            }
        }
    })
    if (tempError) {
        return
    } else if (selectedMenuItemEdited) {
        let index = previousState.findIndex(item => item.createdOn === newData.createdOn)
        let tempData = [...previousState]
        tempData[index] = newData
        setState(tempData)
        swal(CONSTANTS.STRINGS_LIST.EMPTY_STRING, CONSTANTS.STRINGS_LIST.ITEM_EDITED, "success");
        setShowAddItem(false)
        setItemData(packageItemDefault)
    } else {
        const tempItem = [...previousState]
        swal(CONSTANTS.STRINGS_LIST.EMPTY_STRING, CONSTANTS.STRINGS_LIST.ITEM_ADDED, "success");
        setShowAddItem(false)
        tempItem.push(currentData)
        setState(tempItem)
        setItemData(packageItemDefault)
    }
}

export const removeItem = (data, setData, index, docId, subDocId, setMenuItemEdited, setChanges, deletedMenuItems, setDeletedMenuItems) => {
    swal({
        title: "",
        text: CONSTANTS.STRINGS_LIST.ARE_YOU_SURE_DELETE_MESSAGE,
        buttons: true,
        dangerMode: true,
    })
        .then((willDelete) => {
            if (willDelete) {
                if (subDocId) {
                    setChanges(true)
                    setMenuItemEdited(true)
                }
                let temp = [...data];
                let storedDeleted = temp.splice(index, 1)
                let tempData = [...deletedMenuItems]
                storedDeleted.map(item =>
                    tempData.push(item)
                )
                setDeletedMenuItems(tempData)
                setData(temp)
                swal(CONSTANTS.STRINGS_LIST.YOUR_ITEM_DELETED, {
                });
            }
        });
}

export const editMenuItem = (item, setShowAddItem, index, itemData, setItemData, setSelectedMenuItemEdited, setMenuItemEdited) => {
    (!item.imageName && item.imageUrl) ?
        setItemData({ ...item, imageName: item.imageUrl.split(/[\\\//]/).pop().split('?')[0].split('%2F')[3].split("%20")[0] })
        :
        setItemData({ ...item, imageName: item.imageName })
    setShowAddItem(true)
    setMenuItemEdited(true)
    setSelectedMenuItemEdited(true)
}