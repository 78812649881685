import { db } from "../Config";
import { setDoc, doc, addDoc, collection } from "firebase/firestore";
import { createAccount } from "../AuthFunctions";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { CONSTANTS } from "../../Helpers/Constants";
import { removeItemsLocally, setItemsLocally } from "../../LocalStorage/LocalStorage";

//Vendor will be created by Admin
export const createVendor = async (email, password, data, cancelFunc, setLoading, setShowAddedData, showAddedData, setChanges) => {
    try {
        const userCredential = await createAccount(email, password, data)
        const user = userCredential.user;
        const docRef = await addDoc(collection(db, "vendor_profiles"), data.vendor_profile);
        const vendorRef = doc(db, `vendor_profiles/${docRef.id}`);
        await setDoc(doc(db, "vendor_users", user.uid), { ...data.vendor_user, vendorId: vendorRef });
        setLoading(false)
        setShowAddedData(!showAddedData)
        swal(CONSTANTS.STRINGS_LIST.DONE, CONSTANTS.STRINGS_LIST.VENDOR_CREATED, "success")
            .then(function () {
                removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY)
                setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.VENDORS)
            })
            .then(cancelFunc)
        setChanges(false)
    } catch (e) {
        let message = e.message.split("Firebase: ");
        setLoading(false)
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: message[1],
        })
    }
}

//currently not in use
// export const adminSignUp = (email, password, data) => { createAccount(email, password, data) }