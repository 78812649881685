import { removeItemsLocally } from "../../../../LocalStorage/LocalStorage"
import { CONSTANTS } from "../../../../Helpers/Constants"
import { editFAQ, addFAQ } from "../../../../Helpers/WriteHandlers"
import { getFAQ } from "../../../../Helpers/FetchHandlers"
import Swal from "sweetalert2"

export const close_modal_handler = (setModal) => {
    setModal(false)
    removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.FAQ_KEY)
}

export const faq_change_handler = (event, faq, setFaq) => {
    const value = event.target.value
    setFaq({ ...faq, [event.target.name]: value })
}


export const addFaqHandler = async ({ createAndEditFaq, faqData, setFaqData, setModal, setLoading }) => {
    let question = createAndEditFaq.question.match(/\w+/g)
    let answer = createAndEditFaq.answer.match(/\w+/g)
    if (createAndEditFaq.answer == "" || createAndEditFaq.question == "") {
        return
    } else if (!question || !answer) {
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: CONSTANTS.STRINGS_LIST.FIELD_NOT_EMPTY,
        })
    } else {
        addFAQ(createAndEditFaq, faqData, setFaqData, setModal, setLoading)
    }
}

export const editFaqHandler = async ({ createAndEditFaq, setModal, setLoading, currentFaq, faqData }) => {
    let question = createAndEditFaq.question.match(/\w+/g)
    let answer = createAndEditFaq.answer.match(/\w+/g)
    if (createAndEditFaq.answer == "" || createAndEditFaq.question == "") {
        return
    } else if (!question || !answer) {
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: CONSTANTS.STRINGS_LIST.FIELD_NOT_EMPTY,
        })
    }
    else {
        const index = currentFaq.index
        editFAQ(createAndEditFaq, currentFaq.id, index, faqData, setLoading)
        setModal(false);
    }
    setLoading(false)
}

export const getFaqData = async (setFaqData, setLoading) => {
    setLoading(true);
    const data = await getFAQ()
    setFaqData(data)
    setLoading(false);
}

export const accordianHandleChange = (panel, setBg, setExpanded) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setBg(newExpanded ? panel : false)
};