import { collection, doc, addDoc, updateDoc } from "firebase/firestore";
import { db, storage } from '../Firebase/Config'
import { getLocalItems, removeItemsLocally, setItemsLocally } from "../LocalStorage/LocalStorage";
import swal from "sweetalert";
import { CONSTANTS } from "./Constants";
import Swal from 'sweetalert2'
import { capitalizeFirstLetter } from "./Helper";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Images } from "../Assets/Images/Images";
import { FlashAuto } from "@material-ui/icons";
import { getVendorProfile } from "./FetchHandlers";

export const addTags = async (_tags, _id) => {
    try {
        await updateDoc(doc(db, 'system_config', _id), {
            tags: _tags
        })
        Swal.fire({
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: CONSTANTS.STRINGS_LIST.TAG_ADDED_MESSAGE,
            icon: 'success'
        })
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: CONSTANTS.STRINGS_LIST.SOMETHING_WENT_WRONG,
        })
    }
}


export const updateVendor = async (docID, data, cancelFunc, setLoading, setShowAddedData, showAddedData, setChanges) => {
    try {
        delete data.dId
        delete data.emailAddress
        let tempData = { ...data, vendorArea: data.vendorArea.value.toLowerCase(), vendorCity: data.vendorCity.toLowerCase() }
        await updateDoc(doc(db, "vendor_profiles", docID), tempData);
        setLoading(false);
        setShowAddedData(!showAddedData)
        swal(CONSTANTS.STRINGS_LIST.DONE, CONSTANTS.STRINGS_LIST.VENDOR_UPDATED, "success")
            .then(function () {
                removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY)
                setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.VENDORS)
            })
            .then(cancelFunc)
        setChanges(false)
    } catch (e) {
        setLoading(false);
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: e,
        })
    }
}

export const editFAQ = async (data, id, index, faqData, setLoading) => {
    try {
        setLoading(true)
        let tempData = { ...data }
        faqData.splice(index, 1, data)
        delete tempData.id
        await updateDoc(doc(db, 'customer_faqs', id), tempData);
        removeItemsLocally('FAQ')
        swal(CONSTANTS.STRINGS_LIST.EMPTY_STRING, CONSTANTS.STRINGS_LIST.FAQ_UPDATED, "success");
        setLoading(false)
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: CONSTANTS.STRINGS_LIST.SOMETHING_WENT_WRONG,
        })
    }
}

export const addFAQ = async (data, faqData, setFaqData, setModal, setLoading) => {
    try {
        await addDoc(collection(db, "customer_faqs"), data);
        const tempData = [...faqData, data]
        setFaqData(tempData)
        setModal(false)
        removeItemsLocally('FAQ')
        swal(CONSTANTS.STRINGS_LIST.EMPTY_STRING, CONSTANTS.STRINGS_LIST.FAQ_CREATED, "success");
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: CONSTANTS.STRINGS_LIST.SOMETHING_WENT_WRONG,
        })
    }
}
export const create_package = async (data, setImageError, setCreate, setLoading, packageItemList, packageCreated, setPackageCreated, error, setChanges) => {
    try {
        const vendorProfile = await getVendorProfile(getLocalItems('user').userId)
        const maximumCapacity = vendorProfile.vendorProfile.maximumOrderCapacity
        let tempError = false
        let capacityError = false
        let capacityError2 = false
        Object.keys(data).map(key => {
            if (key !== CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_URL) {
                if (key === CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MINIMUM_ORDER_CAPACITY) {
                    if (data[key] === "") tempError = true
                    else if (data[key] > maximumCapacity) capacityError = true
                    else if (data[key] < 1) capacityError2 = true
                } else if (key !== CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.TAGS && key !== CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_NAME) {
                    if (data[key] === "") tempError = true
                } else if (key !== CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_NAME) {
                    if (data[key].length == 0) {
                        tempError = true
                        Swal.fire({
                            text: CONSTANTS.STRINGS_LIST.ADD_ONE_TAG_ATLEAST,
                        })
                    }
                }
            }
        })
        if (tempError) {
            return
        } else if (error) {
            Swal.fire({
                icon: 'error',
                title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                text: CONSTANTS.STRINGS_LIST.FIX_ERROR,
            })
            return
        }
        else if (packageItemList.length === 0) {
            Swal.fire({
                icon: 'error',
                title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                text: CONSTANTS.STRINGS_LIST.ADD_ONE_ITEM_ATLEAST,
            })
        } else if (capacityError) {
            Swal.fire({
                icon: 'error',
                title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                text: CONSTANTS.STRINGS_LIST.MINIMUM_CAPACITY_LESS_THAN + maximumCapacity,
            })
        } else if (capacityError2) {
            Swal.fire({
                icon: 'error',
                title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                text: CONSTANTS.STRINGS_LIST.MINIMUM_CAPACITY_ZERO,
            })
        }
        else {
            swal({
                text: CONSTANTS.STRINGS_LIST.ARE_YOU_SURE_TO_CREATE_PACKAGE,
                buttons: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        setLoading(true)
                        const create = async () => {
                            let tempData = { ...data }
                            delete tempData.amountCharged
                            delete tempData.instacate_fee
                            delete tempData.moms
                            let imageUrl
                            if (tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_URL] !== "" || tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_URL].length !== 0) {
                                imageUrl = await storeImages(tempData.vendorId._key.path.segments[6], tempData.imageUrl[0].name + " - " + new Date(), tempData.imageUrl[0])
                            } else {
                                imageUrl = CONSTANTS.DEFAULT_IMAGE_URL
                            }
                            const tagValue = []
                            tempData.tags.map(val => {
                                tagValue.push(val.label)
                            })
                            tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_URL] = imageUrl
                            tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.TAGS] = tagValue // tag checking
                            tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.START_SERVICE_TIME] = convertHMS(tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.START_SERVICE_TIME])
                            tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.END_SERVICE_TIME] = convertHMS(tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.END_SERVICE_TIME])
                            tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MINIMUM_ORDER_CAPACITY] = Number(tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.MINIMUM_ORDER_CAPACITY])
                            tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.PRICE_PER_UNIT] = Number(tempData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.PRICE_PER_UNIT])
                            const doc = await addDoc(collection(db, "vendor_packages"), tempData)
                            packageItemList.map(async (item, index) => {
                                if (item.image && item.imageName) {
                                    //condition will validate the image is not empty
                                    const tempItemData = { ...item }
                                    delete tempItemData.image
                                    delete tempItemData.imageName
                                    const imageUrl = await storeImages(tempData.vendorId._key.path.segments[6], item.imageName + " - " + new Date(), item.image)
                                    tempItemData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.IMAGE_URL] = imageUrl
                                    tempItemData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.SORT_INDEX] = index + 1
                                    await addDoc(collection(db, "vendor_packages", doc.id, "vendor_package_items"), tempItemData)
                                } if (item.imageName === '') {
                                    //if we add new item with image and remove the image this condition will work
                                    const tempItemData = { ...item }
                                    tempItemData[CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.IMAGE_URL] = CONSTANTS.DEFAULT_IMAGE_URL
                                    tempItemData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.SORT_INDEX] = index + 1
                                    delete tempItemData.imageName
                                    delete tempItemData.image

                                    await addDoc(collection(db, "vendor_packages", doc.id, "vendor_package_items"), tempItemData)
                                } else if (!item.image) {
                                    //this condition will work when no image added
                                    const tempItemData = { ...item }
                                    tempItemData[CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.IMAGE_URL] = CONSTANTS.DEFAULT_IMAGE_URL
                                    delete tempItemData.imageName
                                    delete tempItemData.image
                                    tempItemData[CONSTANTS.FIELDS.CREATE_PACKAGE_KEYS.SORT_INDEX] = index + 1
                                    await addDoc(collection(db, "vendor_packages", doc.id, "vendor_package_items"), tempItemData)
                                }
                            })
                            setLoading(false)
                            swal(CONSTANTS.STRINGS_LIST.EMPTY_STRING, CONSTANTS.STRINGS_LIST.PACKAGE_CREATED, "success")
                            setCreate(false)
                            setChanges(false)
                            setPackageCreated(!packageCreated)
                            setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.PACKAGES)
                        }
                        create()
                    }
                }
                )
        };
    } catch (error) {
        setLoading(false)
        Swal.fire({
            icon: 'error',
            title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
            text: error.message,
        })
    }

}


export function convertHMS(timeString) {
    const arr = timeString.split(":");
    const seconds = parseInt(arr[0]) * 3600 + parseInt(arr[1]) * 60;
    return seconds;
}

const storeImages = async (folderName, fileName, file) => {
    const imagesRef = ref(storage, `images/vendors/${folderName}/${fileName}`)
    let imageUrl = await uploadBytes(imagesRef, file).then((snapshot) => getDownloadURL(imagesRef).then(url => url));
    return imageUrl
}

export const menuItem = async (collectionName, docId, subCollection, data) => {
    addDoc(collection(db, collectionName, docId, subCollection), data)
}