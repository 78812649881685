import { CONSTANTS } from "../../../Helpers/Constants";
import OrderDetailSkeleton from "./Views/OrderDetailSkeleton";
import { useEffect, useState } from "react";
import { getOrderPackage, getSystemConfig, getUserData } from "../../../Helpers/FetchHandlers";
import { getLocalItems, setItemsLocally } from "../../../LocalStorage/LocalStorage";
import { updateOrderStatus } from "../../../Helpers/UpdateHandlers";
import swal from 'sweetalert';
import moment from "moment";
import { capitalizeFirstLetter } from "../../../Helpers/Helper";

export default function OrderDetails({ propsList }) {
    const { setShowPackageDetails, setShowOrderDetails, setActiveTab, orderAccepted, setOrderAccepted, activeTab, setLoading } = propsList
    const [data, setData] = useState([]);
    const [cards, setCards] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [packages, setPackages] = useState([]);
    const [billingData, setBillingData] = useState([]);
    const [systemConfig, setSystemConfig] = useState([])
    let userRole = getLocalItems(CONSTANTS.LOCAL_STORAGE_ITEMS.USER_KEY).userType;

    useEffect(async ()=>{
        const system_config = await getSystemConfig("REp6lXl9OP6NmJUh6KrY", "system_config")
        setSystemConfig(system_config)
    }, [])
    //calculating remainingTime
    let remainingTime;
    const createdOn = moment(new Date(data.createdOn)).format('MMM DD YYYY hh:mm')
    let currentTime = moment(new Date()).format("LLL" )
    let createdDate = moment(new Date(data.createdOn)).format('MMM DD YYYY')
    let currentDate = moment(new Date()).format("MMM DD YYYY")
    let limitTime = moment(createdOn).add(systemConfig.maxAcceptanceLimitHours, "hour").format('LLL')
    var diff = moment.utc(moment(limitTime).diff(moment(currentTime))).format("hh : mm")
    
    if (diff > "00:00" && diff <= `0${systemConfig.maxAcceptanceLimitHours}:00` && createdDate === currentDate && data.status === CONSTANTS.STRINGS_LIST.UNCONFIRMED) {
        remainingTime = `${CONSTANTS.STRINGS_LIST.REMAINING_TIME} ${diff}`
    } else if (data.status === CONSTANTS.STRINGS_LIST.UNCONFIRMED) {
        remainingTime = CONSTANTS.STRINGS_LIST.UNCONFIRMED
    } else {
        remainingTime = ""
    }

    useEffect(async () => {
        setLoading(true)
        setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.ORDER_DETAILS)
        const orderDetail = getLocalItems(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY)
        const billingInfo = await getOrderPackage(orderDetail.dId, 'customer_order_payments')
        async function getData() {
            const orderPackages = await getOrderPackage(orderDetail.dId, 'customer_order_packages')
            const data = await getUserData(orderDetail.createdBy, 'customer_users')
            const cards_data = [{
                heading: CONSTANTS.STRINGS_LIST.CUSTOMER_INFO,
                data: {
                    [CONSTANTS.STRINGS_LIST.COMPANY_NAME]: orderDetail.customerCompany,
                    [CONSTANTS.STRINGS_LIST.CUSTOMER_NAME]: billingInfo[0].billingCustomerName,
                    [CONSTANTS.STRINGS_LIST.ORGANIZATION_NUMBER]: orderDetail.customerOrganizationNumber,
                    [CONSTANTS.STRINGS_LIST.CONTACT_NUMBER]: billingInfo[0].billingContactNumber,
                    [CONSTANTS.STRINGS_LIST.EMAIL_ADDRESS]: billingInfo[0].billingEmailAddress,
                    [CONSTANTS.STRINGS_LIST.BILLING_ADDRESS]: `${billingInfo[0].billingStreetAddress}${billingInfo[0].billingStreetAddress && ','} ${billingInfo[0].billingPostalCode}${billingInfo[0].billingPostalCode && ','} ${capitalizeFirstLetter(billingInfo[0].billingCity)}`,
                }
            }, {
                heading: CONSTANTS.STRINGS_LIST.ORDER_INFO,
                data: {
                    [CONSTANTS.STRINGS_LIST.STATUS]: orderDetail.status,
                    [CONSTANTS.STRINGS_LIST.TOTAL_QUANTITY]: orderDetail.totalPackageQuantity,
                    [CONSTANTS.STRINGS_LIST.NUMBER_OF_PEOPLE]: orderDetail.totalPeople,
                    [CONSTANTS.STRINGS_LIST.ORDER_TOTAL]: orderDetail.totalPayableAmount + CONSTANTS.STRINGS_LIST.SEK,
                    [CONSTANTS.STRINGS_LIST.ORDER_DATE]: moment(orderDetail.createdOn).format('LL'),
                    [CONSTANTS.STRINGS_LIST.ORDER_TIME]: moment(orderDetail.createdOn).format('LT'),
                    [CONSTANTS.STRINGS_LIST.SERVICE_MODE]: orderDetail.serviceMode,
                }
            }, {
                heading: `${orderDetail.serviceMode !== "Pickup" ? CONSTANTS.STRINGS_LIST.DELIVERY_INFO : CONSTANTS.STRINGS_LIST.PICKUP_INFO}`,
                data: orderDetail.serviceMode !== "Pickup" ? {
                    [CONSTANTS.STRINGS_LIST.DELIVERY_DATE]: moment(orderDetail.serviceDate).format('LL'),
                    [CONSTANTS.STRINGS_LIST.DELIVERY_TIME]: moment(orderDetail.serviceDate).format('LT'),
                    [CONSTANTS.STRINGS_LIST.DELIVERY_ADDRESS]: `${orderDetail?.serviceDoorCode}${orderDetail?.serviceDoorCode && ","} ${orderDetail.serviceAddressFloor}${orderDetail.serviceAddressFloor && ","} ${orderDetail.serviceStreetAddress}${orderDetail.serviceStreetAddress && ","} ${orderDetail.serviceArea}${orderDetail.serviceArea && ","} ${orderDetail.servicePostalCode}${orderDetail.servicePostalCode && ","} ${orderDetail.serviceCity}`
                } :
                {
                    [CONSTANTS.STRINGS_LIST.PICKUP_DATE]: moment(orderDetail.serviceDate).format('LL'),
                    [CONSTANTS.STRINGS_LIST.PICKUP_TIME]: moment(orderDetail.serviceDate).format('LT'),
                    [CONSTANTS.STRINGS_LIST.PICKUP_ADDRESS]: `${orderDetail?.serviceDoorCode}${orderDetail?.serviceDoorCode && ","} ${orderDetail.serviceAddressFloor}${orderDetail.serviceAddressFloor && ","} ${orderDetail.serviceStreetAddress}${orderDetail.serviceStreetAddress && ","} ${orderDetail.serviceArea}${orderDetail.serviceArea && ","} ${orderDetail.servicePostalCode}${orderDetail.servicePostalCode && ","} ${orderDetail.serviceCity}`
                }
            }
        ]
        setCards(cards_data)
            setData(orderDetail);
            setPackages(orderPackages)
            setBillingData(billingInfo)
            setCustomerData(data)
            return
        }
        getData()
        setLoading(false)
        
    }, []);

    const routeToPackage = (data) => {
        setShowPackageDetails(true);
        setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.ITEM_KEY, data)
    }

    const orderCancel = (orderId, vendorId) => {
        swal({
            title: CONSTANTS.STRINGS_LIST.CONFIRM_CANCEL_TITLE,
            text: CONSTANTS.STRINGS_LIST.CONFIRM_CANCEL,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal({
                    title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                    text: CONSTANTS.STRINGS_LIST.ORDER_CANCEL_MESSAGE,
                    icon: "success"
                }).then(function () {
                    updateOrderStatus(orderId, 'status', CONSTANTS.STRINGS_LIST.CANCELLED.toLowerCase(), vendorId);
                    setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.ORDERS);
                    setActiveTab(CONSTANTS.STRINGS_LIST.CANCELLED)
                    setShowOrderDetails(false);
                });
            } else {
                return;
            }
        });
    }

    const acceptOrder = (orderId, vendorId) => {
        swal({
            title: CONSTANTS.STRINGS_LIST.CONFIRM_ACCEPT_TITLE,
            text: CONSTANTS.STRINGS_LIST.CONFIRM_ACCEPT,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal({
                    title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                    text: CONSTANTS.STRINGS_LIST.ORDER_ACCEPT_MESSAGE,
                    icon: "success"
                }).then(function () {
                    updateOrderStatus(orderId, 'status', CONSTANTS.STRINGS_LIST.ACCEPTED.toLowerCase(), vendorId);
                    setActiveTab(CONSTANTS.STRINGS_LIST.IN_PROCESS)
                    setOrderAccepted(!orderAccepted)
                    setShowOrderDetails(false);
                    setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.ORDERS);
                });
            } else {
                return;
            }
        });
    }

    const rejectOrder = (orderId, vendorId) => {
        swal({
            title: CONSTANTS.STRINGS_LIST.CONFIRM_REJECT_TITLE,
            text: CONSTANTS.STRINGS_LIST.CONFIRM_REJECT,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal({
                    title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                    text: CONSTANTS.STRINGS_LIST.ORDER_REJECT_MESSAGE,
                    icon: "success"
                }).then(function () {
                    updateOrderStatus(orderId, 'status', CONSTANTS.STRINGS_LIST.REJECTED.toLowerCase(), vendorId);
                    setActiveTab(CONSTANTS.STRINGS_LIST.REJECTED)
                    setShowOrderDetails(false);
                    setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.ORDERS);
                });
            } else {
                return;
            }
        });
    }
    const props = {
        ...propsList,
        data,
        cards,
        packages,
        userRole,
        routeToPackage,
        orderCancel,
        acceptOrder,
        rejectOrder,
        remainingTime
    }
    return (
        <OrderDetailSkeleton
            propsList={props}
        />
    )
}