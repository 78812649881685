import { CONSTANTS } from "../../../../Helpers/Constants";
import { organizationNumberQuery } from "../../../../Helpers/FetchHandlers"

export const changeHandler = (event, setChanges, setVendorData, vendorData, areas) => {
    setChanges(true);
    if (areas) {
        const name = CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.VENDOR_AREA
        const value = event
        setVendorData({ ...vendorData, [name]: value })
    } else {
        const value = event.target.value
        setVendorData({ ...vendorData, [event.target.name]: value })
    }
}

export const organizationNumberHandler = async (e, setOrganizationError) => {
    const tempData = await organizationNumberQuery(e.target.value)
    tempData.length > 0 ? setOrganizationError(true) : setOrganizationError(false)
}