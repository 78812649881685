import React from 'react'
import PackageDetails from '../../PackageDetails'
import OrderDetailPage from './DetailsPage'

export default function OrderDetailSkeleton({propsList}) {

    return (
        <>
            {propsList.showPackageDetails ?
                <PackageDetails
                    propsList={propsList}
                />
                :
                <OrderDetailPage
                    propsList={propsList}
                />
            }
        </>
    )
}
