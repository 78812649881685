import moment from "moment"
import { capitalizeFirstLetter } from "../../../../Helpers/Helper"
import { checkNextPage, checkPreviousPage, getSystemConfig, searchFetch } from "../../../../Helpers/FetchHandlers"
import { QUERY_PARAMS, CONSTANTS } from "../../../../Helpers/Constants"
import { db } from "../../../../Firebase/Config"
import { collection, query, orderBy, limit, getDocs, startAfter, where, endBefore, limitToLast } from "firebase/firestore"

export const sanitizeResult = (result, limit) => {
    return result.map(item => {
        //calculating remainingTime
        let remainingTime;
        const createdOn = moment(new Date(item.createdOn.toDate().toUTCString())).format('LLL')
        let currentTime = moment(new Date()).format("LLL")
        let createdDate = moment(new Date(item.createdOn.toDate().toUTCString())).format('MMM DD YYYY')
        let currentDate = moment(new Date()).format('MMM DD YYYY')
        let limitTime = moment(createdOn).add(limit, "hour").format('LLL')

        var diff = moment.utc(moment(limitTime).diff(moment(currentTime))).format("hh")
        if (diff > "00:00" && diff <= `0${limit}:00` && createdDate === currentDate && capitalizeFirstLetter(item.status) === CONSTANTS.STRINGS_LIST.UNCONFIRMED) {
            remainingTime = `${CONSTANTS.STRINGS_LIST.REMAINING_TIME_LIST} ${diff}h`
        } else {
            remainingTime = capitalizeFirstLetter(item.status)
        }

        return {
            ...item,
            serviceDate: moment(new Date(item.serviceDate.toDate().toUTCString())).format('LLL'),
            createdOn: moment(new Date(item.createdOn.toDate().toUTCString())).format('LLL'),
            modifiedOn: moment(new Date(item.modifiedOn.toDate().toUTCString())).format('LLL'),
            status: remainingTime,
            serviceMode: capitalizeFirstLetter(item.serviceMode),
            createdBy: item.createdById.id
        }
    })
}

export const search_change_handler = (event, setSearch) => {
    //this is search input handler
    let value = event.target.value;
    setSearch(value);
}

export const handleSearch = async(event, searchData, setLoading) => {
    //this is the handler of search result onKeyPress
    setLoading(true)
    let value = event.target.value;
    if (event.key === 'Enter') {
        //search result function in index.js
        searchData(value);
    }
    setLoading(false)
}

const searchResult = async(isSearchWithId, comparator, statusValue, searchOptions, search, orderByField, sortingFlow) => {
    let searchResultQuery;
    if (isSearchWithId) {
        searchResultQuery = query(collection(db, CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS, comparator, statusValue),
            where(searchOptions, "==", search),
            limit(10))
    } else {
        searchResultQuery = query(collection(db, CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS),
            orderBy(orderByField, sortingFlow),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS, comparator, statusValue),
            where(searchOptions, "==", search),
            limit(10))
    }
    let resultSnapshot = await getDocs(searchResultQuery)
    return resultSnapshot
}


const fetchingDataHelper = async(
    setFirstRow,
    setLastRow,
    setData,
    search,
    searchOptions,
    comparator,
    value,
    orderByField,
    sortingFlow,
    dateFilterActive,
    startDate,
    endDate,
    orderSizeFilterActive,
    orderSizeNumber,
    orderStatusFilterActive,
    orderStatus
) => {
    let remainingTimeLimit = await getSystemConfig("REp6lXl9OP6NmJUh6KrY", "system_config")
    let tempData = [],
        currentFirstRow, currentLastRow;
    if (dateFilterActive && orderSizeFilterActive && orderStatusFilterActive) {
        const firstPage = query(collection(db, CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, sortingFlow),
            // orderBy(orderByField, sortingFlow),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, ">=", startDate),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, "<=", endDate),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS, "==", orderStatus),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY, "==", Number(orderSizeNumber)),
            limit(10))
        const docSnapshot = await getDocs(firstPage)
        currentFirstRow = docSnapshot.docs[0]
        currentLastRow = docSnapshot.docs[docSnapshot.docs.length - 1]
        docSnapshot.forEach(doc => {
            tempData.push({...doc.data(), dId: doc.id })
        })
    } else if (dateFilterActive && orderSizeFilterActive) {
        const firstPage = query(collection(db, CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, sortingFlow),
            // orderBy(orderByField, sortingFlow),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS, comparator, value),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, ">=", startDate),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, "<=", endDate),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY, "==", Number(orderSizeNumber)),
            limit(10))
        const docSnapshot = await getDocs(firstPage)
        currentFirstRow = docSnapshot.docs[0]
        currentLastRow = docSnapshot.docs[docSnapshot.docs.length - 1]
        docSnapshot.forEach(doc => {
            tempData.push({...doc.data(), dId: doc.id })
        })
    } else if (dateFilterActive && orderStatusFilterActive) {
        const firstPage = query(collection(db, CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, sortingFlow),
            // orderBy(orderByField, sortingFlow),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS, "==", orderStatus),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, ">=", startDate),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, "<=", endDate),
            limit(10))
        const docSnapshot = await getDocs(firstPage)
        currentFirstRow = docSnapshot.docs[0]
        currentLastRow = docSnapshot.docs[docSnapshot.docs.length - 1]
        docSnapshot.forEach(doc => {
            tempData.push({...doc.data(), dId: doc.id })
        })
    } else if (orderSizeFilterActive && orderStatusFilterActive) {
        const firstPage = query(collection(db, CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS),
            orderBy(orderByField, sortingFlow),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS, "==", orderStatus),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY, "==", Number(orderSizeNumber)),
            limit(10))
        const docSnapshot = await getDocs(firstPage)
        currentFirstRow = docSnapshot.docs[0]
        currentLastRow = docSnapshot.docs[docSnapshot.docs.length - 1]
        docSnapshot.forEach(doc => {
            tempData.push({...doc.data(), dId: doc.id })
        })
    } else if (dateFilterActive) {
        const firstPage = query(collection(db, CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, sortingFlow),
            // orderBy(orderByField, sortingFlow),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS, comparator, value),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, ">=", startDate),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, "<=", endDate),
            limit(10))
        const docSnapshot = await getDocs(firstPage)
        currentFirstRow = docSnapshot.docs[0]
        currentLastRow = docSnapshot.docs[docSnapshot.docs.length - 1]
        docSnapshot.forEach(doc => {
            tempData.push({...doc.data(), dId: doc.id })
        })
    } else if (orderSizeFilterActive) {
        const firstPage = query(collection(db, CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS),
            orderBy(orderByField, sortingFlow),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS, comparator, value),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY, "==", Number(orderSizeNumber)),
            limit(10))
        const docSnapshot = await getDocs(firstPage)
        currentFirstRow = docSnapshot.docs[0]
        currentLastRow = docSnapshot.docs[docSnapshot.docs.length - 1]
        docSnapshot.forEach(doc => {
            tempData.push({...doc.data(), dId: doc.id })
        })
    } else if (orderStatusFilterActive) {
        const firstPage = query(collection(db, CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS),
            orderBy(orderByField, sortingFlow),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS, "==", orderStatus),
            limit(10))
        const docSnapshot = await getDocs(firstPage)
        currentFirstRow = docSnapshot.docs[0]
        currentLastRow = docSnapshot.docs[docSnapshot.docs.length - 1]
        docSnapshot.forEach(doc => {
            tempData.push({...doc.data(), dId: doc.id })
        })
    } else if (search && searchOptions === CONSTANTS.FIELDS.CUSTOMER_ORDERS.ORDER_ID) {
        const docSnapshot = await searchResult(true, comparator, value, searchOptions, search, orderByField, sortingFlow)
        currentFirstRow = docSnapshot.docs[0]
        currentLastRow = docSnapshot.docs[docSnapshot.docs.length - 1]
        docSnapshot.forEach(doc => {
            tempData.push({...doc.data(), dId: doc.id })
        })
    } else if (search && searchOptions !== CONSTANTS.FIELDS.CUSTOMER_ORDERS.ORDER_ID) {
        const docSnapshot = await searchResult(false, comparator, value, searchOptions, search, orderByField, sortingFlow)
        currentFirstRow = docSnapshot.docs[0]
        currentLastRow = docSnapshot.docs[docSnapshot.docs.length - 1]
        docSnapshot.forEach(doc => {
            tempData.push({...doc.data(), dId: doc.id })
        })
    } else {
        const firstPage = query(collection(db, CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS),
            orderBy(orderByField, sortingFlow),
            where(CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS, comparator, value),
            limit(10))
        const docSnapshot = await getDocs(firstPage)
        currentFirstRow = docSnapshot.docs[0]
        currentLastRow = docSnapshot.docs[docSnapshot.docs.length - 1]
        docSnapshot.forEach(doc => {
            tempData.push({...doc.data(), dId: doc.id })
        })
    }
    setFirstRow(currentFirstRow)
    setLastRow(currentLastRow)
    let finalData = sanitizeResult(tempData, remainingTimeLimit.maxAcceptanceLimitHours)
    setData(finalData)
}

export const fetchingData = async(
    //This will help with and without search
    activeTab,
    setFirstRow,
    setLastRow,
    setData,
    setLoading,
    orderByField,
    sortingFlow,
    search,
    searchOptions,
    dateFilterActive,
    startDate,
    endDate,
    orderSizeFilterActive,
    orderSizeNumber,
    orderStatusFilterActive,
    orderStatus
) => {
    setLoading(true)
    if (activeTab === CONSTANTS.STRINGS_LIST.IN_PROCESS) {
        fetchingDataHelper(setFirstRow, setLastRow, setData, search, searchOptions, "in", ["accepted", "unconfirmed"], orderByField, sortingFlow, dateFilterActive, startDate, endDate, orderSizeFilterActive, orderSizeNumber, orderStatusFilterActive, orderStatus)

    } else if (activeTab === CONSTANTS.STRINGS_LIST.REJECTED) {
        fetchingDataHelper(setFirstRow, setLastRow, setData, search, searchOptions, "==", "rejected", orderByField, sortingFlow, dateFilterActive, startDate, endDate, orderSizeFilterActive, orderSizeNumber, orderStatusFilterActive, orderStatus)

    } else if (activeTab === CONSTANTS.STRINGS_LIST.CANCELLED) {
        fetchingDataHelper(setFirstRow, setLastRow, setData, search, searchOptions, "==", "cancelled", orderByField, sortingFlow, dateFilterActive, startDate, endDate, orderSizeFilterActive, orderSizeNumber, orderStatusFilterActive, orderStatus)
    }
    setLoading(false)
}

export const nextPageData = async(queryRef, lastDoc, orderByField, orderDirection, query_limit, searchIsActive, activeFilter) => {
    let next;
    if (activeFilter === "allThreeFiltersActive") {
        next = query(
            collection(db, queryRef.collection_name),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, orderDirection),
            // orderBy(orderByField, orderDirection),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
            where(queryRef.field_name3, queryRef.comparator3, queryRef.field_value3),
            where(queryRef.field_name4, queryRef.comparator4, queryRef.field_value4),
            startAfter(lastDoc),
            limit(query_limit)
        )
    } else if (activeFilter === "dateAndOrderSizeFiltersActive") {
        next = query(
            collection(db, queryRef.collection_name),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, orderDirection),
            // orderBy(orderByField, orderDirection),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
            where(queryRef.field_name3, queryRef.comparator3, queryRef.field_value3),
            where(queryRef.field_name4, queryRef.comparator4, queryRef.field_value4),
            startAfter(lastDoc),
            limit(query_limit)
        )
    } else if (activeFilter === "dateAndOrderStatusFiltersActive") {
        next = query(
            collection(db, queryRef.collection_name),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, orderDirection),
            // orderBy(orderByField, orderDirection),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
            where(queryRef.field_name3, queryRef.comparator3, queryRef.field_value3),
            startAfter(lastDoc),
            limit(query_limit)
        )
    } else if (activeFilter === "orderSizeAndOrderStatusFiltersActive") {
        next = query(
            collection(db, queryRef.collection_name),
            // orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, orderDirection),
            orderBy(orderByField, orderDirection),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
            startAfter(lastDoc),
            limit(query_limit)
        )
    } else if (activeFilter === "dateFilterActive") {
        next = query(
            collection(db, queryRef.collection_name),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, orderDirection),
            // orderBy(orderByField, orderDirection),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
            where(queryRef.field_name3, queryRef.comparator3, queryRef.field_value3),
            startAfter(lastDoc),
            limit(query_limit)
        )
    } else if (activeFilter === "orderSizeFilterActive") {
        next = query(
            collection(db, queryRef.collection_name),
            orderBy(orderByField, orderDirection),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
            startAfter(lastDoc),
            limit(query_limit)
        )
    } else if (activeFilter === "orderStatusFilterActive") {
        next = query(
            collection(db, queryRef.collection_name),
            orderBy(orderByField, orderDirection),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            startAfter(lastDoc),
            limit(query_limit)
        )
    } else if (searchIsActive) {
        if (queryRef.field_name2 === CONSTANTS.FIELDS.CUSTOMER_ORDERS.ORDER_ID) {
            next = query(
                collection(db, queryRef.collection_name),
                where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
                where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
                startAfter(lastDoc),
                limit(query_limit)
            )
        } else {
            next = query(
                collection(db, queryRef.collection_name),
                orderBy(orderByField, orderDirection),
                where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
                where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
                startAfter(lastDoc),
                limit(query_limit)
            )
        }
    } else if (orderByField === 'orderId') {
        next = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            orderBy(orderByField, orderDirection),
            startAfter(lastDoc),
            limit(query_limit)
        );
    } else {
        next = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            orderBy(orderByField, orderDirection),
            startAfter(lastDoc),
            limit(query_limit)
        );
    }

    const nextSnapshot = await getDocs(next);
    let tempData = []
    const last = nextSnapshot.docs[nextSnapshot.docs.length - 1];
    const first = nextSnapshot.docs[0];
    nextSnapshot.forEach((doc) => {
        tempData.push({ dId: doc.id, ...doc.data() })
    })

    const obj = {
        data: tempData,
        last: last,
        first: first
    }
    return obj
}

export const prevPageData = async(queryRef, firstDoc, orderByField, orderDirection, query_limit, searchIsActive, activeFilter) => {
    let lastRef;
    if (activeFilter === "allThreeFiltersActive") {
        lastRef = query(
            collection(db, queryRef.collection_name),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, orderDirection),
            // orderBy(orderByField, orderDirection),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
            where(queryRef.field_name3, queryRef.comparator3, queryRef.field_value3),
            where(queryRef.field_name4, queryRef.comparator4, queryRef.field_value4),
            endBefore(firstDoc),
            limitToLast(query_limit)
        )
    } else if (activeFilter === "dateAndOrderSizeFiltersActive") {
        lastRef = query(
            collection(db, queryRef.collection_name),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, orderDirection),
            // orderBy(orderByField, orderDirection),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
            where(queryRef.field_name3, queryRef.comparator3, queryRef.field_value3),
            where(queryRef.field_name4, queryRef.comparator4, queryRef.field_value4),
            endBefore(firstDoc),
            limitToLast(query_limit)
        )
    } else if (activeFilter === "dateAndOrderStatusFiltersActive") {
        lastRef = query(
            collection(db, queryRef.collection_name),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, orderDirection),
            // orderBy(orderByField, orderDirection),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
            where(queryRef.field_name3, queryRef.comparator3, queryRef.field_value3),
            endBefore(firstDoc),
            limitToLast(query_limit)
        )
    } else if (activeFilter === "orderSizeAndOrderStatusFiltersActive") {
        lastRef = query(
            collection(db, queryRef.collection_name),
            orderBy(orderByField, orderDirection),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
            endBefore(firstDoc),
            limitToLast(query_limit)
        )
    } else if (activeFilter === "dateFilterActive") {
        lastRef = query(
            collection(db, queryRef.collection_name),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, orderDirection),
            // orderBy(orderByField, orderDirection),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
            where(queryRef.field_name3, queryRef.comparator3, queryRef.field_value3),
            endBefore(firstDoc),
            limitToLast(query_limit)
        )
    } else if (activeFilter === "orderSizeFilterActive") {
        lastRef = query(
            collection(db, queryRef.collection_name),
            orderBy(orderByField, orderDirection),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
            endBefore(firstDoc),
            limitToLast(query_limit)
        )
    } else if (activeFilter === "orderStatusFilterActive") {
        lastRef = query(
            collection(db, queryRef.collection_name),
            orderBy(orderByField, orderDirection),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            endBefore(firstDoc),
            limitToLast(query_limit)
        )
    } else if (searchIsActive) {
        if (queryRef.field_name2 === CONSTANTS.FIELDS.CUSTOMER_ORDERS.ORDER_ID) {
            lastRef = query(
                collection(db, queryRef.collection_name),
                orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, orderDirection),
                where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
                where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
                endBefore(firstDoc),
                limitToLast(query_limit)
            )
        } else {
            lastRef = query(
                collection(db, queryRef.collection_name),
                orderBy(orderByField, orderDirection),
                where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
                where(queryRef.field_name2, queryRef.comparator2, queryRef.field_value2),
                endBefore(firstDoc),
                limitToLast(query_limit)
            )
        }
    } else if (orderByField === 'serviceDate') {
        lastRef = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, orderDirection),
            endBefore(firstDoc),
            limitToLast(query_limit)
        );
    } else {
        lastRef = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            orderBy(orderByField, orderDirection),
            endBefore(firstDoc),
            limitToLast(query_limit)
        );
    }

    const lastSnapshot = await getDocs(lastRef);
    let data = []
    const last = lastSnapshot.docs[lastSnapshot.docs.length - 1];
    const first = lastSnapshot.docs[0];
    lastSnapshot.forEach((doc) => {
        data.push({ dId: doc.id, ...doc.data() })
    })

    const obj = {
        data,
        last,
        first
    }
    return obj
}

export const sortFnWithSearch = async(queryRef, searchActive, dateFilterActive, orderSizeActive, orderStatusActive) => {
    let q;
    if (dateFilterActive && orderSizeActive && orderStatusActive) {
        q = query(
            collection(db, queryRef.collectionName),
            where(queryRef.status, queryRef.statusComparator, queryRef.statusValue),
            where(queryRef.serviceDate, queryRef.greaterThanComparator, queryRef.startDate),
            where(queryRef.serviceDate, queryRef.lessThanComparator, queryRef.endDate),
            where(queryRef.orderSize, queryRef.equalComparator, queryRef.orderSizeNumber),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, queryRef.sortMethod),
            limit(10)
        );
    } else if (dateFilterActive && orderSizeActive) {
        q = query(
            collection(db, queryRef.collectionName),
            where(queryRef.status, queryRef.statusComparator, queryRef.statusValue),
            where(queryRef.serviceDate, queryRef.greaterThanComparator, queryRef.startDate),
            where(queryRef.serviceDate, queryRef.lessThanComparator, queryRef.endDate),
            where(queryRef.orderSize, queryRef.equalComparator, queryRef.orderSizeNumber),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, queryRef.sortMethod),
            limit(10)
        );
    } else if (dateFilterActive && orderStatusActive) {
        q = query(
            collection(db, queryRef.collectionName),
            where(queryRef.status, queryRef.statusComparator, queryRef.statusValue),
            where(queryRef.serviceDate, queryRef.greaterThanComparator, queryRef.startDate),
            where(queryRef.serviceDate, queryRef.lessThanComparator, queryRef.endDate),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, queryRef.sortMethod),
            limit(10)
        );
    } else if (orderSizeActive && orderStatusActive) {
        q = query(
            collection(db, queryRef.collectionName),
            where(queryRef.status, queryRef.statusComparator, queryRef.statusValue),
            where(queryRef.orderSize, queryRef.equalComparator, queryRef.orderSizeNumber),
            orderBy(queryRef.orderBy, queryRef.sortMethod),
            limit(10)
        );
    } else if (dateFilterActive) {
        q = query(
            collection(db, queryRef.collectionName),
            where(queryRef.status, queryRef.statusComparator, queryRef.statusValue),
            where(queryRef.serviceDate, queryRef.greaterThanComparator, queryRef.startDate),
            where(queryRef.serviceDate, queryRef.lessThanComparator, queryRef.endDate),
            orderBy(CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE, queryRef.sortMethod),
            limit(10)
        );
    } else if (orderSizeActive) {
        q = query(
            collection(db, queryRef.collectionName),
            where(queryRef.status, queryRef.statusComparator, queryRef.statusValue),
            where(queryRef.orderSize, queryRef.equalComparator, queryRef.orderSizeNumber),
            orderBy(queryRef.orderBy, queryRef.sortMethod),
            limit(10)
        );
    } else if (orderStatusActive) {
        q = query(
            collection(db, queryRef.collectionName),
            where(queryRef.status, queryRef.statusComparator, queryRef.statusValue),
            orderBy(queryRef.orderBy, queryRef.sortMethod),
            limit(10)
        );
    } else if (searchActive) {
        q = query(
            collection(db, queryRef.collectionName),
            where(queryRef.status, queryRef.statusComparator, queryRef.statusValue),
            where(queryRef.searchOptions, queryRef.equalComparator, queryRef.searchValue),
            orderBy(queryRef.orderBy, queryRef.sortMethod),
            limit(10)
        );
    } else {
        q = query(
            collection(db, queryRef.collectionName),
            where(queryRef.status, queryRef.statusComparator, queryRef.statusValue),
            orderBy(queryRef.orderBy, queryRef.sortMethod),
            limit(10)
        );
    }
    const querySnapshot = await getDocs(q);
    let data = []
    const last = querySnapshot.docs[querySnapshot.docs.length - 1];
    const first = querySnapshot.docs[0];
    querySnapshot.forEach((doc) => {
        data.push({
            dId: doc.id,
            ...doc.data()
        })
    });

    const obj = {
        data: data,
        last: last,
        first: first,
    }
    return obj
}


export const fieldName = (searchOption) => {
    let field
    searchOption === "Vendor" ? field = CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_NAME :
        searchOption === "Company" ? field = CONSTANTS.FIELDS.CUSTOMER_ORDERS.CUSTOMER_COMPANY :
        field = CONSTANTS.FIELDS.CUSTOMER_ORDERS.ORDER_ID;
    return field
}

export const fourFieldQuery = (collection_name, field_name1, comparator1, field_value1, field_name2, comparator2, field_value2, field_name3, comparator3, field_value3, field_name4, comparator4, field_value4) => {
    let query = {
        collection_name,
        field_name1,
        comparator1,
        field_value1,
        field_name2,
        comparator2,
        field_value2,
        field_name3,
        comparator3,
        field_value3,
        field_name4,
        comparator4,
        field_value4,
    }
    return query
}

export const threeFieldQuery = (collection_name, field_name1, comparator1, field_value1, field_name2, comparator2, field_value2, field_name3, comparator3, field_value3) => {
    let query = {
        collection_name,
        field_name1,
        comparator1,
        field_value1,
        field_name2,
        comparator2,
        field_value2,
        field_name3,
        comparator3,
        field_value3,
    }
    return query
}

export const twoFieldQuery = (collection_name, field_name1, comparator1, field_value1, field_name2, comparator2, field_value2) => {
    let query = {
        collection_name,
        field_name1,
        comparator1,
        field_value1,
        field_name2,
        comparator2,
        field_value2,
    }
    return query
}

export const oneFieldQuery = (collection_name, field_name1, comparator1, field_value1) => {
    let query = {
        collection_name,
        field_name1,
        comparator1,
        field_value1,
    }
    return query
}