import AdminFAQAccordian from "./AdminFAQAccordian"
import Loader from '../../../../Components/Loader'

export default function AdminFAQTable({ propList }) {
    return (
        <div className="table">
            <div className="tableContainer">
                {propList.loading && <Loader />}
                <AdminFAQAccordian
                    propList={propList}
                />

            </div>
        </div>
    )
}