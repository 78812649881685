import { useState } from "react";
import PackageDetailSkeleton from "./Views/PackageDetailSkeleton";

export default function PackageDetails({propsList}){
    const [toggleActive, setToggleActive] = useState(true)

    return(
        <PackageDetailSkeleton
            propsList={propsList}
            toggleActive={toggleActive}
            setToggleActive={setToggleActive}
        />
    )
}