import React from 'react'

export default function TopStrip({
    path,
    primaryBreadCrumb,
    btnText,
    btnAction,
    image,
    showBtn,
    secondPath,
    buttons,
}) {
    return (
        <div className="strip__Top">
            <div className="left">
                <img src={image} alt="" width="14px" height="14px" />
                <h1 className="primary" onClick={primaryBreadCrumb}>{path}</h1>
                {secondPath?.map((item, index) => {
                    return <h1 className="secondary" onClick={item.action} key={index}>/&nbsp;&nbsp;{item.label}</h1>
                })}
            </div>
            <div className="right">
                {buttons && buttons.map((item, index) => {
                    return item.showBtn && <button id={`button_${index}`} className="btn" key={index} onClick={item.btnAction} disabled={item.disabled}>{item.btnText}</button>
                })}
            </div>
        </div>
    )
}
