import React from 'react'
import { CONSTANTS } from '../../../../Helpers/Constants'
import DashboardTableView from '../../../../Components/Table/Views/TableView'
import { Images } from '../../../../Assets/Images/Images'
import TopStrip from '../../../../Components/TopStrip'
import Table from '../../../../Components/Table'
import DashboardTableHeaders from '../../../../Components/Table/Views/TableHeaders'
import Loader from '../../../../Components/Loader'

export default function VendorOrderTable(
    { propsList }
) {
    const { clearFilter, filterActive, activeTab, dashBoardHeader, setActiveTab, handleHeaderClick, loading } = propsList
    const date = new Date()
    const {
        recordsAfterPagingAndSortings,
    } = DashboardTableView(propsList)

    return (
        <>
            <TopStrip
                path={[CONSTANTS.STRINGS_LIST.ORDERS]}
                image={Images.order_icon_black}
                buttons={[{
                    btnText: CONSTANTS.STRINGS_LIST.RESET,
                    btnAction: clearFilter,
                    showBtn: filterActive,
                }
                ]}
            />
            <div className="table">
                <div className="tableContainer">
                    <DashboardTableHeaders
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        headerList={dashBoardHeader}
                        handleHeaderClick={handleHeaderClick}
                    />
                    {loading && <Loader />}
                    <Table
                        date={date}
                        propsList={propsList}
                        recordsAfterPagingAndSortings={recordsAfterPagingAndSortings}
                    />
                </div>
            </div>
        </>
    )
}
