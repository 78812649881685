import React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Images } from '../../../../Assets/Images/Images';
import { faq_edit_handler } from '../../../../Helpers/Helper';
import Loader from '../../../../Components/Loader';
import { accordianHandleChange } from "../Views/ComponentHelpers"

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  < MuiAccordionSummary
    expandIcon={< ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: props.bg === 'done' ? '#fff' : 'black' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function AdminFAQAccordion({ propList }) {
  const { loading, faqData, setModal, expanded, setExpanded, background, setBackground } = propList

  return (
    <div className='accordian-parent'>
      {loading && <Loader />}
      
      {faqData && faqData.map((data, index) => {
        return (<Accordion key={index} style={{ borderTop: '1px solid #CED4DA' }} expanded={expanded === `panel${index + 1}`} onChange={accordianHandleChange(`panel${index + 1}`, setBackground, setExpanded)}>
          <AccordionSummary className={background === `panel${index + 1}` ? 'accordian-bg-dark' : ''} aria-controls={`panel${index + 1}d-content`} id={`panel${index + 1}d-header`} bg={background === `panel${index + 1}` ? 'done' : 'false'}>
            <Typography >{data.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ width: '88.62%' }}>
                {data.answer}
              </span>
              <span className="pointerCursor editIconSpan">
                <img src={Images.edit_icon} alt='edit_icon' onClick={() => faq_edit_handler(data, index, setModal)} />
              </span>
            </Typography>
          </AccordionDetails>
        </Accordion>)
      })}
    </div>
  );
}