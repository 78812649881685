export default function validateSettings({ formData }) {
    let _markup = formData?.supplementMarkup;
    let _deliveryEnabled = formData?.isDeliveryEnabled;
    let _pickupEnabled = formData?.isPickupEnabled;
    let _city = formData?.city;
    let _locations = formData?.locations;
    let _description = formData?.description;
    if (_markup === '' || _city.length === 0 || _locations.length === 0 || _description === '') {
        return { error: true, message: 'Please enter required fields' }
    }
    if(formData.isSupplementEnabled){
        if (_markup === '' || _markup <= 0 || _markup > 100) {
            return { error: true, message: '"Suppliment markup" value must be between 1 - 100 ' }
        }
    }
    else if (_deliveryEnabled === false && _pickupEnabled === false) {
        return { error: true, message: 'Please select any service mode ' }
    }
    return { error: false, message: "" }
}