import { Images } from "../../../../../Assets/Images/Images"
import { CONSTANTS } from "../../../../../Helpers/Constants"
import { capitalizeFirstLetter, validateURL } from "../../../../../Helpers/Helper"

export default function ItemDetailsCard({ items, removeItem, editMenuItem, onDragStart, onDragEnter, index}) {
    return (
        <div className="detail_card"
            draggable="true"
            onDragStart={(e)=>onDragStart(e, index)}
            onDragEnter={()=>onDragEnter(index)}
        >
            <div className="close-icon" onClick={removeItem}><img src={Images.close_icon} /></div>
            <div className="image_div"  onClick={editMenuItem}>
                <img src={items.imageUrl || Images.default_image} alt="item_image" />
            </div>
            <div className="item_detail"  onClick={editMenuItem}>
                <div className="item_title">
                    {items.title}
                </div>
                <div className="item_description">
                    {items.description}
                </div>
                <div className="item_category">
                    {CONSTANTS.STRINGS_LIST.CATEGORY}:  {capitalizeFirstLetter(items.category)}
                </div>
            </div>
        </div>
    )
}