import { Images } from "../../../../../Assets/Images/Images";
import { CONSTANTS } from "../../../../../Helpers/Constants";
import FormInputs from "../../../../../Components/Controls/FormInputs";
import Dropzone from "../../../../../Components/DragAndDropInput";
import { addItemHandler, itemChangeHandler } from "../ComponentHelper/ComponentHandler";
import Select from "react-select"
import { capitalizeFirstLetter } from "../../../../../Helpers/Helper";
import { getLocalItems } from "../../../../../LocalStorage/LocalStorage";

export default function AddMenuItem({ propsList }) {
    const { setShowAddItem, itemData, setItemData, setPackageItemList, packageItemList, setFilePreview, systemConfig, setChanges, packageItemDefault, selectedMenuItemEdited } = propsList

    let imageName = { ...itemData.imageName }
    let imageUrl = { ...itemData.imageUrl }
    if (imageName && !selectedMenuItemEdited) {
        imageName = undefined
        imageUrl = ""
    }

    return (
        <div className="main-modal">
            <div className="modal-form">
                <div className="form-heading">
                    <p>{!selectedMenuItemEdited ? CONSTANTS.STRINGS_LIST.ADD_ITEM : CONSTANTS.STRINGS_LIST.EDIT_ITEM}</p>
                    <img onClick={() => setShowAddItem(false)} src={Images.close_icon} alt='close' />
                </div>

                <form onSubmit={e => e.preventDefault()}>
                    <div className="input-group">
                        <FormInputs
                            title={CONSTANTS.STRINGS_LIST.TITLE}
                            name={CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.TITLE}
                            placeholder={CONSTANTS.STRINGS_LIST.TITLE.toLocaleLowerCase()}
                            handlerFunction={(event) => { itemChangeHandler(event, itemData, setItemData) }}
                            required="required"
                            pseudoClass="menu_item_title"
                            value={itemData.title}
                        />
                        <Dropzone
                            title={CONSTANTS.STRINGS_LIST.IMAGE}
                            onChange={(files) => setFilePreview(files)}
                            value={itemData.imageName ? itemData.imageName : ""}
                            setChanges={setChanges}
                            state={itemData}
                            setState={setItemData}
                            fieldName={"imageName"}
                            fieldName2={"imageUrl"}
                        />
                    </div>

                    <div className="input-group">
                        <div className="select-group">
                            <p>{CONSTANTS.STRINGS_LIST.CATEGORY}</p>
                            <select
                                name={CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.CATEGORY}
                                onChange={(event) => { itemChangeHandler(event, itemData, setItemData) }}
                                required
                                id={CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.CATEGORY}
                                value={itemData.category}
                            >
                                <option value="">{CONSTANTS.STRINGS_LIST.SELECT_FIELD.toLocaleLowerCase()}</option>
                                {systemConfig?.itemCategories.map((category, index) => {
                                    return (
                                        <option name="category" value={category} key={category + index} >
                                            {capitalizeFirstLetter(category)}
                                        </option>)
                                }
                                )}
                            </select>
                        </div>
                        <div className="select-group">
                            <p>{CONSTANTS.STRINGS_LIST.COURSE_SECTION}</p>
                            <select
                                name={CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.COURSE}
                                onChange={(event) => { itemChangeHandler(event, itemData, setItemData) }}
                                required
                                value={itemData.course}
                                id={CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.COURSE}
                            >
                                <option value="">{CONSTANTS.STRINGS_LIST.SELECT_FIELD.toLocaleLowerCase()}</option>
                                {systemConfig?.itemCourses.map((course, index) => {
                                    return (
                                        <option name="course" value={course} key={course + index} >
                                            {capitalizeFirstLetter(course)}
                                        </option>)
                                }
                                )}
                            </select>
                        </div>
                    </div>

                    <div className="input-group item_description">
                        <p>{CONSTANTS.STRINGS_LIST.DESCRIPTION}</p>
                        <textarea
                            value={itemData.description}
                            name={CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.DESCRIPTION}
                            placeholder={CONSTANTS.STRINGS_LIST.DESCRIPTION.toLocaleLowerCase()}
                            onChange={(event) => { itemChangeHandler(event, itemData, setItemData) }}
                            pseudoClass="menu_item_description"
                        />
                    </div>

                    <div className='add-item-button'>
                        <button id={CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.ADD_BUTTON}
                            onClick={
                                () => addItemHandler(
                                    itemData,
                                    packageItemList,
                                    setPackageItemList,
                                    setShowAddItem,
                                    setItemData,
                                    packageItemDefault,
                                    selectedMenuItemEdited
                                )
                            }
                        >
                            {!selectedMenuItemEdited ? CONSTANTS.STRINGS_LIST.ADD_ITEM : CONSTANTS.STRINGS_LIST.SAVE_ITEM}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}