import {Images} from  '../../../Assets/Images/Images';
import {CONSTANTS} from "../../../Helpers/Constants"

export let admin_side_menu = [
    {
        image_black: Images.order_icon_unfill,
        image_white: Images.order_icon_white,
        label: CONSTANTS.STRINGS_LIST.ORDERS,
        action: (setIsActive, i) => {
            setIsActive('/admin/orders')
        },
        route: '/admin/orders'
    },
    {
        image_black: Images.vendor_black,
        image_white: Images.vendor_white,
        label: CONSTANTS.STRINGS_LIST.VENDORS,
        action: (setIsActive, i) => {
            setIsActive('/admin/vendors')
        },
        route: '/admin/vendors'
    },
    {
        image_black: Images.faq_black,
        image_white: Images.faq_white,
        label: CONSTANTS.STRINGS_LIST.FAQ,
        action: (setIsActive, i) => {
            setIsActive('/admin/FAQ')
        },
        route: '/admin/FAQ'
    },
    {
        image_black: Images.setting_black,
        image_white: Images.setting_white,
        label: CONSTANTS.STRINGS_LIST.SETTINGS,
        action: (setIsActive, i) => {
            setIsActive('/admin/settings')
        },
        route: '/admin/settings'
    },
]

export let vendor_side_menu = [
    {
        image_black: Images.order_icon_unfill,
        image_white: Images.order_icon_white,
        label: CONSTANTS.STRINGS_LIST.ORDERS,
        action: (setIsActive, i) => {
            setIsActive('/vendor/orders')
        },
        route: '/vendor/orders'
    },
    {
        image_black: Images.package_icon_black,
        image_white: Images.package_icon_white,
        label: CONSTANTS.STRINGS_LIST.PACKAGES,
        action: (setIsActive, i) => {
            setIsActive('/vendor/packages')
        },
        route: '/vendor/packages'
    },
    {
        image_black: Images.setting_black,
        image_white: Images.setting_white,
        label: CONSTANTS.STRINGS_LIST.SETTINGS,
        action: (setIsActive, i) => {
            setIsActive('/vendor/settings')
        },
        route: '/vendor/settings'
    },
]