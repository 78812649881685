import { collection, query, where, getDoc, getDocs, limit, limitToLast, doc, startAfter, orderBy, endBefore } from "firebase/firestore";
import { db } from '../Firebase/Config'
import { Timestamp } from "@firebase/firestore";
import { CONSTANTS } from "./Constants";
import { capitalizeFirstLetter } from "./Helper";

export const getUserData = async (userID, collectionName) => {
    const q = doc(db, collectionName, userID);
    const querySnapshot = await getDoc(q);
    const tempData = querySnapshot.data();
    return tempData
}

export const getOrderPackage = async (orderId, subCollectionName) => {
    let tempData;
    if (orderId !== undefined) {
        tempData = []
        const subCollection = collection(db, 'customer_orders', orderId, subCollectionName);
        const querySnapshot = await getDocs(subCollection);
        querySnapshot.forEach((doc) => {
            tempData.push({
                ...doc.data(),
                packageId: doc.id,
            })
        });
    }
    return tempData
}

export const getOrderPackageItems = async (orderId, packageId) => {
    const subCollection = collection(db, 'customer_orders', orderId, 'customer_order_packages', packageId, 'customer_order_package_items');
    const querySnapshot = await getDocs(subCollection);
    let tempData = [];
    querySnapshot.forEach((doc) => {
        tempData.push({
            ...doc.data(),
        })
    });
    return tempData
}

export const fetchVendors = async (queryRef) => {
    let q;

    q = query(
        collection(db, queryRef.collection_name),
        where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
        orderBy(queryRef.order_by, queryRef.sort_method),
        limit(queryRef.query_limit)
    );
    const querySnapshot = await getDocs(q);
    let tempData = []
    const first = querySnapshot.docs[0];
    const last = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
        tempData.push({
            dId: doc.id,
            ...doc.data()
        })
    });

    const obj = {
        first: first,
        data: tempData,
        last: last
    }
    return obj

}

export const fetchAllVendors = async (queryRef) => {
    let q;
    q = query(
        collection(db, queryRef.collection_name),
        orderBy(queryRef.order_by, queryRef.sort_method),
    );
    const querySnapshot = await getDocs(q);
    let tempData = []
    const first = querySnapshot.docs[0];
    const last = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
        tempData.push({
            dId: doc.id,
            ...doc.data()
        })
    });
    const obj = {
        first: first,
        data: tempData,
        last: last
    }
    return obj
}

export const fetchAllPackages = async (queryRef, vendorId) => {
    let q;
    q = query(
        collection(db, queryRef.collection_name),
        orderBy(queryRef.order_by, queryRef.sort_method),
        where("vendorId", "==", vendorId),
    );
    const querySnapshot = await getDocs(q);
    let tempData = []
    const first = querySnapshot.docs[0];
    const last = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
        tempData.push({
            dId: doc.id,
            ...doc.data()
        })
    });
    const obj = {
        first: first,
        data: tempData,
        last: last
    }
    return obj
}


export const fetchPackageVendor = async (queryRef, refDoc, search) => {
    let q;
    if (search) {
        q = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, refDoc),
            where('title', '==', search),
            orderBy(queryRef.order_by, queryRef.sort_method),
            limit(queryRef.query_limit)
        );
    } else {
        q = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, refDoc),
            orderBy(queryRef.order_by, queryRef.sort_method),
            limit(queryRef.query_limit)
        );
    }
    const querySnapshot = await getDocs(q);
    let tempData = []
    const first = querySnapshot.docs[0];
    const last = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
        tempData.push({
            dId: doc.id,
            ...doc.data()
        })
    });

    const obj = {
        first: first,
        data: tempData,
        last: last
    }
    return obj
}

// Firebase Queries for Orders
// Fetching Orders on App Load
// Search Handler w.r.t Current State
export const searchFetch = async (
    {
        collection_name,
        field_name1,
        comparator1,
        field_value1,
        order_by,
        sort_method,
        query_limit,
        field_name2,
        comparator2,
        field_name3,
        comparator3,
        field_name4,
        comparator4,
    },
    field_value2,
    field_value3,
    field_value4,
) => {
    let q
    if (field_name1 === order_by ||
        field_name2 === order_by ||
        field_name3 === order_by ||
        field_name4 === order_by
    ) {
        if (field_name4) {
            q = query(
                collection(db, collection_name),
                where(field_name1, comparator1, field_value1),
                where(field_name2, comparator2, field_value2),
                where(field_name3, comparator3, field_value3),
                where(field_name4, comparator4, field_value4),
                orderBy(order_by, sort_method),
                limit(query_limit)
            );
        }
        if (field_name3) {
            q = query(
                collection(db, collection_name),
                where(field_name1, comparator1, field_value1),
                where(field_name2, comparator2, field_value2),
                where(field_name3, comparator3, field_value3),
                limit(query_limit)
            );
        } else if (field_name2) {
            q = query(
                collection(db, collection_name),
                where(field_name1, comparator1, field_value1),
                where(field_name2, comparator2, field_value2),
                limit(query_limit)
            );
        } else {
            q = query(
                collection(db, collection_name),
                where(field_name1, comparator1, field_value1),
                limit(query_limit)
            );
        }
    } else {
        if (field_name3) {
            q = query(
                collection(db, collection_name),
                where(field_name1, comparator1, field_value1),
                where(field_name2, comparator2, field_value2),
                where(field_name3, comparator3, field_value3),
                orderBy(order_by, sort_method),
                limit(query_limit)
            );
        } else if (field_name2) {
            q = query(
                collection(db, collection_name),
                where(field_name1, comparator1, field_value1),
                where(field_name2, comparator2, field_value2),
                orderBy(order_by, sort_method),
                limit(query_limit)
            );
        } else {
            q = query(
                collection(db, collection_name),
                where(field_name1, comparator1, field_value1),
                orderBy(order_by, sort_method),
                limit(query_limit)
            );
        }
    }

    const querySnapshot = await getDocs(q);
    let tempData = []
    const first = querySnapshot.docs[0];
    const last = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
        tempData.push({
            dId: doc.id,
            ...doc.data()
        })
    });
    const obj = {
        first: first,
        data: tempData,
        last: last
    }
    return obj
}




export const sortFnWithStatus = async (queryRef, searchField) => {
    let q;

    // Currently being used only for Order Status
    q = query(
        collection(db, queryRef.collection_name),
        where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
        orderBy('orderId', queryRef.sort_method),
        limit(10)
    );


    const querySnapshot = await getDocs(q);
    let tempData = []
    const last = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
        tempData.push({
            dId: doc.id,
            ...doc.data()
        })
    });

    const obj = {
        data: tempData,
        last: last
    }
    return obj

}

export const sortFnWithSearchFields = async (queryRef, searchField) => {
    let q;
    q = query(
        collection(db, queryRef.collection_name),
        where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
        where(queryRef.field_name2, queryRef.comparator2, searchField),
        orderBy(queryRef.field_name2, queryRef.sort_method),
        orderBy('orderId', queryRef.sort_method),
        limit(10)
    );


    const querySnapshot = await getDocs(q);
    let tempData = []
    const last = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
        tempData.push({
            dId: doc.id,
            ...doc.data()
        })
    });

    const obj = {
        data: tempData,
        last: last
    }
    return obj
}



// Order Company Search Handler w.r.t Current State
// Order Vendor Search Handler w.r.t Current State
// For Material Search Handler w.r.t Current State on Accepted State
// For Material Search Handler w.r.t Current State on not Accepted State
// Order Size Handler w.r.t Current State
// Search Orders with Start Date Filters w.r.t. Current Active State
// Search Orders with End Date Filters w.r.t. Current Active State
// Search Orders with Start and End Dates Filters w.r.t. Current Active State
// Search Vendors with Name Filters w.r.t. Current Active State

// Get Tags
export const getConfig = async () => {
    const q = query(collection(db, "system_config"));
    const querySnapshot = await getDocs(q);
    let tempData = []
    querySnapshot.forEach((doc) => {
        tempData = doc.data();
        tempData._id = doc.id;
    });
    return tempData
}

export const organizationNumberQuery = async (input) => {
    const q = query(collection(db, "vendor_profiles"), where("organizationNumber", "==", input));
    let tempData = []
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        tempData.push({
            id: doc.id,
            ...doc.data()
        })
    });
    return tempData
}

export const getFAQ = async () => {
    const q = query(collection(db, "customer_faqs"));
    const querySnapshot = await getDocs(q);
    let tempData = []
    querySnapshot.forEach((doc) => {
        tempData.push({
            id: doc.id,
            ...doc.data()
        })
    });
    return tempData
}

export const checkNextPage = async (queryRef, lastDocID, orderByField, orderDirection, query_limit) => {
    let q = query(
        collection(db, queryRef.collection_name),
        where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
        orderBy(orderByField, orderDirection),
        startAfter(lastDocID),
        limit(query_limit)
    );
    const querySnapshot = await getDocs(q);
    let tempData = []
    querySnapshot.forEach((doc) => {
        tempData.push(doc.data())
    })
    return tempData
}


export const nextPageDataVendors = async (queryRef, lastDoc, orderByField, orderDirection, query_limit, search, activeState) => {
    let next;
    if (search) {
        next = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where("status", queryRef.comparator1, activeState.toLocaleLowerCase()),
            where(CONSTANTS.FIELDS.VENDOR_PROFILES.VENDOR_NAME, "==", search),
            orderBy(queryRef.order_by, queryRef.sort_method),
            startAfter(lastDoc),
            limit(query_limit)
        );
    } else {
        next = query(
            collection(db, queryRef.collection_name),
            where("status", queryRef.comparator1, activeState.toLocaleLowerCase()),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            orderBy(queryRef.order_by, queryRef.sort_method),
            startAfter(lastDoc),
            limit(query_limit)
        );
    }


    const nextSnapshot = await getDocs(next);
    let tempData = []
    const last = nextSnapshot.docs[nextSnapshot.docs.length - 1];
    const first = nextSnapshot.docs[0];
    nextSnapshot.forEach((doc) => {
        tempData.push({ dId: doc.id, ...doc.data() })
    })

    const obj = {
        data: tempData,
        last: last,
        first: first
    }
    return obj
}

export const checkPreviousPage = async (queryRef, lastDocID, orderByField, orderDirection) => {
    let q = query(
        collection(db, queryRef.collection_name),
        where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
        orderBy(orderByField, orderDirection),
        endBefore(lastDocID),
        limit(queryRef.query_limit)
    );
    const querySnapshot = await getDocs(q);
    let tempData = []
    querySnapshot.forEach((doc) => {
        tempData.push({ dId: doc.id, ...doc.data() })
    })
    return tempData
}

export const getPackages = async (queryRef, orderByField, orderDirection) => {
    const q = query(
        collection(db, queryRef.collection_name),
        where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
        orderBy(orderByField, orderDirection),
        limitToLast(queryRef.query_limit)
    );
    const querySnapshot = await getDocs(q);
    let tempData = [];
    querySnapshot.forEach((doc) => {
        tempData.push({
            ...doc.data(),
            packageId: doc.id,
        })
    });
    return tempData
}

export const prevPageDataPackages = async (queryRef, userRef, firstDoc, search) => {
    let lastRef;
    if (search !== CONSTANTS.STRINGS_LIST.EMPTY_STRING) {
        lastRef = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, userRef),
            where('title', '==', search),
            orderBy(queryRef.order_by, queryRef.sort_method),
            endBefore(firstDoc),
            limitToLast(queryRef.query_limit)
        );
    } else {
        lastRef = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, userRef),
            orderBy(queryRef.order_by, queryRef.sort_method),
            endBefore(firstDoc),
            limitToLast(queryRef.query_limit)
        );
    }


    const lastSnapshot = await getDocs(lastRef);
    let tempData = []
    const last = lastSnapshot.docs[lastSnapshot.docs.length - 1];
    const first = lastSnapshot.docs[0];
    lastSnapshot.forEach((doc) => {
        tempData.push({ dId: doc.id, ...doc.data() })
    })

    const obj = {
        data: tempData,
        last: last,
        first: first
    }
    return obj
}

export const nextPageDataPackages = async (queryRef, userRef, lastDoc, search) => {
    let firstRef;
    if (search !== CONSTANTS.STRINGS_LIST.EMPTY_STRING) {
        firstRef = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, userRef),
            where('title', '==', search),
            orderBy(queryRef.order_by, queryRef.sort_method),
            startAfter(lastDoc),
            limit(queryRef.query_limit)
        );
    } else {
        firstRef = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, userRef),
            orderBy(queryRef.order_by, queryRef.sort_method),
            startAfter(lastDoc),
            limit(queryRef.query_limit)
        );
    }


    const lastSnapshot = await getDocs(firstRef);
    let tempData = []
    const last = lastSnapshot.docs[lastSnapshot.docs.length - 1];
    const first = lastSnapshot.docs[0];
    lastSnapshot.forEach((doc) => {
        tempData.push({ dId: doc.id, ...doc.data() })
    })

    const obj = {
        data: tempData,
        last: last,
        first: first
    }
    return obj
}

export const checkNextPageDataPackages = async (queryRef, userRef, lastDoc, search) => {
    let firstRef;
    if (search) {
        firstRef = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, userRef),
            where('title', '==', search),
            orderBy(queryRef.order_by, queryRef.sort_method),
            startAfter(lastDoc),
            limit(1)
        );
    }
    else {
        firstRef = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, userRef),
            orderBy(queryRef.order_by, queryRef.sort_method),
            startAfter(lastDoc),
            limit(1)
        );
    }


    const lastSnapshot = await getDocs(firstRef);
    const snapSize = lastSnapshot.docs.length;
    return snapSize
}

export const checkPrevPageDataPackages = async (queryRef, userRef, firstDoc, search) => {
    let firstRef;
    if (search) {
        firstRef = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, userRef),
            where('title', '==', search),
            orderBy(queryRef.order_by, queryRef.sort_method),
            endBefore(firstDoc),
            limit(1)
        );
    }
    else {
        firstRef = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(queryRef.field_name2, queryRef.comparator2, userRef),
            orderBy(queryRef.order_by, queryRef.sort_method),
            endBefore(firstDoc),
            limit(1)
        );
    }


    const lastSnapshot = await getDocs(firstRef);
    const snapSize = lastSnapshot.docs.length;
    return snapSize
}

export const prevPageDataVendors = async (queryRef, firstDoc, orderByField, orderDirection, query_limit, search, activeState) => {
    let lastRef;
    if (search) {
        lastRef = query(
            collection(db, queryRef.collection_name),
            where("status", queryRef.comparator1, activeState.toLocaleLowerCase()),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            where(CONSTANTS.FIELDS.VENDOR_PROFILES.VENDOR_NAME, "==", search),
            orderBy(queryRef.order_by, queryRef.sort_method),
            endBefore(firstDoc),
            limitToLast(query_limit)
        );
    } else {

        lastRef = query(
            collection(db, queryRef.collection_name),
            where(queryRef.field_name1, queryRef.comparator1, queryRef.field_value1),
            orderBy(queryRef.order_by, queryRef.sort_method),
            endBefore(firstDoc),
            limitToLast(query_limit)
        );
    }


    const lastSnapshot = await getDocs(lastRef);
    let tempData = []
    const last = lastSnapshot.docs[lastSnapshot.docs.length - 1];
    const first = lastSnapshot.docs[0];
    lastSnapshot.forEach((doc) => {
        tempData.push({ dId: doc.id, ...doc.data() })
    })

    const obj = {
        data: tempData,
        last: last,
        first: first
    }
    return obj
}

export const admin_login_query = async (input) => {
    const q = query(collection(db, "admin_users"), where("emailAddress", "==", input));
    let tempData = []
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        tempData.push({
            id: doc.id,
            ...doc.data()
        })
    });
    return tempData
}

export const vendor_login_query = async (input) => {
    const q = query(collection(db, "vendor_users"), where("emailAddress", "==", input), where("isActive", "==", true))
    let tempData = []
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        tempData.push({
            id: doc.id,
            ...doc.data()
        })
    });
    return tempData
}

export const getSystemConfig = async (id, collectionName) => {
    const q = doc(db, collectionName, id);
    const querySnapshot = await getDoc(q);
    const tempData = querySnapshot.data();
    return tempData
}

export const getPackageItems = async (id = "123") => {
    const q = query(collection(db, "vendor_packages", id, "vendor_package_items"), orderBy("sortIndex", "asc"));
    const querySnapshot = await getDocs(q);
    let tempData = []
    querySnapshot.forEach((doc) => {
        tempData.push({ ...doc.data(), id: doc.id })
    });
    return tempData

}

export const getVendorProfile = async (id) => {
    const q = doc(db, 'vendor_users', id);
    const querySnapshot = await getDoc(q);
    const tempData = querySnapshot.data();
    const vendorProfileId = tempData.vendorId.id;
    const q_vp = doc(db, 'vendor_profiles', vendorProfileId);
    const _querySnapshot = await getDoc(q_vp);
    const _tempData = _querySnapshot.data();
    tempData.vendorProfileId = vendorProfileId;
    tempData.vendorProfile = _tempData;
    return tempData
}

export const getCities = async () => {
    const getData = await getConfig();
    const locationsCities = Object.keys(getData.locations);
    let cities = [];
    locationsCities.map((data) => {
        cities.push({ 'label': capitalizeFirstLetter(data), 'value': data })
    })
    return cities;
}

export const addArrayInDB = async (cities) => {
    let updatedCities = [];
    cities.map((item) => {
        updatedCities.push(item.value.toLowerCase());
    })
    return updatedCities;
}

export const getAreas = async () => {
    const getData = await getConfig();
    const locationsCities = Object.keys(getData.locations).map(city => getData.locations[city]);
    let citiesAreas = [];
    locationsCities.map((data) => {
        data.map(area => {
            citiesAreas.push({ 'label': capitalizeFirstLetter(area), 'value': area })
        })
    })
    return citiesAreas;
}

export const timestampToDays = (dates) => {
    let newDates = [];
    dates.map((item) => {
        const date = new Date(item.seconds * 1000);
        newDates.push(date);
    })
    return newDates
}

export const DaysToTimestamp = (dates) => {
    let newDates = [];
    dates.map((item) => {
        const date = Timestamp.fromDate(new Date(item));
        newDates.push(date);
    })
    return newDates
}

export const offsetIndex = (from, to, arr = []) => {
    if (from < to) {
      let start = arr.slice(0, from),
        between = arr.slice(from + 1, to + 1),
        end = arr.slice(to + 1);
      return [...start, ...between, arr[from], ...end];
    }
    if (from > to) {
      let start = arr.slice(0, to),
        between = arr.slice(to, from),
        end = arr.slice(from + 1);
      return [...start, arr[from], ...between, ...end];
    }
    return arr;
  }