import React from 'react'
import OrderDetails from '../../../Admin/OrderDetails'
import VendorOrderTable from "../Views/VendorOrderTable"
// import OrderDetails from '../../OrderDetails/OrderDetails'

export default function VendorOrderSkeleton({propsList}) {
    return (
        <div className="dashboardSkeleton">
            <div className="skeletonContainer">
                {propsList.showOrderDetails ? <OrderDetails propsList={propsList} /> : <VendorOrderTable propsList={propsList} />}
            </div>
        </div>
    )
}
