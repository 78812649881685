import React from 'react'
import { Images } from '../../../../Assets/Images/Images'
import TopStrip from '../../../../Components/TopStrip'
import { CONSTANTS } from '../../../../Helpers/Constants'
import CreateVendorTable from './CreateVendorTable'
import { cancelCreationFunction } from '../../../../Helpers/Helper'
import { setItemsLocally } from '../../../../LocalStorage/LocalStorage'


export default function CreateVendorSkeleton({ propList }) {
    const { setCreate, setChanges, changes, edit } = propList
    return (
        <div className="dashboardSkeleton">
            <TopStrip
                path={[CONSTANTS.STRINGS_LIST.VENDOR]}
                primaryBreadCrumb={(event) => (
                    cancelCreationFunction(event, changes, setCreate, setChanges),
                    setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.VENDORS)
                )}
                secondPath={[
                    {
                        label: edit ? [CONSTANTS.STRINGS_LIST.EDIT_VENDOR] : [CONSTANTS.STRINGS_LIST.CREATE_VENDOR],
                        action: () => { }
                    }
                ]}
                image={Images.vendor_icon}
                buttons={[
                    {
                        btnText: [CONSTANTS.STRINGS_LIST.CANCEL],
                        btnAction: (event) => (
                            cancelCreationFunction(event, changes, setCreate, setChanges),
                            setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.VENDORS)
                        ),
                        showBtn: true,
                    }
                ]}
            />
            <div className="skeletonContainer">
                <CreateVendorTable
                    propList = {propList}
                />
            </div>
        </div>
    )
}
