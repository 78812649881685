import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../../Context/UserContext';
import AdminVendorSkeleton from './Views/AdminVendorsSkeleton'
import { CONSTANTS, QUERY_PARAMS } from '../../../Helpers/Constants'
import { makeStyles, Table, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core'
import {
    fetchVendors,
    nextPageDataVendors,
    prevPageDataVendors,
    searchFetch
} from '../../../Helpers/FetchHandlers'
import { trim } from '../../../Helpers/Helper'
import { getLocalItems, removeItemsLocally, setItemsLocally } from '../../../LocalStorage/LocalStorage'

const heads = [
    {
        id: CONSTANTS.STRINGS_LIST.VENDOR, label: CONSTANTS.STRINGS_LIST.VENDOR, disableSort: true,
    }, {
        id: CONSTANTS.STRINGS_LIST.ORGANIZATION_NUMBER, label: CONSTANTS.STRINGS_LIST.ORGANIZATION_NUMBER, disableSort: true,
    }, {
        id: CONSTANTS.STRINGS_LIST.CONTACT_PERSON, label: CONSTANTS.STRINGS_LIST.CONTACT_PERSON, disableSort: true,
    }, {
        id: CONSTANTS.STRINGS_LIST.CONTACT_NUMBER, label: CONSTANTS.STRINGS_LIST.CONTACT_NUMBER, align: 'left', disableSort: true,
    }, {
        id: '', label: "", disableSort: true, align: 'left',
    }
]

const statusFields = [
    [CONSTANTS.STRINGS_LIST.ORDER_STATUS],
    [CONSTANTS.STRINGS_LIST.ACCEPTED],
    [CONSTANTS.STRINGS_LIST.UNCONFIRMED]
]

const dashBoardHeader = [
    [CONSTANTS.STRINGS_LIST.ENABLED],
    [CONSTANTS.STRINGS_LIST.DISABLED],
]

const listNames = [
    'name',
    'organizationNumber',
    'contactPersonName',
    'contactNumber'
]

const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: '#152536',
            backgroundColor: '#ffffff',
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            backgroundColor: '#F7F7F9',
            cursor: 'pointer',
        },
    },
}))

export default function AdminVendorDashboard() {
    const [createVendor, setCreateVendor] = useState(false)
    const [ sortList, setSortList ] = useState(false);
    const [showAddedData, setShowAddedData] = useState(false)
    const { loading, setLoading } = useContext(UserContext);
    const classes = useStyles();
    const [data, setData] = React.useState([])
    const [filterActive, setFilterActive] = React.useState(false)
    const pages = [10, 20, 30]
    const [order, setOrder] = React.useState();
    const [orderBy, setOrderBy] = React.useState();
    const [activeState, setActiveState] = React.useState(CONSTANTS.STRINGS_LIST.ENABLED);
    const [search, setSearch] = React.useState('');
    const [orderSizeActive, setOrderSizeActive] = React.useState(true);
    const [orderStatusActive, setOrderStatusActive] = React.useState(true);
    const [searchActive, setSearchActive] = React.useState(true);
    const statusText = CONSTANTS.STRINGS_LIST.ORDER_STATUS;
    const [lastRow, setLastRow] = React.useState([]);
    const [firstRow, setFirstRow] = React.useState([]);

    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)

    useEffect(() => {
        setLoading(true);
        if (getLocalItems(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY) === CONSTANTS.STRINGS_LIST.CREATE_VENDOR) {
            setCreateVendor(true)
        } 
        else if (getLocalItems(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY) === CONSTANTS.LOCAL_STORAGE_ITEMS.SORTED_VENDORS_KEY) {
            setSortList(true)
        } 
        else {
            setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.VENDORS);
            removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY)
            removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.ITEM_KEY)
        }


        async function fetchData() {
            let tempVendors = []
            if (activeState === CONSTANTS.STRINGS_LIST.ENABLED) {
                tempVendors = await fetchVendors(QUERY_PARAMS.VENDOR_QUERIES.fetchVendors)
            } else if (activeState === CONSTANTS.STRINGS_LIST.DISABLED) {
                tempVendors = await fetchVendors(QUERY_PARAMS.VENDOR_QUERIES.fetchVendorsDisbaled)
            }
            const tempData = tempVendors.data.map(vendor => {
                return {
                    ...vendor,
                }
            })
            setFirstRow(tempVendors.first)
            setLastRow(tempVendors.last)
            setData(tempData)
            setLoading(false);
        }
        clearFilter()
        fetchData();
    }, [activeState, showAddedData, sortList])

    
    const setSearchFn = (search) => {
        let target = search.target;
        setSearch(target.value);
    }

    const handleSearch = async e => {
        setLoading(true);
        let target = e.target;
        setSearch(target.value);
        if (e.key === CONSTANTS.STRINGS_LIST.ENTER) {
            searchData(target.value);
        }
        setLoading(false);
    }

    const searchData = async (input) => {
        let tempData = []
        const search = trim(input)
        if (activeState === CONSTANTS.STRINGS_LIST.ENABLED) {
            tempData = await searchFetch(QUERY_PARAMS.VENDOR_QUERIES.searchVendorsNameEnabled, search)
        } else {
            tempData = await searchFetch(QUERY_PARAMS.VENDOR_QUERIES.searchVendorsNameDisabled, search)
        }
        setFirstRow(tempData.first)
        setLastRow(tempData.last)
        setData(tempData.data);
        setFilterActive(true);
    }

    const clearFilter = async () => {
        setLoading(true);
        let tempData = []
        if (activeState === CONSTANTS.STRINGS_LIST.ENABLED) {
            tempData = await searchFetch(QUERY_PARAMS.VENDOR_QUERIES.fetchVendors)
        } else if (activeState === CONSTANTS.STRINGS_LIST.DISABLED) {
            tempData = await searchFetch(QUERY_PARAMS.VENDOR_QUERIES.fetchVendorsDisbaled)
        }
        setFirstRow(tempData.first)
        setLastRow(tempData.last)
        setData(tempData.data)
        setFilterActive(false)
        setSearch('')
        setOrderBy('')
        setOrder('')
        setPage(0)
        setRowsPerPage(10)

        setOrderStatusActive(true)
        setOrderSizeActive(true)
        setSearchActive(true)
        setLoading(false);
    }


    const fetchEnabled = async () => {
        setLoading(true);
        const tempVendors = await searchFetch(QUERY_PARAMS.VENDOR_QUERIES.fetchVendors)
        const tempData = tempVendors?.data?.map(vendor => {
            return {
                ...vendor,
            }
        })

        setFirstRow(tempVendors.first)
        setLastRow(tempVendors.last)
        setData(tempData)
        clearFilter()
        setLoading(false);
    }

    const fetchDisabled = async () => {
        setLoading(true);
        const tempVendors = await searchFetch(QUERY_PARAMS.VENDOR_QUERIES.fetchVendorsDisbaled)
        const tempData = tempVendors?.data?.map(vendor => {
            return {
                ...vendor,
            }
        })

        setFirstRow(tempVendors.first)
        setLastRow(tempVendors.last)
        setData(tempData)
        setLoading(false);
    }

    const handleHeaderClick = (header) => {
        setLoading(true);
        if (header === CONSTANTS.STRINGS_LIST.ENABLED) {
            fetchEnabled()
        } else if (header === CONSTANTS.STRINGS_LIST.DISABLED) {
            fetchDisabled()
        }
        setActiveState(header)
        setLoading(false);
    }

    const TblContainer = props => (
        <Table
            className={classes.table}
        >
            {props.children}
        </Table>
    )

    const TblHead = props => {
        const handleSortRequest = cellId => {
            const isAsc = orderBy === cellId && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(cellId);
        };
        return (
            <TableHead>
                <TableRow>
                    {heads.map(headCell => (
                        <TableCell
                            key={headCell.id}
                        >
                            {headCell.disableSort ? headCell.label :
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={() => { handleSortRequest(headCell.id) }}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    const TblPagination = props => (
        <TablePagination
            rowsPerPageOptions={pages}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => {
                setPage(newPage);
            }}
            onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
            }}
        />
    )

    const firstPageFn = async () => {
        const tempOrders = await searchFetch(QUERY_PARAMS.VENDOR_QUERIES.fetchVendors)
        const tempData = tempOrders.data
        if (tempData.length > 0) {
            setLastRow(tempOrders.first)
            setFirstRow(tempOrders.last)
            setData(tempData)
        }
    }

    const prevPageFn = async () => {
        setLoading(true);
        let currentQuery = ''
        const sortBySanitized = 'vendor_profiles'
        const sortOrderSanitized = 'desc'

        if (activeState === CONSTANTS.STRINGS_LIST.ENABLED) {
            currentQuery = QUERY_PARAMS.VENDOR_QUERIES.fetchVendors
        } else if (activeState === CONSTANTS.STRINGS_LIST.DISABLED) {
            currentQuery = QUERY_PARAMS.VENDOR_QUERIES.fetchVendorsDisbaled
        }

        const result = await prevPageDataVendors(
            currentQuery,
            firstRow,
            sortBySanitized,
            sortOrderSanitized,
            rowsPerPage,
            search,
            activeState
        )

        const tempData = result.data
        if (tempData.length > 0) {
            setLastRow(result.last)
            setFirstRow(result.first)
            setData(tempData)
        }
        setLoading(false);

    }

    const nextPageFn = async () => {
        setLoading(true);
        let currentQuery = ''
        const sortBySanitized = 'vendor_profiles'
        const sortOrderSanitized = 'desc'

        if (activeState === CONSTANTS.STRINGS_LIST.ENABLED) {
            currentQuery = QUERY_PARAMS.VENDOR_QUERIES.fetchVendors
        } else if (activeState === CONSTANTS.STRINGS_LIST.DISABLED) {
            currentQuery = QUERY_PARAMS.VENDOR_QUERIES.fetchVendorsDisbaled
        }
        const result = await nextPageDataVendors(
            currentQuery,
            lastRow,
            sortBySanitized,
            sortOrderSanitized,
            rowsPerPage,
            search,
            activeState
        )

        const tempData = result.data
        if (tempData.length > 0) {
            setLastRow(result.last)
            setFirstRow(result.first)
            setData(tempData)
        }
        setLoading(false);
    }

    const lastPageFn = async () => {
        setLoading(true);
        setLoading(false)
        return
    }

    return (
        <div className="main-container">
            <AdminVendorSkeleton
                create={createVendor}
                setCreate={setCreateVendor}
                sortList={sortList}
                setSortList={setSortList}
                data={data}
                loading={loading}
                filterActive={filterActive}
                handleSearch={handleSearch}
                statusFields={statusFields}
                clearFilter={clearFilter}
                search={search}
                orderSizeActive={orderSizeActive}
                orderStatusActive={orderStatusActive}
                searchActive={searchActive}
                statusText={statusText}
                TblContainer={TblContainer}
                TblHead={TblHead}
                TblPagination={TblPagination}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                order={order}
                orderBy={orderBy}
                page={page}
                rowsPerPage={rowsPerPage}
                pages={pages}
                heads={heads}
                activeTab={activeState}
                listNames={listNames}
                editable={true}
                searchVisible={true}
                setActiveTab={setActiveState}
                dashBoardHeader={dashBoardHeader}
                search_change_handler={setSearchFn}
                searchData={searchData}
                firstPageFn={firstPageFn}
                prevPageFn={prevPageFn}
                nextPageFn={nextPageFn}
                lastPageFn={lastPageFn}
                handleHeaderClick={handleHeaderClick}
                setShowAddedData={setShowAddedData}
                showAddedData= {showAddedData}
                showPrevPgBtn={true}
                showNextPgBtn={true}
            />
        </div>
    )
}