import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../Context/UserContext';
import AdminVendorsListSortSkeleton from './Views/AdminVendorsListSortSkeleton';
import { CONSTANTS, QUERY_PARAMS } from '../../../Helpers/Constants';
import { fetchAllVendors } from '../../../Helpers/FetchHandlers';
import { updateVendorSortIndex } from '../../../Helpers/UpdateHandlers';
import Swal from "sweetalert2";
import { setItemsLocally } from '../../../LocalStorage/LocalStorage';

const heads = [
    {
        id: CONSTANTS.STRINGS_LIST.VENDOR, label: CONSTANTS.STRINGS_LIST.VENDOR, disableSort: true,
    }, {
        id: CONSTANTS.STRINGS_LIST.ORGANIZATION_NUMBER, label: CONSTANTS.STRINGS_LIST.ORGANIZATION_NUMBER, disableSort: true,
    }, {
        id: CONSTANTS.STRINGS_LIST.CONTACT_PERSON, label: CONSTANTS.STRINGS_LIST.CONTACT_PERSON, disableSort: true,
    }, {
        id: CONSTANTS.STRINGS_LIST.CONTACT_NUMBER, label: CONSTANTS.STRINGS_LIST.CONTACT_NUMBER, align: 'left', disableSort: true,
    }
]

const listNames = [
    'name',
    'organizationNumber',
    'contactPersonName',
    'contactNumber'
]

export default function AdminVendorsListSort({ sortList, setSortList }) {
    const { loading, setLoading } = useContext(UserContext);
    const [data, setData] = useState([]);
    let [sortedData, setSortedData] = useState({
        heads: listNames,
        rows: data
    });

    const defaultDragState = {
        row: -1,
        startPoint: null,
        direction: "", 
        dropIndex: -1 
    };
    const [dragState, setDragState] = useState({ ...defaultDragState });
    const [isSorted, setIsSorted] = useState(false);

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            let tempVendors = await fetchAllVendors(QUERY_PARAMS.VENDOR_QUERIES.fetchAllVendors);
            const tempData = tempVendors.data.map(vendor => {
                return {
                    ...vendor,
                }
            })
            getSortedData(tempData);
        }
        fetchData();
    }, [])

    const getSortedData = (tempData) => {
        let FinalArray = [];
        FinalArray = tempData.sort((a, b) => {
            return a.sortIndex - b.sortIndex
        })
        setData(FinalArray);
        setLoading(false);
    }

    // This needs to be added for optimization purpose, once the feature is approve

    // const getDifference = (sortedData) => {
    //     return data.filter((page1) => {
    //         return !sortedData.find((page2) => {
    //             return page1.sortedIndex === page2.sortedIndex
    //         })
    //     })
    // }

    const onDragEnd = (type, from, to, newData) => {
        setSortedData(newData);
        setData(newData._row);
        setIsSorted(true);
    }

    const saveSortedOrder = (e, sortedData) => {
        e.preventDefault();
        if(sortedData === undefined || sortedData === [] || sortedData === {}){
            Swal.fire({
                icon: 'error',
                title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                text: CONSTANTS.STRINGS_LIST.VENDOR_LIST_SORT_UPDATED_FAILED,
            })
        }
        else {
            setLoading(true);
            for (let i = 0; i < sortedData.length; i++) {
                updateVendorSortIndex(sortedData[i].dId, parseInt(i + 1))
                if (sortedData.length === parseInt(i + 1)) {
                    setLoading(false);
                    Swal.fire({
                        icon: 'success',
                        title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                        text: CONSTANTS.STRINGS_LIST.VENDOR_LIST_SORT_UPDATED_SUCCESS,
                    })
                }
            }
            setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.VENDORS);
            setSortList(false);    
            setSortedData({});
        }
    }

    return (
        <div className="main-container">
            <AdminVendorsListSortSkeleton
                data={data}
                loading={loading}
                heads={heads}
                listNames={listNames}
                sortList={sortList}
                setSortList={setSortList}
                saveSortedOrder={saveSortedOrder}
                dragState={dragState}
                setDragState={setDragState}
                defaultDragState={defaultDragState}
                sortedData={sortedData}
                onDragEnd={onDragEnd}
                isSorted={isSorted}
            />
        </div>
    )
}