import LoginInput from '../../../Components/Controls/LoginInput'
import { Images } from '../../../Assets/Images/Images'
import { CONSTANTS } from '../../../Helpers/Constants'
import { useState } from 'react'
import { forget_password_handler } from '../../../Helpers/AuthHandlers'
import { submitHandler } from '../../../Helpers/Helper'
import { useHistory } from 'react-router'

export default function PasswordReset() {
    const [email, setEmail] = useState('')
    const [error, setError] = useState({
        email: false,
    })
   const history = useHistory()
    const changeHandler = (e) => {
        const value = e.target.value
        if (e.target.type === 'email') {
            setEmail(value)
            setError({email: false })
        }
    }

    return (
        <>
            <div className='login-screen'>
                <div className='login-top-strip'></div>
                <div className='login-card'>
                    <div className='login-logo'>
                        <img src={Images.login_logo} alt='logo' />
                    </div>
                    <div className='login-heading'>{CONSTANTS.STRINGS_LIST.FORGET_PASSWORD}</div>
                    <form onSubmit={(e) => submitHandler(e)}>
                        <LoginInput
                            error={error.email}
                            errorMessage={CONSTANTS.STRINGS_LIST.EMAIL_ERROR_MESSAGE}
                            classes={`${error.email && "error-group"} input-group`}
                            label={CONSTANTS.STRINGS_LIST.EMAIL}
                            changeHandler={changeHandler}
                            value={email}
                            placeholder={CONSTANTS.STRINGS_LIST.TYPE_EMAIL}
                            type='email'
                        />

                        <div className='login-btn'>
                            <button type='submit' onClick={()=>forget_password_handler(email, setError, history)}>{CONSTANTS.STRINGS_LIST.SEND_RESET_EMAIL}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
