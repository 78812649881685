import PackageDetailComponent from "./PackageDetailComponent";

export default function PackageDetailTable({itemList, items, toggleActive, setToggleActive }) {
    return (
            <div className="package_table">
                <div className="package_container_table">
                    <PackageDetailComponent
                        items={items}
                        itemList={itemList}
                        toggleActive={toggleActive}
                        setToggleActive={setToggleActive}
                    />
                </div>
            </div>
    )
}