import { Images } from "../../../../../Assets/Images/Images"
import { CONSTANTS } from "../../../../../Helpers/Constants"
import { capitalizeFirstLetter, validateURL } from "../../../../../Helpers/Helper"

export default function ItemDetailsCard({ items }) {
    return (
        <div className="detail_card">
            <div className="image_div">
                <img src={(items.imageUrl && validateURL(items.imageUrl) ? items.imageUrl : Images.item_tunacado)} alt="item_image" />
            </div>
            <div className="item_detail">
                <div className="item_title">
                    {items.title}
                </div>
                <div className="item_description">
                    {items.description}
                </div>
                <div className="item_category">
                    {CONSTANTS.STRINGS_LIST.CATEGORY}:  {capitalizeFirstLetter(items.category)}
                </div>
            </div>
        </div>
    )
}