import TopStrip from "../../../../Components/TopStrip";
import ItemDetailsComponent from "./ItemDetailsComponent/ItemDetailsComponent";
import PackageDetailTable from "./PackageDetailTable";
import { CONSTANTS } from "../../../../Helpers/Constants";
import { Images } from "../../../../Assets/Images/Images";
import { getLocalItems, removeItemsLocally, setItemsLocally } from "../../../../LocalStorage/LocalStorage";
import { useEffect, useState } from "react";
import { getOrderPackageItems } from "../../../../Helpers/FetchHandlers";

export default function PackageDetailSkeleton({ propsList, toggleActive, setToggleActive }) {
    const { data, setShowPackageDetails, setShowOrderDetails, setShowPackages } = propsList
    const [items, setItems] = useState([]);
    const [itemList, setItemList] = useState([]);
    useEffect(() => {
        setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.PACKAGE_DETAILS)
        const itemsLocal = (getLocalItems(CONSTANTS.LOCAL_STORAGE_ITEMS.ITEM_KEY))
        const orderId = data.dId;
        setItems(itemsLocal)
        async function getData() {
            const data = await getOrderPackageItems(orderId, itemsLocal.packageId)
            setItemList(data)
        }
        getData()
    }, [])

    return (
        <>
            <TopStrip
                path={[CONSTANTS.STRINGS_LIST.ORDERS]}
                primaryBreadCrumb={() => {
                    setShowPackageDetails(false);
                    setShowOrderDetails(false);
                    setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.ORDERS);
                    removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.ITEM_KEY);
                    removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY);
                }}
                secondaryBreadCrumb={() => { setShowPackages(false) }}
                secondPath={[
                    {
                        label: [CONSTANTS.STRINGS_LIST.ORDER_DETAILS],
                        action: () => {
                            setShowPackageDetails(false);
                            setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.ORDER_DETAILS);
                            removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.ITEM_KEY);
                        }
                    },
                    {
                        label: [CONSTANTS.STRINGS_LIST.PACKAGE_DETAILS],
                        action: () => { }
                    }
                ]}
                image={Images.order_icon_black}
                buttons={[
                    {
                        btnText: [CONSTANTS.STRINGS_LIST.BACK],
                        btnAction: () => {
                            setShowPackageDetails(false);
                            setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.ORDERS);
                            removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.ITEM_KEY);
                        },
                        showBtn: true,
                    }
                ]}
            />
            <PackageDetailTable
                items={items}
                itemList={itemList}
                toggleActive={toggleActive}
                setToggleActive={setToggleActive}
            />
            <ItemDetailsComponent itemList={itemList} />
        </>
    )
}