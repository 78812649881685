import MenuItems from "./MenuItems/MenuItems"
import CreatePackageForm from "./CreatePackageForm"
import Loader from "../../../../Components/Loader"


export default function CreatePackageTable({ propsList, setCreate,packageCreated, setPackageCreated }) {
    const {loading} = propsList
    return (
        <div className="create_package_table">
            {loading && <Loader />}
            <>
                <div className="create_package_container">
                    <CreatePackageForm
                        propsList={propsList}
                        setCreate = {setCreate}
                        packageCreated={packageCreated}
                        setPackageCreated = {setPackageCreated}
                    />
                    <MenuItems
                        propsList={propsList}
                    />
                </div>
            </>
        </div>
    )
}