import { CONSTANTS } from '../../../../Helpers/Constants';
import Loader from '../../../../Components/Loader';
import Toggle from '../../../../Components/Controls/Toggle';
import Checkbox from "../../../../Components/Controls/Checkbox";
import Dropzone from '../../../../Components/DragAndDropInput';
import Select from 'react-select';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

export default function VendorSettingsView({ loading, formData, changes, setChanges, _nonBookableDates, changeHandler, onSaveSettings, handleDayClick }) {
    return (
        <div className="table">
            <div className="tableContainer">
                {loading && <Loader />}
                <div className="setting-table-selectors">
                    <div className="setting-table-content">
                        <div className='table-sides'>
                            <div className="settings-table-first">
                                <p>{CONSTANTS.STRINGS_LIST.DESCRIPTION} *</p>
                                <textarea
                                    placeholder={CONSTANTS.STRINGS_LIST.DESCRIPTION}
                                    value={formData.description}
                                    onChange={(e) => changeHandler(CONSTANTS.FIELDS.VENDOR_SETTINGS_KEYS.DESCRIPTION, e.target.value)}
                                />
                                <div className="settings-row">
                                    <div className="table-first-col">
                                        <p>{CONSTANTS.STRINGS_LIST.LOGO}</p>
                                        <Dropzone
                                            setChanges={setChanges}
                                            value={formData.logo}
                                            onChange={(e) => changeHandler(CONSTANTS.FIELDS.VENDOR_SETTINGS_KEYS.LOGO, e)}
                                        />
                                        <p>{CONSTANTS.STRINGS_LIST.CHOOSE_AREA} *</p>
                                        <Select
                                            options={formData.citiesAreasOptions}
                                            isMulti
                                            value={formData.locations}
                                            onChange={(e) => changeHandler(CONSTANTS.FIELDS.VENDOR_SETTINGS_KEYS.LOCATION, e)}
                                        />
                                        <div className="sub-first-col">
                                            <p>{CONSTANTS.STRINGS_LIST.SUPPLEMENTS}</p><br />
                                            <div className="col-last-first">
                                                <div className="last-width">
                                                    <Toggle
                                                        title={CONSTANTS.STRINGS_LIST.SUPPLEMENTS}
                                                        value={formData.isSupplementEnabled}
                                                        onChange={(e) => changeHandler(CONSTANTS.FIELDS.VENDOR_SETTINGS_KEYS.IS_SUPPLEMENT_ENABLED, e)}
                                                    />
                                                </div>
                                                <div className="last-width">
                                                    <div className="last-markup">
                                                        <label>{CONSTANTS.STRINGS_LIST.MARKUP}</label>
                                                        <input
                                                            type="number"
                                                            pattern="\d*"
                                                            value={formData.supplementMarkup}
                                                            onChange={(e) => changeHandler(CONSTANTS.FIELDS.VENDOR_SETTINGS_KEYS.SUPPLEMENT_MARKUP, e.target.value)}
                                                            disabled={!formData.isSupplementEnabled}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-second-col">
                                        <p>{CONSTANTS.STRINGS_LIST.CHOOSE_CITY} *</p>
                                        <Select
                                            options={formData.citiesOption}
                                            isMulti
                                            value={formData.city}
                                            onChange={(e) => changeHandler(CONSTANTS.FIELDS.VENDOR_SETTINGS_KEYS.CITY, e)}
                                        />
                                        <p>{CONSTANTS.STRINGS_LIST.BOOK_BEFORE_DAYS}</p>
                                        <input
                                            type="number"
                                            pattern="\d*"
                                            placeholder={CONSTANTS.STRINGS_LIST.DAYS}
                                            value={formData.bookBeforeDays}
                                            onChange={(e) => changeHandler(CONSTANTS.FIELDS.VENDOR_SETTINGS_KEYS.BOOK_BEFORE_DAYS, e.target.value)}
                                        />
                                        <p>{CONSTANTS.STRINGS_LIST.MAXIMUM_ORDER_CAPACITY}</p>
                                        <input
                                            type="number"
                                            pattern="\d*"
                                            placeholder={CONSTANTS.STRINGS_LIST.MAXIMUM_ORDER_CAPACITY.toLocaleLowerCase()}
                                            value={formData.maximumOrderCapacity}
                                            onChange={(e) => changeHandler(CONSTANTS.FIELDS.VENDOR_SETTINGS_KEYS.MAXIMUM_ORDER_CAPACITY, e.target.value)}
                                        />
                                        <div className="sub-first-col">
                                            <p>{CONSTANTS.STRINGS_LIST.MODE}</p>
                                            <div className="col-last-first">
                                                {loading ? '' :
                                                    <>
                                                        <div className="last-width">
                                                            <Checkbox
                                                                label={CONSTANTS.STRINGS_LIST.DELIVERY}
                                                                value={formData.isDeliveryEnabled}
                                                                onChange={(e) => changeHandler(CONSTANTS.FIELDS.VENDOR_SETTINGS_KEYS.IS_DELIVERY_ENABLED, e)}
                                                            />
                                                        </div>
                                                        <div className="last-width">
                                                            <Checkbox
                                                                label={CONSTANTS.STRINGS_LIST.COLLECTION}
                                                                value={formData.isPickupEnabled}
                                                                onChange={(e) => changeHandler(CONSTANTS.FIELDS.VENDOR_SETTINGS_KEYS.IS_PICKUP_ENABLED, e)}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-table-second">
                                <div className="table-calendar">
                                    <p>{CONSTANTS.STRINGS_LIST.NON_BOOKABLE_DAYS}</p><br />
                                    <DayPicker
                                        selectedDays={_nonBookableDates}
                                        onDayClick={handleDayClick}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='save-btn'>
                            <button disabled={!changes} onClick={(e) => onSaveSettings(e)}>{CONSTANTS.STRINGS_LIST.SAVE}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}