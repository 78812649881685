import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../../../Context/UserContext';
import VendorPacakgesListSortSkeleton from './Views/VendorPacakgesListSortSkeleton';
import { CONSTANTS, QUERY_PARAMS } from '../../../Helpers/Constants';
import { fetchAllPackages, fetchAllVendors, getUserData } from '../../../Helpers/FetchHandlers';
import moment from 'moment'
import { updatePackageSortIndex, updateVendorSortIndex } from '../../../Helpers/UpdateHandlers';
import Swal from "sweetalert2";
import { getLocalItems } from '../../../LocalStorage/LocalStorage';

const heads = [
    {
        id: 'img', label: CONSTANTS.STRINGS_LIST.IMAGE, disableSort: true, align: 'left',
    }, {
        id: 'title', label: CONSTANTS.STRINGS_LIST.TITLE, disableSort: true, align: 'left',
    },
    {
        id: 'pricePerUnit', label: CONSTANTS.STRINGS_LIST.PRICE_PER_UNIT, align: 'left', disableSort: true,
    }, {
        id: 'editable', label: '', align: 'left',
    }
]

const listNames = [
    "imageUrl",
    'title',
    'pricePerUnit',
]

export default function VendorPackagesListSort({ sortList, setSortList }) {
    const { loading, setLoading } = useContext(UserContext);
    const [data, setData] = useState([]);
    let [sortedData, setSortedData] = useState({
        heads: listNames,
        rows: data
    });

    const defaultDragState = {
        row: -1,
        startPoint: null,
        direction: "", 
        dropIndex: -1 
    };
    const [dragState, setDragState] = useState({ ...defaultDragState });
    const [isSorted, setIsSorted] = useState(false);


    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            const userData = await getUserData(getLocalItems('user').userId, 'vendor_users')
            const userIDRef = userData.vendorId
            let tempPackages = await fetchAllPackages(QUERY_PARAMS.PACKAGES_QUERIES.fetchAllPackages, userIDRef);
            const tempData = tempPackages.data.map(packages => {
                return {
                    ...packages,
                }
            })
            getSortedData(tempData);
        }
        fetchData();
    }, [])

    const getSortedData = (tempData) => {
        let FinalArray = [];
        FinalArray = tempData.sort((a, b) => {
            return a.sortIndex - b.sortIndex
        })
        setData(FinalArray);
        setLoading(false);
    }

    const onDragEnd = (type, from, to, newData) => {
        setSortedData(newData);
        setData(newData._row);
        setIsSorted(true);
    }

    const saveSortedOrder = (e, sortedData) => {
        e.preventDefault();
        if(sortedData === undefined || sortedData === [] || sortedData === {}){
            Swal.fire({
                icon: 'error',
                title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                text: CONSTANTS.STRINGS_LIST.VENDOR_LIST_SORT_UPDATED_FAILED,
            })
        } else {

            setLoading(true);
            for (let i = 0; i < sortedData.length; i++) {
                updatePackageSortIndex(sortedData[i].dId, parseInt(i + 1))
                if (sortedData.length === parseInt(i + 1)) {
                    setLoading(false);
                    Swal.fire({
                        icon: 'success',
                        title: CONSTANTS.STRINGS_LIST.EMPTY_STRING,
                        text: CONSTANTS.STRINGS_LIST.PACKAGE_LIST_SORT_UPDATED_SUCCESS,
                    })
                }
            }
            setSortList(false)
            setSortedData({})
        }
    }

    return (
        <div className="main-container">
            <VendorPacakgesListSortSkeleton
                data={data}
                setData={setData}
                loading={loading}
                heads={heads}
                listNames={listNames}
                sortList={sortList}
                setSortList={setSortList}
                saveSortedOrder={saveSortedOrder}
                dragState={dragState}
                setDragState={setDragState}
                defaultDragState={defaultDragState}
                sortedData= {sortedData}
                onDragEnd={onDragEnd}
                isSorted={isSorted}
            />
        </div>
    )
}