import { InputAdornment, Toolbar } from '@material-ui/core'
import React from 'react'
import Controls from '../Controls/Controls'
import { Images } from '../../Assets/Images/Images'
import { CONSTANTS } from '../../Helpers/Constants'
import { capitalizeString, isEmpty, isString, validateURL } from '../../Helpers/Helper'
import { getLocalItems, setItemsLocally } from '../../LocalStorage/LocalStorage'
import { fetchingData, nextPage } from '../../Containers/Admin/AdminOrders/ComponentHelper/ComponentHelper'
// import TopStrip from '../../../Components/TopStrip/TopStrip'

export default function TableView({
    propsList
}) {
    const tableClickHandler = (row) => {
        const currentMode = getLocalItems(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY)
        if (currentMode === CONSTANTS.STRINGS_LIST.ORDERS) {
            setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY, row)
            propsList.setShowOrderDetails(true)
        }
    }
    //temporary solution
    let statusField;
    return (
        <div>
            <Toolbar className="tool-bar">
                <div className="date-order-side">
                    {propsList.filterVisible && (
                        <>
                            <div className="datePicker">
                                <Controls.DatePicker
                                    value={propsList.startDate}
                                    disabled={!propsList.dateActive}
                                    className="date-picker datePicker"
                                    maxDate={isEmpty(propsList.endDate) ? undefined : propsList.endDate}
                                    placeholder={CONSTANTS.STRINGS_LIST.START_DATE}
                                    onChange={(e) => { propsList.handleStartDateFilter(e) }}
                                />
                            </div>
                            <div className="datePicker">
                                <Controls.DatePicker
                                    value={propsList.endDate}
                                    disabled={!propsList.dateActive}
                                    className="date-picker datePicker"
                                    minDate={isEmpty(propsList.startDate) ? undefined : propsList.startDate}
                                    placeholder={CONSTANTS.STRINGS_LIST.END_DATE}
                                    onChange={(e) => { propsList.handleEndDateFilter(e) }}
                                />
                            </div>

                            <Controls.Input
                                value={propsList.order}
                                disabled={!propsList.orderSizeActive}
                                placeholder={CONSTANTS.STRINGS_LIST.ORDER_SIZE}
                                className="searchBox order"
                                InputProps={{
                                    endAdornment: (
                                        <>
                                            <InputAdornment position="end">
                                                {propsList.order !== '' && (
                                                    <button className="searchButton" onClick={() => propsList.handleOrderFn(propsList.order)}>
                                                        <img src={Images.search_icon} alt="search" />
                                                    </button>
                                                )}
                                            </InputAdornment>
                                        </>
                                    )
                                }}
                                onKeyPress={(e) => {
                                    propsList.handleOrderSize(e)
                                    propsList.setOrder(e.target.value.toLocaleLowerCase())
                                }}
                                onChange={(e) => { propsList.setOrder(e.target.value) }}
                            />
                            {propsList.activeTab === 'In process' && (
                                <select
                                    disabled={!propsList.orderStatusActive}
                                    onChange={(e) => { propsList.handleOrderStatus(e); statusField = e.target.value }}
                                    placeholder={CONSTANTS.STRINGS_LIST.ORDER_STATUS}
                                    value={propsList.showFirstStatus ? propsList.orderStatus : statusField}
                                    className={`selectBox width137 ${propsList.statusText === 'Order Status' ? 'disabled' : ''}`}
                                >
                                    {propsList.showFirstStatus && <option value="">{propsList.firstStatusOption}</option>}
                                    {propsList.statusFields.map((item, index) => {
                                        return (
                                            <option key={'ine456' + index} value={item}>{item}</option>
                                        )
                                    }
                                    )}
                                </select>
                            )}
                        </>
                    )}
                </div>
                <div className="search">
                    {propsList.searchVisible && (
                        <>
                            {propsList.searchOptionVisible &&
                                <select
                                    disabled={!propsList.searchActive}
                                    onChange={(e) => {
                                        propsList.setSearchOptions(e.target.value);
                                    }}
                                    placeholder={CONSTANTS.STRINGS_LIST.ORDER_STATUS}
                                    value={propsList.searchOptions}
                                    className={`selectBox ${propsList.searchActive === 'Order Status' ? 'disabled' : ''}`}
                                >
                                    <option disabled>{propsList.searchOptions}</option>

                                    {propsList.searchListNames && propsList.searchListNames.map((item, index) => {
                                        return (
                                            <option key={'ine654' + index} value={item}>{item}</option>
                                        )
                                    }
                                    )}
                                </select>
                            }
                            <Controls.Input
                                disabled={(!propsList.searchActive || propsList.searchOptions === 'Select Field')}
                                placeholder={propsList.searchPlaceholder ?? CONSTANTS.STRINGS_LIST.SEARCH}
                                className="searchBox"
                                value={propsList.search}
                                InputProps={{
                                    startAdornment: (
                                        <>
                                            <InputAdornment position="start">
                                                <img src={Images.search_icon} alt="search" />
                                            </InputAdornment>
                                        </>
                                    ),
                                    endAdornment: (
                                        <>
                                            <InputAdornment position="end">
                                                {propsList.search !== '' && (
                                                    <button className="searchButton" onClick={() => propsList.searchData(propsList.search)}>
                                                        <img src={Images.search_icon} alt="search" />
                                                    </button>
                                                )}
                                            </InputAdornment>
                                        </>
                                    )
                                }}
                                onChange={(e) => propsList.search_change_handler(e)}
                                onKeyPress={(e) => propsList.handleSearch(e)}
                            />
                        </>
                    )}
                </div>

            </Toolbar>
            <div className="overFlowAuto">
                <div className="order-card">
                    <div className="package-list">
                        <div className="package-table">
                            <table className="generalTable">
                                <thead>
                                    <tr>
                                        {propsList?.heads?.map((item, index) => {
                                            return (
                                                <th key={'ine321' + index}>{item.label}{
                                                    item.sortable && item.sortable && (
                                                        <span className="sort-icon">
                                                            <span className='up' onClick={(e) => propsList.sortAsc(e, item.label)}>
                                                                {propsList.sortBy === item.label && propsList.sortOrder === CONSTANTS.STRINGS_LIST.ASSCENDING ?
                                                                    <img src={Images.sortUpIcon_black} alt="up" />
                                                                    :
                                                                    <img src={Images.sortUpIcon_white} alt="up" />
                                                                }
                                                            </span>
                                                            <span className="down" onClick={(e) => propsList.sortDesc(e, item.label)}>
                                                                {propsList.sortBy === item.label && propsList.sortOrder === CONSTANTS.STRINGS_LIST.DESCENDING ?
                                                                    <img src={Images.sortDownIcon_black} alt="up" />
                                                                    :
                                                                    <img src={Images.sortDownIcon_white} alt="up" />
                                                                }
                                                            </span>
                                                        </span>
                                                    )
                                                }</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {propsList.data?.length > 0 ? propsList.data.map((item, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                className="dataRow"
                                                onClick={() => { tableClickHandler(item) }}
                                            >
                                                {propsList.listNames.map((head, index1) => {
                                                    return (
                                                        <>
                                                            <td
                                                                className={`
                                                                ${isString(item[head]) && item[head]?.toLowerCase() === 'accepted' ? 'acceptedText' : item[head] === 'rejected' ?
                                                                        'rejectedText' : ''}`}
                                                                key={index + 'ine321' + index1}
                                                            >
                                                                {head === 'imageUrl' ? (
                                                                    <img className="tableImage" src={validateURL(item[head]) ? item[head] : Images.main_detail_img} target="_blank" rel="noopener noreferrer" alt='img' />
                                                                ) : (
                                                                    <>{
                                                                        isString(item[head]) ?
                                                                            item[head]?.toLowerCase() === ('accepted' || 'rejected' || 'cancelled') ?
                                                                                capitalizeString(item[head]) :
                                                                                item[head]
                                                                            :
                                                                            item[head]
                                                                    }
                                                                    </>
                                                                )}
                                                            </td>
                                                        </>
                                                    )
                                                })}

                                                {propsList.editable && (
                                                    <td>
                                                        <img src={Images.edit_icon} alt="edit" onClick={() => {
                                                            setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY, item)
                                                            propsList.setCreate(!propsList.create)
                                                        }} />
                                                    </td>
                                                )}
                                            </tr>
                                        )
                                    }) : (
                                        <tr className="noData">
                                            <td colSpan="6">No Data</td>
                                        </tr>
                                    )}
                                </tbody>

                            </table>

                        </div>
                    </div>
                </div >
            </div>

            {/* {!propsList.pagination && */}
            <div className='pagination'>
                {propsList.showFirstPgBtn ? (
                    <button onClick={() => propsList.firstPageFn()}><img src={Images.firstPageArrow} alt="first_page_arrow" /></button>
                ) : ''}

                <button
                    className={propsList.showPrevPgBtn ? '' : 'btnDisabled'}
                    onClick={() => (propsList.showPrevPgBtn && propsList.prevPageFn())}
                >
                    <img src={propsList.showPrevPgBtn ? Images.prevPageArrow : Images.prevPageArrowDisabled} alt="prev_page_arrow" />
                </button>

                <button
                    className={propsList.showNextPgBtn ? '' : 'btnDisabled'}
                    onClick={() => (propsList.showNextPgBtn && propsList.nextPageFn())}
                >
                    <img src={propsList.showNextPgBtn ? Images.nextPageArrow : Images.nextPageArrowDisabled} alt="prev_page_arrow" />
                </button>

                {propsList.showLastPgBtn ? (
                    <button onClick={() => propsList.lastPageFn()}><img src={Images.lastPageArrow} alt="last_page_arrow" /></button>
                ) : ''}

            </div>
        </div>
    )
}