import { useEffect, useState } from "react"
import { CONSTANTS } from "../../../../../Helpers/Constants"
import ItemDetailsCard from "../ItemDetailsComponent/ItemDetailsCard"
import { menuItem } from "../../../../../Helpers/WriteHandlers"
import { removeItem } from "../ComponentHelper/ComponentHandler"
import { editMenuItem } from "../ComponentHelper/ComponentHandler"
import { offsetIndex } from "../../../../../Helpers/FetchHandlers"

export default function MenuItems({ propsList }) {
    const { setShowAddItem, packageItemList, setPackageItemList, edit, setMenuItemEdited, setChanges, deletedMenuItems, setDeletedMenuItems, itemData, setItemData, packageItemDefault, selectedMenuItemEdited, setSelectedMenuItemEdited, onDragEnd, onDragEnter, onDragStart, dragState } = propsList

    let _row = packageItemList
    if (dragState.direction === "row") {
        _row = offsetIndex(dragState.row, dragState.dropIndex, _row);
    }
    

    return (
        <div
        onDragEnd={(e) => onDragEnd(_row)}
            className="menu-items">
            <div className="heading-title">
                <div className="heading-section">
                    <p className="heading">{CONSTANTS.STRINGS_LIST.MENU_ITEMS}</p>
                    {packageItemList.length == 0 && <p className="add-first-title">{CONSTANTS.STRINGS_LIST.ADD_FIRST_ITEM}</p>}
                </div>
                <div className="add-menu-item">
                    <button id={CONSTANTS.FIELDS.PACKAGE_ITEMS_KEYS.ADD_ITEM_OPEN} onClick={
                        () => {
                            setChanges(true)
                            setShowAddItem(true)
                            setItemData(packageItemDefault)
                            setSelectedMenuItemEdited(false)
                            edit && setMenuItemEdited(true)
                        }
                    }>{CONSTANTS.STRINGS_LIST.ADD_ITEM}</button>
                </div>
            </div>
            <div className="items-list">
                {packageItemList.map((item, index) => {
                    return (
                        <ItemDetailsCard
                            onDragEnter={onDragEnter}
                            onDragStart={onDragStart}
                            onDragEnd={onDragEnd}
                            index={index}
                            key={index}
                            items={item}
                            removeItem={() => removeItem(packageItemList, setPackageItemList, index, edit?.dId, item.id, setMenuItemEdited, setChanges, deletedMenuItems, setDeletedMenuItems)}
                            editMenuItem={() => editMenuItem(item, setShowAddItem, index, itemData, setItemData, setSelectedMenuItemEdited, setMenuItemEdited)}
                        />
                    )
                })
                }
            </div>
        </div>
    )
}