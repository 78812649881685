
import CreateVendorForm from "./CreateVendorForm"

export default function CreateVendorTable({ propList }) {
    return (
        <div className="table">
            <div className="tableContainer">
                <CreateVendorForm
                    propList = {propList}
                />
            </div>
        </div>
    )
}