
import { changeHandler } from './ComponentHelper';
import { CONSTANTS } from "../../../../Helpers/Constants"
import { create_vendor_handler } from '../../../../Helpers/AuthHandlers';
import { organizationNumberHandler } from './ComponentHelper';
import { submitHandler, phoneValidation, edit_vendor_handler, emailValidation, capitalizeFirstLetter } from '../../../../Helpers/Helper';
import FormInputs from '../../../../Components/Controls/FormInputs';
import Loader from '../../../../Components/Loader'
import Select from "react-select"

export default function CreateVendorForm({ propList }) {
    const { cancelFunc, setChanges, changes, edit, setShowAddedData, showAddedData, loading, setLoading, vendorData, setVendorData, password, setPassword, confirmPassword, setConfirmPassword, error, setError, phoneError, setPhoneError, organizationError, setOrganizationError, stockholmAreas, emailError, setEmailError } = propList

    const for_handler = {
        vendorData,
        setError,
        password,
        confirmPassword,
        cancelFunc,
        organizationError,
        setOrganizationError,
        edit,
        phoneError,
        setPhoneError,
        setLoading,
        setShowAddedData,
        showAddedData,
        setChanges,
        emailError,
        setEmailError
    }

    return (
        <>
            {loading && <Loader />}
            <form onSubmit={e => submitHandler(e)} id="my-form" autoComplete='off'>
                <div className="create-vendor-form">
                    <div className='company-info'>
                        <div className="form-heading"> {CONSTANTS.STRINGS_LIST.COMPANY_INFORMATION} </div>
                        <div className='input-group'>
                            <FormInputs
                                title={CONSTANTS.STRINGS_LIST.VENDOR_NAME}
                                name={CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.NAME}
                                value={vendorData[CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.NAME]}
                                placeholder={CONSTANTS.STRINGS_LIST.VENDOR_NAME.toLocaleLowerCase()}
                                handlerFunction={(event) => changeHandler(event, setChanges, setVendorData, vendorData)}
                                required="required"
                            />

                            <FormInputs
                                title={CONSTANTS.STRINGS_LIST.ORGANIZATION_NUMBER}
                                name={CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.ORGANIZATION_NUMBER}
                                value={vendorData[CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.ORGANIZATION_NUMBER]}
                                placeholder={CONSTANTS.STRINGS_LIST.ORGANIZATION_NUMBER.toLocaleLowerCase()}
                                handlerFunction={(event) => changeHandler(event, setChanges, setVendorData, vendorData)}
                                onBlur={(event) => organizationNumberHandler(event, setOrganizationError)}
                                error={organizationError}
                                errorMessage={CONSTANTS.STRINGS_LIST.ORGANIZATION_NUMBER_ERROR}
                                disabled={edit && true}
                                required="required"
                            />

                            <FormInputs
                                title={CONSTANTS.STRINGS_LIST.CITY}
                                name={CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.VENDOR_CITY}
                                value={vendorData[CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.VENDOR_CITY]}
                                placeholder={CONSTANTS.STRINGS_LIST.CITY.toLocaleLowerCase()}
                                handlerFunction={(event) => changeHandler(event, setChanges, setVendorData, vendorData)}
                                required="required"
                                disabled={true}
                            />
                        </div>

                        <div className='input-group'>
                            <FormInputs
                                title={CONSTANTS.STRINGS_LIST.ADDRESS}
                                name={CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.ADDRESS}
                                value={vendorData[CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.ADDRESS]}
                                handlerFunction={(event) => changeHandler(event, setChanges, setVendorData, vendorData)}
                                placeholder={CONSTANTS.STRINGS_LIST.COMPLETE_ADDRESS}
                                required="required"
                            />

                            <FormInputs
                                title={CONSTANTS.STRINGS_LIST.POSTAL_CODE}
                                name={CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.POSTAL_CODE}
                                value={vendorData[CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.POSTAL_CODE]}
                                handlerFunction={(event) => changeHandler(event, setChanges, setVendorData, vendorData)}
                                placeholder={CONSTANTS.STRINGS_LIST.POSTAL_CODE.toLocaleLowerCase()}
                                required="required"
                            />
                            <div className="area-select">
                                <p>{CONSTANTS.STRINGS_LIST.AREA}</p>
                                <Select options={stockholmAreas}
                                    placeholder={CONSTANTS.STRINGS_LIST.AREA.toLocaleLowerCase()}
                                    value={capitalizeFirstLetter({ ...vendorData[CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.VENDOR_AREA], label: capitalizeFirstLetter(vendorData[CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.VENDOR_AREA].label) })}
                                    onChange={(event) => changeHandler(event, setChanges, setVendorData, vendorData, "areas")}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='contact-person'>
                        <div className="form-heading"> {CONSTANTS.STRINGS_LIST.CONTACT_PERSON} </div>

                        <div className='input-group'>
                            <FormInputs
                                title={CONSTANTS.STRINGS_LIST.NAME}
                                name={CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.CONTACT_PERSON_NAME}
                                value={vendorData[CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.CONTACT_PERSON_NAME]}
                                handlerFunction={(event) => changeHandler(event, setChanges, setVendorData, vendorData)}
                                placeholder={CONSTANTS.STRINGS_LIST.NAME.toLocaleLowerCase()}
                                required="required"
                            />

                            <FormInputs
                                title={CONSTANTS.STRINGS_LIST.EMAIL_ADDRESS}
                                name={CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.EMAIL_ADDRESS}
                                value={vendorData[CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.EMAIL_ADDRESS]}
                                handlerFunction={(event) => changeHandler(event, setChanges, setVendorData, vendorData)}
                                placeholder={CONSTANTS.STRINGS_LIST.EMAIL.toLocaleLowerCase()}
                                type="email"
                                error={emailError}
                                errorMessage={CONSTANTS.STRINGS_LIST.EMAIL_ERROR_MESSAGE}
                                onBlur={() => emailValidation(vendorData[CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.EMAIL_ADDRESS], setEmailError)}
                                disabled={edit && true}
                                required="required"
                            />

                            <div className='form-inputs'>
                                <p>{CONSTANTS.STRINGS_LIST.STATUS}</p>
                                <select id="status"
                                    name={CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.STATUS}
                                    value={vendorData[CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.STATUS]}
                                    onChange={(event) => changeHandler(event, setChanges, setVendorData, vendorData)}
                                    className='minimal'
                                >
                                    <option id="status_enabled" value='enabled'>{CONSTANTS.STRINGS_LIST.ENABLED}</option>
                                    <option id="status_disabled" value='disabled'>{CONSTANTS.STRINGS_LIST.DISABLED}</option>
                                </select>
                            </div>
                        </div>

                        <div className='input-group'>
                            <FormInputs
                                title={CONSTANTS.STRINGS_LIST.TELEPHONE_NUMBER}
                                name={CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.CONTACT_NUMBER}
                                value={vendorData[CONSTANTS.FIELDS.CREATE_VENDOR_KEYS.CONTACT_NUMBER]}
                                handlerFunction={(event) => changeHandler(event, setChanges, setVendorData, vendorData)}
                                placeholder="+46123456789"
                                error={phoneError}
                                onBlur={() => phoneValidation(vendorData.contactNumber, setPhoneError)}
                                errorMessage={CONSTANTS.STRINGS_LIST.PHONE_VALIDATION}
                                type="text"
                                required="required"
                            />
                        </div>
                    </div>

                    {!edit && <div className='form-user'>
                        <div className="form-heading"> {CONSTANTS.STRINGS_LIST.USER} </div>
                        <div className='input-group'>
                            <FormInputs
                                title={CONSTANTS.STRINGS_LIST.CREATE_PASSWORD}
                                name="password"
                                value={password}
                                handlerFunction={(e) => setPassword(e.target.value)}
                                placeholder={CONSTANTS.STRINGS_LIST.PASSWORD_LENGTH.toLocaleLowerCase()}
                                type="password"
                                required="required"
                            />
                            <FormInputs
                                title={CONSTANTS.STRINGS_LIST.CONFIRM_PASSWORD}
                                name="password2"
                                value={confirmPassword}
                                handlerFunction={(e) => setConfirmPassword(e.target.value)}
                                placeholder={CONSTANTS.STRINGS_LIST.CONFIRM_PASSWORD.toLocaleLowerCase()}
                                type="password"
                                error={error}
                                errorMessage={CONSTANTS.STRINGS_LIST.PASSWORDS_DO_NOT_MATCH}
                                required="required"
                            />
                        </div>

                    </div>}
                    <div className='input-group'>
                        <button id="button_submit"
                            type='submit'
                            disabled={!changes}
                            onClick={
                                edit ?
                                    () => edit_vendor_handler(for_handler)
                                    :
                                    () => create_vendor_handler(for_handler)}
                        >
                            {edit ? CONSTANTS.STRINGS_LIST.SAVE : CONSTANTS.STRINGS_LIST.CREATE_VENDOR}
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}
