export const Images = {
    logo: require('./logo.svg').default,
    login_logo: require('./login-logo.svg').default,
    order_icon_black: require('./Icons/CalendarBlack.svg').default,
    order_icon_white: require('./Icons/Calendar-White.svg').default,
    order_icon_unfill: require('./Icons/Calendar.svg').default,
    vendor_black: require('./Icons/Vendors.svg').default,
    vendor_white: require('./Icons/Vendors-White.svg').default,
    faq_black: require('./Icons/FAQ.svg').default,
    faq_white: require('./Icons/FAQ-White.svg').default,
    setting_black: require('./Icons/Settings.svg').default,
    setting_white: require('./Icons/Settings-White.svg').default,
    search_icon: require('./Icons/search_ico.svg').default,
    userMask: require('./Icons/Mask Group.png').default,
    order_icon: require('./Icons/Calendar.svg').default,
    vendor_icon: require('./Icons/Vendors.svg').default,
    faq_icon: require('./Icons/FAQ.svg').default,
    setting_icon: require('./Icons/Settings.svg').default,
    edit_icon: require('./Icons/Edit-Icon.svg').default,
    user_logout: require('./Icons/user-logout.svg').default,
    hamburger_menu: require('./Icons/hamburger-menu.svg').default,
    firstPageArrow: require('./Icons/firstPageArrow.svg').default,
    lastPageArrow: require('./Icons/lastPageArrow.svg').default,
    nextPageArrow: require('./Icons/nextPageArrow.svg').default,
    nextPageArrowDisabled: require('./Icons/nextPageArrowDisabled.svg').default,
    prevPageArrow: require('./Icons/prevPageArrow.svg').default,
    prevPageArrowDisabled: require('./Icons/prevPageArrowDisabled.svg').default,
    close_icon: require('./Icons/close_icon.svg').default,
    package_icon_black: require('./Icons/packages_black.svg').default,
    package_icon_white: require('./Icons/packages_white.svg').default,
    main_detail_img: require("./main_detail_image.png").default,
    item_tunacado: require("./Tunacado.png").default,
    add_image_icon: require('./Icons/add-image-icon.svg').default,
    sortUpIcon_black: require('./Icons/Sort-Black-Up.svg').default,
    sortUpIcon_white: require('./Icons/Sort-White-Up.svg').default,
    sortDownIcon_black: require('./Icons/Sort-Black-Down.svg').default,
    sortDownIcon_white: require('./Icons/Sort-White-Down.svg').default,
    default_image: require("./image.png").default
}