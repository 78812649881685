import React, { useEffect, useContext } from 'react';
import { UserContext } from '../../../Context/UserContext';
import { CONSTANTS, QUERY_PARAMS, TABLES, LIST } from '../../../Helpers/Constants'
import moment from 'moment'
import { capitalizeFirstLetter, trim } from '../../../Helpers/Helper'
import {
    checkNextPage,
    checkPreviousPage,
    getSystemConfig,
    getUserData,
    searchFetch,
    sortFn,
} from '../../../Helpers/FetchHandlers'
import swal from 'sweetalert';
import { getLocalItems, setItemsLocally } from '../../../LocalStorage/LocalStorage'
import VendorOrderSkeleton from './Views/VendorOrdersSkeleton';
import { fetchingData, sanitizeResult, fiveFieldQuery, fourFieldQuery, threeFieldQuery, twoFieldQuery, search_change_handler, handleSearch, fieldName, oneFieldQuery, sortFnWithSearch, nextPageData, prevPageData } from './ComponentHelper/ComponentHelper';

export default function DashboardVendor() {
    const heads = TABLES.VENDOR_ORDER_HEADS
    const statusFields = TABLES.ORDER_STATUS_FIELDS
    const dashBoardHeader = TABLES.ORDER_DASHBOARD_HEADERS
    const listNames = TABLES.VENDOR_ORDER_LISTNAMES
    const searchListNames = LIST.VENDOR_ORDER_SEARCH_LIST
    const [data, setData] = React.useState([])
    const { loading, setLoading } = useContext(UserContext);
    const [filterActive, setFilterActive] = React.useState(false)
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)
    const [endDateSelected, setEndDateSelected] = React.useState('')

    const [order, setOrder] = React.useState('');
    const [orderSizeSelected, setOrderSizeSelected] = React.useState('')
    const [search, setSearch] = React.useState('');
    const [dateActive, setDateActive] = React.useState(true);
    const [startDateFilterActive, setStartDateFilterActive] = React.useState(false)
    const [endDateFilterActive, setEndDateFilterActive] = React.useState(false)
    const [startDateSelected, setStartDateSelected] = React.useState('')
    const [orderSizeActive, setOrderSizeActive] = React.useState(true);
    const [orderSizeFilterActive, setOrderSizeFilterActive] = React.useState(false)
    const [orderStatusActive, setOrderStatusActive] = React.useState(true);
    const [orderStatusFilterActive, setOrderStatusFilterActive] = React.useState(false)
    const [searchActive, setSearchActive] = React.useState(true);
    const searchFieldActive = true;
    const [searchIsActive, setSearchIsActive] = React.useState(false)
    const [orderStatus, setOrderStatus] = React.useState('');
    const [orderStatusSelected, setOrderStatusSelected] = React.useState('')
    const statusText = 'Order Status';
    const [searchOptions, setSearchOptions] = React.useState(CONSTANTS.STRINGS_LIST.SELECT_FIELD); //will hold, search field
    const [searchFieldCurrent, setSearchFieldCurrent] = React.useState('')
    const [showFirstStatus, setShowFirstStatus] = React.useState(true);
    const firstStatusOption = CONSTANTS.STRINGS_LIST.ORDER_STATUS;
    const [orderBy, setOrderBy] = React.useState();


    const [activeTab, setActiveTab] = React.useState(CONSTANTS.STRINGS_LIST.IN_PROCESS);
    const [orderAccepted, setOrderAccepted] = React.useState(true);
    const [lastRow, setLastRow] = React.useState([]);
    const [firstRow, setFirstRow] = React.useState([]);
    const [showOrderDetails, setShowOrderDetails] = React.useState(false)
    const [showPackageDetails, setShowPackageDetails] = React.useState(false)
    const [sortOrder, setSortOrder] = React.useState(CONSTANTS.STRINGS_LIST.DESCENDING)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [showPrevPgBtn, setShowPrevPgBtn] = React.useState(false)
    const [showFirstPgBtn, setShowFirstPgBtn] = React.useState(false)
    const [showLastPgBtn, setShowLastPgBtn] = React.useState(false)
    const [showNextPgBtn, setShowNextPgBtn] = React.useState(false)

    const [sortBy, setSortBy] = React.useState(CONSTANTS.STRINGS_LIST.ID)
    const [sortingOrder, setSortingOrder] = React.useState("desc")
    const [orderByField, setOrderByField] = React.useState(CONSTANTS.FIELDS.CUSTOMER_ORDERS.ORDER_ID)
    const [vendorId, setVendorId] = React.useState('')
    const [systemConfig, setSystemConfig] = React.useState('') 

    useEffect(async () => {
        let config = await getSystemConfig("REp6lXl9OP6NmJUh6KrY", "system_config")
        setSystemConfig(config)
    }, [])

    useEffect(async () => {
        setLoading(true)
        const userData = await getUserData(getLocalItems('user').userId, 'vendor_users')
        const userId = userData.vendorId
        setVendorId(userId)
        setLoading(true);
        if (getLocalItems('mode') === CONSTANTS.STRINGS_LIST.PACKAGE_DETAILS) {
            setShowPackageDetails(true)
        } else if (getLocalItems('mode') === CONSTANTS.STRINGS_LIST.ORDER_DETAILS) {
            setShowOrderDetails(true)
        } else {
            setItemsLocally('mode', CONSTANTS.STRINGS_LIST.ORDERS)
        }
        fetchingData(activeTab, setFirstRow, setLastRow, setData, setLoading, orderByField, sortingOrder, userId)
        setLoading(false);
    }, [activeTab, orderAccepted])

    const searchData = async (input) => {
        let search = trim(input)
        if (searchOptions === CONSTANTS.STRINGS_LIST.COMPANY) {
            fetchingData(activeTab, setFirstRow, setLastRow, setData, setLoading, orderByField, sortingOrder, vendorId, search, CONSTANTS.FIELDS.CUSTOMER_ORDERS.CUSTOMER_COMPANY)
        } else if (searchOptions === CONSTANTS.STRINGS_LIST.VENDOR) {
            fetchingData(activeTab, setFirstRow, setLastRow, setData, setLoading, orderByField, sortingOrder, vendorId, search, CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_NAME)
        } else if (searchOptions === CONSTANTS.STRINGS_LIST.ID) {
            fetchingData(activeTab, setFirstRow, setLastRow, setData, setLoading, orderByField, sortingOrder, vendorId, search, CONSTANTS.FIELDS.CUSTOMER_ORDERS.ORDER_ID)
        } else {
            swal(CONSTANTS.STRINGS_LIST.SELECT_TO_SEARCH);
        }
        let emptyInput = input.match(/\w+/g)
        if (input === "" || !emptyInput) {
            swal(CONSTANTS.STRINGS_LIST.ENTER_TO_SEARCH);
        } else {
            setFilterActive(true)
            setSearchActive(true)
            setSearchIsActive(true)

            setDateActive(false)
            setOrderSizeActive(false)
            setOrderStatusActive(false)
        }
    }

    const handleEndDateFilter = async e => {
        const date = new Date(e.target.value).getDate()
        const month = new Date(e.target.value).getMonth()
        const year = new Date(e.target.value).getFullYear()
        let finalEndDate = new Date(year, month, date, 23, 59, 59)
        const endDateSelected = finalEndDate
        
        let startLimit;
        if (startDate === null) {
            let finalStartDate = new Date(year, month, date)
            startLimit = finalStartDate
            setStartDate(startLimit)
        } else {
            startLimit = startDate
        }
        fetchingData(activeTab, setFirstRow, setLastRow, setData, setLoading, orderByField, sortingOrder, vendorId, search, searchActive, true, startLimit, endDateSelected, orderSizeFilterActive, order, orderStatusFilterActive, orderStatus)
        setEndDate(endDateSelected)
        setFilterActive(true)
        setStartDateFilterActive(true)

        setSearchActive(false)
    }

    const handleStartDateFilter = async e => {
        const date = new Date(e.target.value).getDate()
        const month = new Date(e.target.value).getMonth()
        const year = new Date(e.target.value).getFullYear()
        let finalStartDate = new Date(year, month, date)

        let endLimit;
        if (endDate === null) {
            let finalEndDate = new Date(year, month, date, 23, 59, 59)
            endLimit = finalEndDate
            setEndDate(endLimit)
        } else {
            endLimit = endDate
        }
        fetchingData(activeTab, setFirstRow, setLastRow, setData, setLoading, orderByField, sortingOrder, vendorId, search, searchActive, true, finalStartDate, endLimit, orderSizeFilterActive, order, orderStatusFilterActive, orderStatus)
        setStartDate(finalStartDate)
        setStartDateFilterActive(true)
        setFilterActive(true)

        setSearchActive(false)
    }

    const handleOrderFn = async (orderSize) => {
        let emptyInput = orderSize.match(/\w+/g)
        if (orderSize === "" || !emptyInput) {
            swal(CONSTANTS.STRINGS_LIST.ENTER_ORDER_SIZE);
            setOrderSizeFilterActive(false)
        } else {
            const orderSizeNumber = Number(orderSize)
            fetchingData(activeTab, setFirstRow, setLastRow, setData, setLoading, orderByField, sortingOrder, vendorId, search, searchActive, startDateFilterActive, startDate, endDate, true, orderSizeNumber, orderStatusFilterActive, orderStatus)
            setFilterActive(true)
            setSearchActive(false)
            setOrderSizeFilterActive(true)
        }
    }

    //temprory work by Madani Raza for Empty OrderSize Value
    useEffect(() => {
        let emptyInput = order.match(/\w+/g)
        if (order === "" || !emptyInput) {
            setOrderSizeFilterActive(false)
        }
    }, [order])
    const handleOrderSize = async e => {
        let value = e.target.value;
        if (e.key === 'Enter') {
            handleOrderFn(value);
        }
    }

    const handleOrderStatus = async e => {
        setLoading(true)
        const value = e.target.value;
        const valueSanitized = value.toLocaleLowerCase()
        setOrderStatus(valueSanitized)
        setOrderStatusFilterActive(true)
        setOrderStatus(valueSanitized)
        fetchingData(activeTab, setFirstRow, setLastRow, setData, setLoading, orderByField, sortingOrder, vendorId, search, searchActive, startDateFilterActive, startDate, endDate, orderSizeFilterActive, order, true, valueSanitized)
        setFilterActive(true)
        setOrderStatusActive(true)
        setShowFirstStatus(false)
        setSearchActive(false)
        setOrderStatusSelected(valueSanitized)
        setLoading(false)

    }

    const firstPageFn = async () => {
        setLoading(true)
        setLoading(false)
    }
    const paginationQueryHelper = () => {
        let orderBySanitized = sortBy.toLocaleLowerCase()
        let currentQuery = {}
        let activeFilter;
        if (orderBySanitized.toLocaleLowerCase() === CONSTANTS.STRINGS_LIST.ID.toLocaleLowerCase()) {
            orderBySanitized = CONSTANTS.FIELDS.CUSTOMER_ORDERS.ORDER_ID
        } else if (orderBySanitized.toLocaleLowerCase() === CONSTANTS.STRINGS_LIST.ADDRESS_AREA.toLocaleLowerCase()) {
            orderBySanitized = CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_AREA
        } else if (orderBySanitized.toLocaleLowerCase() === CONSTANTS.STRINGS_LIST.DELIVERY_MODE.toLocaleLowerCase()) {
            orderBySanitized = CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_MODE
        }

        const querySubHelper = (statusComparator, statusValue) => {
            let querySetter;
            if (startDateFilterActive && orderSizeFilterActive && orderStatusFilterActive) {
                activeFilter = "allThreeFiltersActive"
                querySetter = fiveFieldQuery(
                    CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    "==",
                    vendorId,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    "==",
                    orderStatus.toLocaleLowerCase(),
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    ">=",
                    startDate,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    "<=",
                    endDate,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY,
                    "==",
                    Number(order)
                )
            } else if (startDateFilterActive && orderSizeFilterActive) {
                activeFilter = "dateAndOrderSizeFiltersActive"
                querySetter = fiveFieldQuery(
                    CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    "==",
                    vendorId,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator,
                    statusValue,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    ">=",
                    startDate,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    "<=",
                    endDate,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY,
                    "==",
                    Number(order)
                )
            } else if (startDateFilterActive && orderStatusFilterActive) {
                activeFilter = "dateAndOrderStatusFiltersActive"
                querySetter = fourFieldQuery(
                    CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    "==",
                    vendorId,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    "==",
                    orderStatus.toLocaleLowerCase(),
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    ">=",
                    startDate,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    "<=",
                    endDate,
                )
            } else if (orderSizeFilterActive && orderStatusFilterActive) {
                activeFilter = "orderSizeAndOrderStatusFiltersActive"
                querySetter = threeFieldQuery(
                    CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    "==",
                    vendorId,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    "==",
                    orderStatus.toLocaleLowerCase(),
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY,
                    "==",
                    Number(order)
                )
            } else if (startDateFilterActive) {
                activeFilter = "dateFilterActive"
                querySetter = fourFieldQuery(
                    CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    "==",
                    vendorId,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator,
                    statusValue,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    ">=",
                    startDate,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    "<=",
                    endDate,
                )
            } else if (orderSizeFilterActive) {
                activeFilter = "orderSizeFilterActive"
                querySetter = threeFieldQuery(
                    CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    "==",
                    vendorId,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator,
                    statusValue,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY,
                    "==",
                    Number(order),
                )
            } else if (orderStatusFilterActive) {
                activeFilter = "orderStatusFilterActive"
                querySetter = twoFieldQuery(
                    CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    "==",
                    vendorId,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    "==",
                    orderStatus.toLocaleLowerCase()
                )
            }
            else if (searchIsActive && search) {
                querySetter = threeFieldQuery(
                    CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    "==",
                    vendorId,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator,
                    statusValue,
                    fieldName(searchOptions),
                    "==",
                    search
                )
            } else {
                querySetter = twoFieldQuery(
                    CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    "==",
                    vendorId,
                    CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator,
                    statusValue,
                )

            }
            return querySetter
        }

        if (activeTab === CONSTANTS.STRINGS_LIST.IN_PROCESS) {
            currentQuery = querySubHelper("in", ["accepted", "unconfirmed"])

        } else if (activeTab === CONSTANTS.STRINGS_LIST.REJECTED) {
            currentQuery = querySubHelper("==", "rejected")

        } else if (activeTab === CONSTANTS.STRINGS_LIST.CANCELLED) {
            currentQuery = querySubHelper("==", "cancelled")

        }
        let finalQuery = {
            currentQuery,
            activeFilter
        }
        return finalQuery
    }

    const prevPageFn = async () => {
        setLoading(true)
        const result = await prevPageData(
            paginationQueryHelper().currentQuery,
            firstRow,
            orderByField,
            sortingOrder,
            rowsPerPage,
            searchIsActive,
            paginationQueryHelper().activeFilter,
        )
        const tempData = await sanitizeResult(result.data, systemConfig.maxAcceptanceLimitHours)
        if (tempData.length > 0) {
            setLastRow(result.last)
            setFirstRow(result.first)
            setData(tempData)
        }
        setLoading(false)

    }

    const nextPageFn = async () => {
        setLoading(true)
        const result = await nextPageData(
            paginationQueryHelper().currentQuery,
            lastRow,
            orderByField,
            sortingOrder,
            rowsPerPage,
            searchIsActive,
            paginationQueryHelper().activeFilter
        )
        const tempData = sanitizeResult(result.data, systemConfig.maxAcceptanceLimitHours)
        if (tempData.length > 0) {
            setLastRow(result.last)
            setFirstRow(result.first)
            setData(tempData)
        }
        setLoading(false)
    }

    const lastPageFn = async () => {
        setLoading(true)
        setLoading(true)
        return
    }

    const clearFilter = async () => {
        setLoading(true)
        fetchingData(activeTab, setFirstRow, setLastRow, setData, setLoading, orderByField, sortingOrder, vendorId)

        setOrderStatus([CONSTANTS.STRINGS_LIST.ORDER_STATUS])
        setSearchOptions([CONSTANTS.STRINGS_LIST.SEARCH_FIELD])
        setShowFirstStatus(true)
        setFilterActive(false)
        setSearch('')
        setStartDate(null)
        setEndDate(null)
        setOrderBy('')
        setOrder('')
        setDateActive(true)
        setOrderStatusActive(true)
        setOrderSizeActive(true)
        setSearchActive(true)
        setStartDateFilterActive(false)
        setEndDateFilterActive(false)
        setEndDateSelected('')
        setOrderSizeFilterActive(false)
        setOrderSizeSelected('')
        setOrderStatusFilterActive(false)
        setOrderStatusSelected('')
        setSearchIsActive(false)
        setSearchFieldCurrent('')
        setLoading(false)
    }

    const sortingHelper = async (orderByMethod, sortingFlow) => {
        let orderBy;
        if (orderByMethod === CONSTANTS.STRINGS_LIST.ID) orderBy = CONSTANTS.FIELDS.CUSTOMER_ORDERS.ORDER_ID
        else if (orderByMethod === CONSTANTS.STRINGS_LIST.DELIVERY_DATE) orderBy = CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE

        setSortBy(orderByMethod)
        setSortingOrder(sortingFlow)
        setOrderByField(orderBy)
        let tempData = []
        let queryRef;
        if (activeTab === CONSTANTS.STRINGS_LIST.IN_PROCESS) {
            if (startDateFilterActive && orderSizeFilterActive && orderStatusFilterActive) {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: '==',
                    statusValue: orderStatus.toLocaleLowerCase(),
                    serviceDate: CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    greaterThanComparator: '>=',
                    startDate: startDate,
                    lessThanComparator: '<=',
                    endDate: endDate,
                    orderSize: CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY,
                    equalComparator: "==",
                    orderSizeNumber: Number(order),
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else if (startDateFilterActive && orderSizeFilterActive) {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: 'in',
                    statusValue: ["accepted", "unconfirmed"],
                    serviceDate: CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    greaterThanComparator: '>=',
                    startDate: startDate,
                    lessThanComparator: '<=',
                    endDate: endDate,
                    orderSize: CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY,
                    equalComparator: "==",
                    orderSizeNumber: Number(order),
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else if (startDateFilterActive && orderStatusFilterActive) {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    euqalComparator: '==',
                    statusComparator: '==',
                    statusValue: orderStatus.toLocaleLowerCase(),
                    serviceDate: CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    greaterThanComparator: '>=',
                    startDate: startDate,
                    lessThanComparator: '<=',
                    endDate: endDate,
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else if (orderSizeFilterActive && orderStatusFilterActive) {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: '==',
                    statusValue: orderStatus.toLocaleLowerCase(),
                    orderSize: CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY,
                    equalComparator: "==",
                    orderSizeNumber: Number(order),
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else if (startDateFilterActive) {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    euqalComparator: '==',
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: 'in',
                    statusValue: ['unconfirmed', 'accepted'],
                    serviceDate: CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    greaterThanComparator: '>=',
                    startDate: startDate,
                    lessThanComparator: '<=',
                    endDate: endDate,
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else if (orderSizeFilterActive) {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: 'in',
                    statusValue: ["accepted", "unconfirmed"],
                    orderSize: CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY,
                    equalComparator: "==",
                    orderSizeNumber: Number(order),
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else if (orderStatusFilterActive) {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    euqalComparator: '==',
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: '==',
                    statusValue: orderStatus.toLocaleLowerCase(),
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else if (searchIsActive) {
                let searchField
                if (searchOptions === CONSTANTS.STRINGS_LIST.ID) searchField = CONSTANTS.FIELDS.CUSTOMER_ORDERS.ORDER_ID
                else if (searchOptions === CONSTANTS.STRINGS_LIST.COMPANY) searchField = CONSTANTS.FIELDS.CUSTOMER_ORDERS.CUSTOMER_COMPANY
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: 'in',
                    statusValue: ["accepted", "unconfirmed"],
                    searchOptions: searchField,
                    equalComparator: "==",
                    searchValue: search,
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    euqalComparator: '==',
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: 'in',
                    statusValue: ["accepted", "unconfirmed"],
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            }
            tempData = await sortFnWithSearch(
                queryRef,
                searchIsActive,
                startDateFilterActive,
                orderSizeFilterActive,
                orderStatusFilterActive
            )

        } else if (activeTab === CONSTANTS.STRINGS_LIST.REJECTED) {
            if (startDateFilterActive && orderSizeFilterActive) {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    euqalComparator: '==',
                    statusComparator: '==',
                    statusValue: "rejected",
                    serviceDate: CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    greaterThanComparator: '>=',
                    startDate: startDate,
                    lessThanComparator: '<=',
                    endDate: endDate,
                    orderSize: CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY,
                    equalComparator: "==",
                    orderSizeNumber: Number(order),
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else if (startDateFilterActive) {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: '==',
                    equalComparator: '==',
                    statusValue: "rejected",
                    serviceDate: CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    greaterThanComparator: '>=',
                    startDate: startDate,
                    lessThanComparator: '<=',
                    endDate: endDate,
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else if (orderSizeFilterActive) {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: '==',
                    statusValue: "rejected",
                    orderSize: CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY,
                    equalComparator: "==",
                    orderSizeNumber: Number(order),
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else if (searchIsActive) {
                let searchField
                if (searchOptions === CONSTANTS.STRINGS_LIST.ID) searchField = CONSTANTS.FIELDS.CUSTOMER_ORDERS.ORDER_ID
                else if (searchOptions === CONSTANTS.STRINGS_LIST.COMPANY) searchField = CONSTANTS.FIELDS.CUSTOMER_ORDERS.CUSTOMER_COMPANY
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: '==',
                    statusValue: "rejected",
                    searchOptions: searchField,
                    equalComparator: "==",
                    searchValue: search,
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    euqalComparator: '==',
                    statusComparator: '==',
                    statusValue: "rejected",
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            }
            tempData = await sortFnWithSearch(
                queryRef,
                searchIsActive,
                startDateFilterActive,
                orderSizeFilterActive,
                orderStatusFilterActive
            )
        } else if (activeTab === CONSTANTS.STRINGS_LIST.CANCELLED) {
            if (startDateFilterActive && orderSizeFilterActive) {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: '==',
                    statusValue: "cancelled",
                    serviceDate: CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    greaterThanComparator: '>=',
                    startDate: startDate,
                    lessThanComparator: '<=',
                    endDate: endDate,
                    orderSize: CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY,
                    equalComparator: "==",
                    orderSizeNumber: Number(order),
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else if (startDateFilterActive) {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: '==',
                    equalComparator: '==',
                    statusValue: "cancelled",
                    serviceDate: CONSTANTS.FIELDS.CUSTOMER_ORDERS.SERVICE_DATE,
                    greaterThanComparator: '>=',
                    startDate: startDate,
                    lessThanComparator: '<=',
                    endDate: endDate,
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else if (orderSizeFilterActive) {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: '==',
                    statusValue: "cancelled",
                    orderSize: CONSTANTS.FIELDS.CUSTOMER_ORDERS.TOTAL_PACKAGE_QUANTITY,
                    equalComparator: "==",
                    orderSizeNumber: Number(order),
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else if (searchIsActive) {
                let searchField
                if (searchOptions === CONSTANTS.STRINGS_LIST.ID) searchField = CONSTANTS.FIELDS.CUSTOMER_ORDERS.ORDER_ID
                else if (searchOptions === CONSTANTS.STRINGS_LIST.COMPANY) searchField = CONSTANTS.FIELDS.CUSTOMER_ORDERS.CUSTOMER_COMPANY
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: '==',
                    statusValue: "cancelled",
                    searchOptions: searchField,
                    equalComparator: "==",
                    searchValue: search,
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            } else {
                queryRef = {
                    collectionName: CONSTANTS.API.COLLECTION_NAMES.CUSTOMER_ORDERS,
                    vendorIdField: CONSTANTS.FIELDS.CUSTOMER_ORDERS.VENDOR_ID,
                    vendorId: vendorId,
                    equalComparator: "==",
                    status: CONSTANTS.FIELDS.CUSTOMER_ORDERS.STATUS,
                    statusComparator: '==',
                    statusValue: "cancelled",
                    orderBy: orderBy,
                    sortMethod: sortingFlow,
                    queryLimit: '10',
                }
            }
            tempData = await sortFnWithSearch(
                queryRef,
                searchIsActive,
                startDateFilterActive,
                orderSizeFilterActive,
                orderStatusFilterActive
            )
        }
        const result = await sanitizeResult(tempData.data, systemConfig.maxAcceptanceLimitHours)
        setFirstRow(tempData.first)
        setLastRow(tempData.last)
        setSortingOrder(sortingFlow)
        setSortBy(orderByMethod)
        setData(result)
    }

    const sortAsc = async (e, orderByMethod) => {
        setLoading(true)
        sortingHelper(orderByMethod, "asc")
        setSortOrder(CONSTANTS.STRINGS_LIST.ASSCENDING)
        setLoading(false)
    }

    const sortDesc = async (e, orderByMethod) => {
        setLoading(true)
        sortingHelper(orderByMethod, "desc")
        setSortOrder(CONSTANTS.STRINGS_LIST.DESCENDING)
        setLoading(false)
    }

    const propsList = {
        data,
        filterActive,
        handleSearch: (e) => handleSearch(e, searchData, setLoading),
        handleOrderStatus,
        startDate,
        endDate,
        search_change_handler: (e) => search_change_handler(e, setSearch),
        handleOrderSize,
        handleStartDateFilter,
        handleEndDateFilter,
        statusFields,
        clearFilter,
        search,
        dateActive,
        orderSizeActive,
        orderStatusActive,
        searchActive,
        statusText,
        loading,
        setLoading,
        // fetchProcess,
        setOrder,
        setOrderBy,
        order,
        orderBy,
        heads,
        activeTab,
        listNames,
        filterVisible: true,
        searchVisible: true,
        setActiveTab,
        dashBoardHeader,
        searchData,
        setSearchOptions,
        searchOptions,
        searchListNames,
        showFirstStatus,
        setShowFirstStatus,
        firstStatusOption,
        orderStatus,
        setOrderStatus,
        searchOptionVisible: true,
        handleOrderFn,
        searchFieldActive,
        firstPageFn,
        prevPageFn,
        nextPageFn,
        lastPageFn,
        // handleHeaderClick,
        setOrderAccepted,
        orderAccepted,
        setShowOrderDetails,
        showOrderDetails,
        showPackageDetails,
        setShowPackageDetails,
        sortBy,
        setSortBy,
        sortOrder,
        setSortOrder,
        sortDesc,
        sortAsc,
        showPrevPgBtn: true,
        showFirstPgBtn,
        showLastPgBtn,
        showNextPgBtn: true,
    }
    return (
        <div className=" main-container btn-danger">
            <VendorOrderSkeleton
                propsList={propsList}
            />
        </div>
    )
}