import { useState } from "react";
import { createContext } from "react";
import { getLocalItems } from "../LocalStorage/LocalStorage";

export const UserContext = createContext()

const UserProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState(getLocalItems('user'));
    const [changes, setChanges] = useState(false);

    const value = {
        //everything we need anywhere
        currentUser,
        setCurrentUser,
        loading,
        setLoading,
        changes,
        setChanges,
    }
    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;