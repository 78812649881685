import React from 'react'
import { Images } from '../../../../Assets/Images/Images';
import DashboardTableView from '../../../../Components/Table/Views/TableView';
import TopStrip from '../../../../Components/TopStrip';
import { CONSTANTS } from '../../../../Helpers/Constants';
import Table from '../../../../Components/Table';
import DashboardTableHeaders from '../../../../Components/Table/Views/TableHeaders';
import { removeItemsLocally, setItemsLocally } from '../../../../LocalStorage/LocalStorage';
import Loader from '../../../../Components/Loader';

export default function AdminVendorTable(
    props
) {

    const propsList = props.props;
    const date = new Date()
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSortings,
    } = DashboardTableView(propsList)


    return (
        <>
            <TopStrip
                path={[CONSTANTS.STRINGS_LIST.VENDOR]}
                image={Images.vendor_icon}
                buttons={[
                    {
                        btnText: CONSTANTS.STRINGS_LIST.MANAGE_SORT,
                        btnAction: () => {
                            setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.LOCAL_STORAGE_ITEMS.SORTED_VENDORS_KEY)
                            propsList.setSortList(!propsList.sortList);
                        },
                        showBtn: true,
                    },
                    {
                        btnText: CONSTANTS.STRINGS_LIST.CREATE_VENDOR,
                        btnAction: () => {
                            propsList.setCreate(!propsList.create);
                            removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY)
                        },
                        showBtn: true,
                    }, {
                        btnText: CONSTANTS.STRINGS_LIST.RESET,
                        btnAction: propsList.clearFilter,
                        showBtn: propsList.filterActive,
                    },
                ]}
            />
            <div className="table">
                <div className="tableContainer">
                    <DashboardTableHeaders
                        setActiveTab={propsList.setActiveTab}
                        activeTab={propsList.activeTab}
                        headerList={propsList.dashBoardHeader}
                        handleHeaderClick={propsList.handleHeaderClick}
                    />
                    {propsList.loading && <Loader />}
                    <Table
                        date={date}
                        TblContainer={TblContainer}
                        TblHead={TblHead}
                        TblPagination={TblPagination}
                        recordsAfterPagingAndSortings={recordsAfterPagingAndSortings}
                        propsList={propsList}
                    />
                </div>
            </div>
        </>
    )
}
