import React from 'react'
import { Images } from '../../../../Assets/Images/Images'
import TopStrip from '../../../../Components/TopStrip'
import { CONSTANTS } from '../../../../Helpers/Constants'
import CreatePackageTable from './CreatePackageTable'
import Loader from "../../../../Components/Loader"
import { cancelCreationFunction } from '../../../../Helpers/Helper'
import AddMenuItem from './AddMenuItem/AddMenuItem'
import { setItemsLocally } from '../../../../LocalStorage/LocalStorage'

export default function CreatePackageSkeleton({ propsList }) {
    const { props, changes, setChanges, showAddItem, loading, edit } = propsList
    const prop = props.props
    return (
        <div className="dashboardSkeleton">
            <TopStrip
                path={[CONSTANTS.STRINGS_LIST.PACKAGES]}
                primaryBreadCrumb={(event) => (
                    setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.PACKAGES),
                    cancelCreationFunction(event, changes, prop.setCreate, setChanges))}
                secondPath={[
                    {
                        label: edit ? CONSTANTS.STRINGS_LIST.EDIT_PACKAGE : CONSTANTS.STRINGS_LIST.CREATE_PACKAGE,
                        action: () => { }
                    }
                ]}
                image={Images.package_icon_black}
                buttons={[
                    {
                        btnText: [CONSTANTS.STRINGS_LIST.CANCEL],
                        btnAction: (event) => (
                            setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.PACKAGES),
                            cancelCreationFunction(event, changes, prop.setCreate, setChanges
                            )),
                        showBtn: true,
                    }
                ]}
            />
            <div className="skeletonContainer">
                <CreatePackageTable
                    propsList={propsList}
                    setCreate={prop.setCreate}
                    packageCreated={prop.packageCreated}
                    setPackageCreated={prop.setPackageCreated}
                />
                {showAddItem && <AddMenuItem
                    propsList={propsList}
                />}
            </div>
            {loading && <Loader />}
        </div>
    )
}
