import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../Context/UserContext';
import VendorSettingsSkeleton from './Views/VendorSettingsSkeleton';
import { askConfimation, selectOptions, onChangeRoute, isEmpty } from '../../../Helpers/Helper';
import { CONSTANTS } from '../../../Helpers/Constants';
import { getVendorProfile, getCities, getAreas, timestampToDays } from '../../../Helpers/FetchHandlers';
import { updateVendorSettings } from '../../../Helpers/UpdateHandlers';
import validateSettings from './ValidateSettings';
import { useHistory } from "react-router";
import { DateUtils } from 'react-day-picker';
import swal from 'sweetalert';
import { removeItemsLocally, setItemsLocally } from '../../../LocalStorage/LocalStorage';

export default function VendorSettings() {
    const history = useHistory();
    const { currentUser, loading, setLoading, changes, setChanges } = useContext(UserContext);
    const [formData, setFormData] = useState({});
    const [vendorData, setVendorData] = useState({});
    const [_nonBookableDates, setNonBookableDate] = useState([]);
    let bookableDatesNot = [];

    useEffect(() => {
        setItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.MODE_KEY, CONSTANTS.STRINGS_LIST.SETTINGS);
        removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.EDIT_KEY)
        removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.ITEM_KEY)
        let block;

        if (changes) {
            askConfimation(setChanges);
            block = history.block((location, action) => onChangeRoute(setChanges))
        }
        return () => {
            block && block()
        }
    }, [changes, history]);

    useEffect(() => {
        setCurrentVendorData(currentUser.userId);
    }, [])

    const changeHandler = (field, value) => {
        setFormData({
            ...formData,
            [field]: value
        })
        setChanges(true);
    }

    const setCurrentVendorData = async (userId) => {
        setLoading(true);
        const currentVendorData = await getVendorProfile(userId);

        if (!isEmpty(currentVendorData)) {
            let dates = timestampToDays(currentVendorData?.vendorProfile?.nonBookableDates)
            setNonBookableDate(dates);
            bookableDatesNot = dates;

            const citiesOptions = await getCities();
            const citiesLocationsOption = await getAreas();
            const cities = currentVendorData?.vendorProfile?.city ? await selectOptions(currentVendorData?.vendorProfile?.city) : ''
            const citiesAreas = currentVendorData?.vendorProfile?.locations.length > 0 ? await selectOptions(currentVendorData?.vendorProfile?.locations) : '';
            const _logo = currentVendorData?.vendorProfile?.logoImageUrl.split(/[\\\//]/).pop().split('?')[0].split('%2F')[3];
            const initialForm = {
                vendorId: currentVendorData?.vendorId,
                description: currentVendorData?.vendorProfile?.description || "",
                logo: _logo || "",
                logoImageUrl: currentVendorData?.vendorProfile?.logoImageUrl || '',
                city: cities || "",
                citiesOption: citiesOptions || "",
                locations: citiesAreas || "",
                citiesAreasOptions: citiesLocationsOption || [],
                bookBeforeDays: currentVendorData?.vendorProfile?.bookBeforeDays || "",
                maximumOrderCapacity: currentVendorData?.vendorProfile?.maximumOrderCapacity || "",
                nonBookableDates: currentVendorData?.vendorProfile?.nonBookableDates || "",
                updatedNonbookableDates: bookableDatesNot,
                supplementMarkup: currentVendorData?.vendorProfile?.supplementMarkUp || 0,
                isDeliveryEnabled: currentVendorData?.vendorProfile?.isDeliveryEnabled || false,
                isPickupEnabled: currentVendorData?.vendorProfile?.isPickupEnabled || false,
                isSupplementEnabled: currentVendorData?.vendorProfile?.isSupplementEnabled || false
            };
            setFormData(initialForm);
            setVendorData(currentVendorData);
        } else {
            setFormData([])
            setVendorData([])
        }
        setLoading(false);
        setChanges(false);
    }

    const handleDayClick = (day, { selected }) => {
        const selectedDays = _nonBookableDates.concat();
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        setNonBookableDate(selectedDays);
        bookableDatesNot = selectedDays;
        setFormData({
            ...formData,
            updatedNonbookableDates: selectedDays
        })
        setChanges(true);
    }

    const saveSettingsAlert = () => {
        let vendorProfileID = vendorData?.vendorProfileId;
        swal(CONSTANTS.STRINGS_LIST.SAVE_UPDATED_CHANGES, {
            buttons: {
                cancel: CONSTANTS.STRINGS_LIST.CANCEL,
                save: CONSTANTS.STRINGS_LIST.SAVE
            }
        }).then((value) => {
            switch (value) {
                case "save":
                    updateVendorSettings(vendorProfileID, formData)
                    break;
                default:
                    break;
            }
        })
        setChanges(false);
    }

    const onSaveSettings = (e) => {
        e.preventDefault();
        const { error, message } = validateSettings({ formData });
        if (error && message) {
            swal({
                title: CONSTANTS.STRINGS_LIST.SETTINGS_UPDATED_FAILED,
                text: message,
                icon: "error"
            })
        }
        else {
            saveSettingsAlert();
        }
    }

    return (
        <>
            <div className="main-container">
                <VendorSettingsSkeleton
                    loading={loading}
                    formData={formData}
                    changes={changes}
                    setChanges={setChanges}
                    _nonBookableDates={_nonBookableDates}
                    changeHandler={changeHandler}
                    onSaveSettings={onSaveSettings}
                    handleDayClick={handleDayClick}
                />
            </div>
        </>
    )
}