import { Route, Redirect } from "react-router"
import { UserContext } from "../../Context/UserContext"
import { useContext } from "react"
import { getLocalItems } from "../../LocalStorage/LocalStorage"

export const VendorProtected = ({ component: Component, ...rest }) => {
    const { currentUser } = useContext(UserContext)
    const user = getLocalItems("user")

    return (
        <Route {...rest} render={
            props => {
                if(user === null) {
                    return (
                        <Redirect to="/vendor" />
                    )
                }
                else if(user.userType === 'admin'){
                    return (
                        <Redirect to="/error" />
                    )
                }
                else if (user !== null) {
                    if(currentUser && user.userType === 'vendor'){
                        return(
                            <Component {...rest}{...props} />
                        )
                    }
                    else if(user.userType === 'vendor'){
                        return(
                            <Redirect to='/vendor' />
                        )
                    }
                    else{
                        return(
                            <Redirect to="/error" />
                        )
                    }
                }
            }
        }
        />
    )
}

export const VendorLoginProtected = ({ component: Component, ...rest }) => {
    const { currentUser } = useContext(UserContext)

    return (
        <Route {...rest} render={
            props => !currentUser ? <Component {...rest}{...props} /> : <Redirect to='/vendor/orders' />} />
    )
}