import React from 'react'
import VendorPackagesTable from "../Views/VendorPackagesTable"
import CreatePackage from "../../CreatePackage"
import VendorPackagesListSort from '../../VendorPackagesListSort'
// import OrderDetails from '../../OrderDetails/OrderDetails'

export default function VendorPackagesSkeleton(props) {
    return (
        <div className="dashboardSkeleton">
            <div className="skeletonContainer">
                {props.create ?
                    <CreatePackage
                        props={props}
                    /> :
                    props.sortList ?

                        <VendorPackagesListSort
                            sortList={props.sortList}
                            setSortList={props.setSortList}
                        />
                        :
                        <VendorPackagesTable
                            props={props}
                        />
                }
            </div>
        </div>
    )
}
