import { Link, useLocation } from "react-router-dom"
export default function LEFT_SIDE_LIST({menuType, isActive, setIsActive}) {
    const location = useLocation();
    
    return (
        <ul>
            {menuType && menuType.map((d, i) => {
                return (
                    <Link id={`sidebar_${i}`} key={i} to={d.route} className="route-link">
                        <li id={`sidebar_${d.label}`} className={isActive === d.route ? 'active' : ''} onClick={() => {
                            location.pathname === d.route &&
                            d.action(setIsActive, i)
                        }
                        }>
                            <img src={d.image_black} alt='icon' id="imgBlack" />
                            <img src={d.image_white} alt='icon' id="imgWhite" />
                            {d.label}
                        </li>
                    </Link>

                )
            })}
        </ul>
    )
}