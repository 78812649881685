import React from 'react'
import OrderDetails from '../../OrderDetails'
import DashboardTable from './DashboardTable'

export default function DashboardSkeleton({ propsList }) {
    const { showOrderDetails } = propsList
    return (
        <div className="dashboardSkeleton">
            <div className="skeletonContainer">
                {showOrderDetails ? <OrderDetails propsList={propsList} /> : <DashboardTable propsList={propsList} />}
            </div>
        </div>
    )
}
