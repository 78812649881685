import React from 'react'

export default function DashboardTableHeaders(props) {
    return (
        <div className="tableSelectors">

            {props.headerList.map((header, index) => {
                return (
                    <span
                        key={'selec' + index + 'or'}
                        className={`ic-span ${props.activeTab === header[0] ? 'isSelected' : ''}`}
                        onClick={() => {props.setActiveTab(header[0])
                        }}
                    >
                        <p className='ic-p' >{header}</p>
                    </span >
                )
            })}
        </div>
    )
}
