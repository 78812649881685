export default function validateSettings({ moms, fees, tags }) {
    if (moms === '' && fees === '') {
        return { error: true, message: 'Please enter required fields' }
    }
    if (moms === '' || moms < 0 || moms > 100) {
        return { error: true, message: '"MOMS" value must be between 0 - 100% ' }
    }
    else if (fees === '' || fees < 0 || fees > 100) {
        return { error: true, message: '"FEES" value must be between 0 - 100% ' }
    }
    else if (tags === '' || tags.length < 0) {
        return { error: true, message: 'Please enter valid "TAGS" value' }
    }
    return { error: false, message: "" }
}
