import './Assets/Styles/style.css';
import UserProvider from './Context/UserContext';
import AppRouter from './Router/AppRouter';


function App() {
  return (

    <div className="App">
      <header className="App-header">
        <UserProvider>
          <AppRouter />
        </UserProvider>
      </header>
    </div>
  );
}

export default App;
