import { Images } from "../../../../Assets/Images/Images";
import TopStrip from "../../../../Components/TopStrip";
import { CONSTANTS } from "../../../../Helpers/Constants";
import { removeItemsLocally } from "../../../../LocalStorage/LocalStorage";
import AdminFAQTable from "./AdminFAQTable";

export default function AdminFAQSkeleton({ propList }) {
    const { setModal } = propList
    return (
        <div className="dashboardSkeleton">
            <div className="skeletonContainer">
                <TopStrip
                    path={[CONSTANTS.STRINGS_LIST.FAQ]}
                    image={Images.faq_black}
                    buttons={[
                        {
                            btnText: CONSTANTS.STRINGS_LIST.CREATE_FAQ,
                            btnAction: () => { setModal(true); removeItemsLocally(CONSTANTS.LOCAL_STORAGE_ITEMS.FAQ_KEY) },
                            showBtn: true,
                        }
                    ]}
                />
                <AdminFAQTable
                    propList={propList}
                />
            </div>
        </div>
    )
}